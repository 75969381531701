<section id="retry-transfer" class="h-100" >
    <app-retry-transfer-success
        *ngIf="successModal"
        [entityType]="currentPayment.entityTypeLocale"
        [currentPaymentStatus]="currentPayment.currentPaymentStatus"
        (setCloseModal)="onNoClick()"
    ></app-retry-transfer-success>
    <app-retry-transfer-error
        *ngIf="errorModal?.error"
        [paymentError]="paymentError"
        [errorMessage]="errorModal?.errorMessage"
        (setCloseModal)="onNoClick()"
    ></app-retry-transfer-error>
    <section *ngIf="!successModal && !errorModal" class="row animated fadeIn">
        <section mat-dialog-title class="modal-header" (click)="onNoClick()">
            <h3 class="modal-title">No pudimos depositar tu pago</h3>
            <mat-icon
            class="close-icon"
            >close</mat-icon>
        </section>
        <mat-dialog-content class="retry-modal-content">
            <section class="header-text">
                <p class="mr-1 mb-0">
                    El
                </p>
                <p class="mr-1 mb-0 font-weight-semi-bold">
                    {{paymentDate}}
                </p>
                <p class="mb-0 mr-1">
                    intentamos depositar el 
                </p>
                <p class="mr-1 mb-0">
                    pago de 
                </p>
                <p class="mr-1 mb-0 font-weight-semi-bold">
                    {{currentPayment.entityTypeLocale}}
                </p>
                <p class="mr-1 mb-0">
                    por un monto de
                </p>
                <p class="font-weight-semi-bold mb-0 mr-1">
                    {{currentPayment.value | clpCurrency}}
                </p>
                <p *ngIf="currentPayment && currentPayment.valueUF" class="mb-0 mr-1">
                    ({{currentPayment.valueUF}} UF)
                </p>
                <p class="mb-0">
                    y no pudimos. 
                </p>
            </section>
            <section class="content-text-wrapper">
                <h3 class="content-title">
                    ¿Cuál fue el motivo del rechazo?
                </h3>
                <p class="content-text">
                    Pueden ser dos: la cuenta de destino no existe o que los datos de la cuenta de destino no coinciden.
                </p>
            </section>
            <section class="content-text-wrapper" style="border: none !important;">
                <h3 class="content-title">
                    ¿Qué tienes que hacer?
                </h3>
                <p class="content-text">
                    Revisar los datos bancarios y corregirlos para que se pueda reintentar el depósito.
                </p>
            </section>
            <app-retry-transfer-form
                [currentPayment]="currentPayment"
                (showSuccessModal)="showSuccessModal($event)"
                (showErrorModal)="showErrorModal($event)"
            ></app-retry-transfer-form>
        </mat-dialog-content>
    </section>
</section>

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ReplacePipe } from '../replace/replace.pipe';

@Pipe({
  name: 'clpCurrency'
})
export class ClpCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any): any {
    // (num)
    // Recibe:
    //    número
    // Devuelve:
    //    precio formato chileno

    // (cualquier otra cosa)
    // Recibe:
    //    "algo"
    // Devuelve:
    //    "algo" (lo mismo)

    // en caso que value sea un string que represente un número..
    if (typeof value === 'number' || !isNaN(value)) {
      value = Math.round(value);
      const replacePipe = new ReplacePipe();
      value = super.transform(value, 'USD', 'symbol', '1.0');
      value = replacePipe.transform(value, ',', '.');
      value = replacePipe.transform(value, ',', '.');
      value = replacePipe.transform(value, ',', '.');
      return value;
    } else {
      return value;
    }
  }
}

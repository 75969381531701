/* eslint-disable no-async-promise-executor */
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject, NgZone, isDevMode } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { Router } from '@angular/router';
import { OAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { INeatClubUserInfo } from 'neat-lib/dist/Interfaces/IData';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

import { CookiesService } from '@services/cookies/cookies.service';
import { AppGlobals } from '@shared/constants';
import { sleep } from '@shared/utils';

import { DatabaseService } from './../shared/database/database.service';
import { UserInfo } from '../../models/users/user-info.model';
import { User } from '../../models/users/user.model';
import { AuthProvider, IconErrorModals } from '../../shared/enums/enums.enum';
import { CloudService } from '../cloud/cloud.service';
import { DevicesDetectorService } from '../shared/devices-detector/devices-detector.service';
import { ErrorHandlerService } from '../shared/error-handler/error-handler.service';
import { SweetalertService } from '../shared/sweetalert/sweetalert.service';
import { IntercomService } from './../shared/intercom/intercom.service';

declare let heap: any;
@Injectable({
  providedIn: 'root'
})
export class UserService {
  sharedUser: Subject<User> = new BehaviorSubject<User>(null);
  sharedUserInfo: Subject<UserInfo> = new BehaviorSubject<UserInfo>(null);
  isNewUsers: Subject<boolean> = new BehaviorSubject<boolean>(false);
  entitiesPreferencesOnboarding: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private sharedIdToken: Subject<string> = new BehaviorSubject<string>(null);
  private providerInfo: Subject<string> = new BehaviorSubject<string>(null);
  private currentUser$: Subject<User> = new BehaviorSubject<User>(null);
  private currentNeatClubUser$: Subject<INeatClubUserInfo> = new BehaviorSubject<INeatClubUserInfo>(null);
  private currentOperationData$: Subject<any> = new BehaviorSubject<any>(null);
  private referralSegmentation$: Subject<any> = new BehaviorSubject<any>(null);
  private transferDateData$: Subject<any> = new BehaviorSubject<any>(null);
  private allRewardsAvailable$ = new BehaviorSubject<any[]>([]);
  private currentPromotionsDates$: Subject<any> = new BehaviorSubject<any>(null);
  private showBasicServicesByRut$: Subject<any> = new BehaviorSubject<any>(null);
  showBasicServicesByRut = false;
  currentUser: User;
  actionCodeSettings = { url: `${this.document.location.origin}/dashboard` };
  user: User;
  userInfoDoc: UserInfo;
  userPhotoUrl: any;
  userId: string;
  appLogin: boolean;
  kushkiMode = false;
  soyio = true;
  public userSaved: User;
  userRut: string;
  displayName: string; // Analizar si usamos esto
  userInfo: boolean;
  private emailRegisterData = new BehaviorSubject<any>(null);
  currentEmailRegisterData = this.emailRegisterData.asObservable();
  getProviderData: string;
  // authState: any;
  constructor(
    private db: DatabaseService,
    @Inject(DOCUMENT) private document: Document,
    public ngZone: NgZone,
    private devicesService: DevicesDetectorService,
    private fireAuth: AngularFireAuth,
    private router: Router,
    private intercomService: IntercomService,
    private swalService: SweetalertService,
    private cloudService: CloudService,
    private errorService: ErrorHandlerService,
    private firePerformance: AngularFirePerformance,
    private fireAnalytics: AngularFireAnalytics,
    private afs: AngularFirestore,
    private http: HttpClient,
    private cookiesService: CookiesService,
  ) {
    this.fireAuth.languageCode = new Promise((resolve) => { resolve('es'); });
    this.cargarStorage();
  }

  get isNewUser$(): Observable<boolean> {
    return this.isNewUsers.asObservable();
  }

  get isEntitiesOnboardingRequest$(): Observable<boolean> {
    return this.entitiesPreferencesOnboarding.asObservable();
  }

  get user$(): Observable<User> {
    return this.sharedUser.asObservable().pipe(filter(user => !!user));
  }

  get userInfo$(): Observable<UserInfo> {
    return this.sharedUserInfo.asObservable().pipe(filter(user => !!user));
  }

  get idToken$(): Observable<string> {
    return this.sharedIdToken.asObservable().pipe(filter(idToken => !!idToken));
  }

  get provider$(): Observable<string> {
    return this.providerInfo.asObservable().pipe(filter(providerInfo => !!providerInfo));
  }

  get currentUserData$(): Observable<User> {
    return this.currentUser$.asObservable().pipe(filter(user => !!user));
  }

  get currentNeatClubUserData$(): Observable<INeatClubUserInfo> {
    return this.currentNeatClubUser$.asObservable().pipe(filter(user => !!user));
  }

  //quizás mas adelante crear un generalService para estos casos
  get operationData$(): Observable<any> {
    return this.currentOperationData$.asObservable().pipe(filter(status => !!status));
  }

  get referralSegmentationData$(): Observable<any> {
    return this.referralSegmentation$.asObservable().pipe(filter(status => !!status));
  }

  get allRewardsAvailables$(): Observable<any[]> {
    return this.allRewardsAvailable$.asObservable().pipe(filter(status => !!status));
  }

  get transferDates$(): Observable<any> {
    return this.transferDateData$.asObservable().pipe(filter(status => !!status));
  }

  get promotionsDates$(): Observable<any> {
    return this.currentPromotionsDates$.asObservable().pipe(filter(status => !!status));
  }

  setShowBasicServiceScreen(data: any) {
    this.showBasicServicesByRut = data;
    this.showBasicServicesByRut$.next(data);
  }

  get showBasicServiceScreen$(): Observable<any> {
    return this.showBasicServicesByRut$.asObservable();
  }

  getUserData$(uid: string) {
    this.afs.firestore.collection('users').doc(uid).onSnapshot(doc => {
      this.currentUser$.next(doc.data() as User);
    });
  }

  getNeatClubUserData$(uid: string) {
    this.afs.firestore.collection('neatClubUsersInfo').doc(uid).onSnapshot(doc => {
      this.currentNeatClubUser$.next(doc.data() as INeatClubUserInfo);
    });
  }

  getTransferDateInfoData$() {
    this.afs.firestore.collection('generalData').doc('transferDateInfo').onSnapshot(doc => {
      this.transferDateData$.next(doc.data());
    });
  }

  getReferralSegmentationData$() {
    this.afs.firestore.collection('generalData').doc('referralSegmentation').onSnapshot(doc => {
      this.referralSegmentation$.next(doc.data());
    });
  }

  getAllRewardsAvailable$() {
    this.afs.firestore.collection('rewards').onSnapshot(snapshot => {
      const docsData = [];
      snapshot.forEach(doc => {
        docsData.push(doc.data());
      });
      this.allRewardsAvailable$.next(docsData);
    });
  }

  getOperationData$() {
    this.afs.firestore.collection('generalData').doc('operationData').onSnapshot(doc => {
      this.currentOperationData$.next(doc.data());
    });
  }

  getPromotionsDates$() {
    this.afs.firestore.collection('generalData').doc('promotionsDates').onSnapshot(doc => {
      this.currentPromotionsDates$.next(doc.data());
    });
  }

  addUser(data: User): void {
    this.sharedUser.next(data);
    localStorage.setItem('user', JSON.stringify(Object.assign({}, data)));
    this.errorService.setUser({ uid: data.id, email: data.email, displayName: data.displayName });
  }

  setEmailRegisterData(data: any) {
    this.emailRegisterData.next(data);
  }

  addIsNewUser(data: boolean): void {
    this.isNewUsers.next(data);
  }

  showEntitiesPreferencesOnboarding(data: boolean): void {
    this.entitiesPreferencesOnboarding.next(data);
  }

  addUserInfo(data: UserInfo): void {
    this.sharedUserInfo.next(data);
  }

  addIdToken(data: string): void {
    this.sharedIdToken.next(data);
  }

  addProviderInfo(data: string): void {
    this.providerInfo.next(data);
  }

  async defineExperimentsTreatmentsGroups(currentUser: User, treamentPercentage: number, extraDataFieldName: string) {
    const userExtraData = currentUser?.extraData;
    let updateUserData = {};
    const treatmentGroup = Math.random() <= treamentPercentage;
    if (currentUser.extraData) {
      userExtraData[extraDataFieldName] = treatmentGroup;
      updateUserData = new User().deserialize({ 'extraData': userExtraData });
    } else {
      updateUserData = new User().deserialize({ 'extraData': { extraDataFieldName: treatmentGroup } });
    }
    try {
      await this.updateUserDoc(updateUserData, currentUser.id);
    } catch (err) {
      this.errorService.recordError(err,
        'new-checkout.component.ts', 'updateUserDoc()', `Error al actualizar campo extraData onboarding-option-selection de user`);
    }
  }

  async updateUserDoc(data: Partial<User>, docID = null): Promise<any> {
    await this.db.firestoreUpdateDoc('users', Object.assign({}, data), docID)
      .catch((err) => {
        this.errorService.recordError(err, 'user.service.ts',
          'firestoreUpdateDoc()', 'Error al updateUserDoc()');
        throw err;
      });
  }

  async updateNeatClubUserInfoDoc(docID = null): Promise<any> {
    await this.db.NeatClubSignTerms(docID).catch((err) => {
      this.errorService.recordError(err, 'user.service.ts',
        'firestoreUpdateDoc()', 'Error al updateUserDoc()');
      throw err;
    });
  }

  async updateUserInfoAuth(data: Partial<User>): Promise<any> {
    const { displayName } = data;
    await (await this.fireAuth.currentUser).updateProfile({ displayName })
      .catch((err) => {
        this.errorService.recordError(err, 'user.service.ts',
          'updateProfile()', 'Error al actualizar displayName en auth.currentUser');
      });
  }

  getUserProvider() {
    this.fireAuth.onAuthStateChanged(
      async (authUser: firebase.User) => {
        if (authUser) {
          this.addProviderInfo(authUser.providerData[0].providerId);
        }
      }
    );
  }

  private async getUserfromUserDoc(uid: string): Promise<User> {
    try {
      const userDoc = await this.db.firestoreGetData('users', uid)
        .catch((err) => {
          this.errorService.recordError(err, 'user.service.ts',
            'firestoreGetData()', `Error al obenter userDoc: ${uid} and currentUser: ${this.currentUser?.id}`);
          throw err;
        });
      return new User().deserialize(userDoc.data());
    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts',
        'getUserDoc()', `Error al obtener user desde user doc: ${uid}`);
      throw err;
    }
  }

  async waitForUserInfoRut(id: string) {
    const trace = await this.firePerformance.trace('user from waitForUserInfoRut()');
    trace.start();
    let userInfoDoc = await this.getUserInfoDoc(id);
    while (!userInfoDoc.rut) {
      await sleep(200);
      userInfoDoc = await this.getUserInfoDoc(id);
    }
    trace.stop();
  }

  // TO DO: this should be a subscribe
  getUserInfoDoc(uid: string): Promise<UserInfo> {
    return new Promise<UserInfo>(async (resolve, reject) => {
      try {
        if (this.userInfoDoc) {
          resolve(this.userInfoDoc);
        } else {
          this.db.firestoreGetRef('usersInfo', uid).onSnapshot(async snapShot => {
            const trace = await this.firePerformance.trace('user from getUserInfoDoc()');
            trace.start();
            if (snapShot) {
              this.userInfoDoc = new UserInfo().deserialize(snapShot.data());
              this.addUserInfo(this.userInfoDoc);
              this.fireAnalytics.setUserProperties({
                was_referred: this.userInfoDoc?.referrerId ? true : false,
                has_referred: this.userInfoDoc?.referredWhoPayed?.length > 0 ? true : false,
                has_payed: this.userInfoDoc?.hasPayed
              });
              resolve(this.userInfoDoc);
            } else {
              resolve(null);
            }
            trace.stop();
          });
        }
      } catch (err) {
        this.errorService.recordError(err, 'user.service.ts',
          'getUserInfoDoc()', `Error al obtener el usersInfo doc: ${uid}`);
        reject(err);
      }
    });
  }


  // TO DO: this should be a subscribe
  getCurrentUser(): Promise<User> {
    return new Promise<User>(async (resolve, reject) => {
      if (this.currentUser === undefined) {
        this.fireAuth.onAuthStateChanged(
          async (authUser: firebase.User) => {
            if (authUser) {
              const trace = await this.firePerformance.trace('user from getCurrentUser()');
              trace.start();
              this.addProviderInfo(authUser.providerData[0].providerId);
              this.fireAnalytics.setUserId(authUser.uid);
              const userToken = await authUser.getIdToken(true);
              this.addIdToken(userToken);
              this.getUserfromUserDoc(authUser.uid)
                .then((userDoc: User) => {
                  this.currentUser = userDoc;
                  this.fireAnalytics.setUserProperties({
                    email_verified: this.currentUser.emailVerified,
                    age: this.currentUser.birthDate ? new Date().getFullYear() - this.currentUser.birthDate.year : null
                  });
                  this.userSaved = userDoc;
                  this.cookiesService.setCookie('userId', userDoc.id, '.neatpagos.com', 90);
                  this.intercomService.updateIntercomUser(
                    this.currentUser,
                    Number(authUser.metadata.creationTime) / 1000,
                    Number(authUser.metadata.lastSignInTime) / 1000
                  );
                  this.currentUser.extend_with(authUser);
                  try {
                    this.setHeapConfig(this.currentUser);
                  } catch (e) {
                    this.errorService.recordError(e, 'user.service.ts',
                      `heap.addUserProperties()', 'Error al asignar heap property to ${authUser.email} in getCurrentUser`);
                  }
                  resolve(this.currentUser);
                })
                .catch(err => {
                  this.errorService.recordError(err, 'user.service.ts',
                    'this.db.firestoreGetData()', `Error al obtener datos del usuario: ${authUser.uid}`);
                  reject(err);
                })
                .finally(() => {
                  trace.stop();
                });
            } else {
              // No es muy intuitivo, se devuelve null dado que no hay usuario logeado
              resolve(null);
            }
          },
          (error: firebase.auth.Error) => {
            this.errorService.recordError(error, 'user.service.ts',
              'this.fireAuth.onAuthStateChanged', 'Error al obtener user de auth en getCurrentUser()');
            reject(error);
          }
        );
      } else {
        resolve(this.currentUser);
      }
    });
  }

  async logOut(): Promise<void> {
    try {
      await this.fireAuth.signOut()
        .catch((err) => {
          this.errorService.recordError(err, 'user.service.ts',
            'auth.signOut()', 'Error al cerrar sesión');
        });
      this.addIdToken(null);
      window.Intercom('shutdown');
      this.currentUser = null;
      this.borrarStorage();
      this.cookiesService.deleteCookie('userId', '.neatpagos.com');
      this.swalService.swalToastGeneral('Sesión cerrada exitosamente', 'success');
      this.cookiesService.deleteCookie('login', '.neatpagos.com');
      this.cookiesService.deleteCookie('tempEntityCookie', '.neatpagos.com');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts',
        'logOut()', 'Error al cerrar sesión');
      throw err;
    }
  }

  // Firebase SignInWithPopup
  private async oAuthProvider(provider: firebase.auth.AuthProvider): Promise<firebase.auth.UserCredential> {
    const trace = await this.firePerformance.trace('user from oAuthProvider()');
    trace.start();
    const signInWithPopup = await this.fireAuth.signInWithPopup(provider)
      .catch((error) => {
        trace.stop();
        this.errorService.recordError(error, 'user.service.ts',
          'signInWithPopup()', 'Error al iniciar sesión con oAuthProvider');
        throw error;
      });
    trace.stop();
    return signInWithPopup;
  }

  async hasRut(credentials: firebase.auth.UserCredential) {
    const trace = await this.firePerformance.trace('user from hasRut()');
    trace.start();
    try {
      this.errorService.setUser(credentials.user);
      const userData = await this.getUserInfoDoc(credentials.user.uid)
        .catch((err) => {
          trace.stop();
          this.errorService.recordError(err, 'user.service.ts',
            'getUserInfoDoc()', 'Error al obtener userInfo doc para sacar rut');
          throw err;
        });
      if (userData.rut) {
        trace.stop();
        return true;
      } else {
        trace.stop();
        return false;
      }
    } catch (err) {
      trace.stop();
      this.errorService.recordError(err, 'user.service.ts',
        'hasRut()', 'Error al obtener rut');
      throw err;
    }
  }

  getProfileDataFromUser(userDataRaw: string) {
    const userData = JSON.parse(userDataRaw);

    const httpHeaders = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${userData.credential.oauthAccessToken}`
      })
    };
    return this.http.get<any>(AppGlobals.profileMeEndpoint(userData.user.stsTokenManager.apiKey), httpHeaders).toPromise();
  }

  saveSurveyResponse(data: any): Observable<any> {
    const endpoint = AppGlobals.saveSurveyResponse;
    const httpHeader = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        // 'Origin': 'neatpagos.com',
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, data, httpHeader).pipe(
      tap(() => {
      })
    );
  }

  async signInWithCustomToken(customToken: string): Promise<User> {
    try {
      await this.fireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .catch(err => {
          this.errorService.recordError(err, 'user.service.ts',
            'this.fireAuth.setPersistence()', 'Error al setear persistencia de usuario en inicio de sesión');
          throw err;
        });
      const userCredential = await this.fireAuth.signInWithCustomToken(customToken)
        .catch(err => {
          this.errorService.recordError(err, 'user.service.ts',
            'signInWithCustomToken()', 'Error al iniciar sesión');
          throw err;
        });
      const userToken = await userCredential.user.getIdToken(true);
      this.addIdToken(userToken);
      this.fireAnalytics.logEvent('login', { method: 'CustomToken' });
      this.fireAnalytics.setUserId(userCredential.user.uid);
      this.errorService.setUser(userCredential.user);
      const userDoc = await this.getUserfromUserDoc(userCredential.user.uid)
        .catch(err => {
          this.errorService.recordError(err, 'user.service.ts',
            'this.getUserDoc()', 'Error al obtener userType en inicio de sesión');
          throw err;
        });
      this.currentUser = userDoc;
      this.intercomService.updateIntercomUser(
        this.currentUser,
        Number(userCredential.user.metadata.creationTime) / 1000,
        Number(userCredential.user.metadata.lastSignInTime) / 1000
      );
      this.currentUser.extend_with(userCredential.user, userCredential.additionalUserInfo);
      return this.currentUser;
    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts',
        'signInWithCustomToken()', 'Error al iniciar sesión');
      let msg = 'Error al iniciar sesión. ';
      msg += this.errorService.getErrorMessage(err.code);
      throw msg;
    }
  }

  // Firebase Apple Sign-in
  async signInWithApple(registerForm?: {
    acceptTerms: boolean;
    referrerId: string | null;
    comesFrom: string | null;
    birthDate?: { day: number, month: number, year: number };
  }, isMobile?: boolean, appVersion?: string): Promise<User> {
    try {
      const provider = new OAuthProvider('apple.com');
      if (registerForm) {
        provider.setCustomParameters({
          locale: 'es-CL'
        });
        provider.addScope('email');
        provider.addScope('name');
      }
      const userCredential = await this.oAuthProvider(provider);
      let response: any;
      try {
        response = await this.getProfileDataFromUser(JSON.stringify(userCredential));
      } catch (err) {
        this.errorService.recordError(err, 'user.service.ts', 'this.getProfileDataFromUser()', 'Error al ejecutar getProfileDataFromUser');
      }
      if (registerForm) {
        if (response && response.birthdays?.length > 0) {
          if (response.birthdays[0].date) {
            registerForm.birthDate = response.birthdays[0].date;
          }
        }
      } else {
        const userToken = await userCredential.user.getIdToken(true);
        const userRegistrationConsistency = await this.cloudService.userRegistrationConsistency(userToken);
        if (userRegistrationConsistency?.redirect?.includes('registrar')) {
          this.router.navigate(['registrar']);
          this.swalService.swalError2('No existe cuenta Neat con ese correo',
            `Revisa si te registraste con otro correo o crea una nueva cuenta en Neat`, IconErrorModals.mailWarning, false, false);
          return null;
        }
      }
      await this.setExtraConfig(userCredential);
      if (userCredential!.additionalUserInfo.isNewUser) {
        if (registerForm) {
          // Heap Tracks 2.0
          this.fireAnalytics.logEvent('Registro', {
            Respuesta: 'Exito',
            Canal: isMobile ? 'Web-mobile' : 'Web-desktop',
            Versión: appVersion,
            Método: 'Apple'
          });
          return await this.setInitialUserConfig(userCredential, registerForm);
        } else {
          this.router.navigate(['registrar']);
          return null;
        }
      } else {
        return await this.getInitialUserConfig(userCredential);
      }

    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts', 'this.oAuthProvider()', 'Error al iniciar sesión con google');
      return err;
    }
  }


  // Firebase Google Sign-in
  async signInWithGoogle(registerForm?: {
    acceptTerms: boolean;
    referrerId: string | null;
    comesFrom: string | null;
    birthDate?: { day: number, month: number, year: number };
  }, isMobile?: boolean, appVersion?: string): Promise<User> {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      if (registerForm) {
        provider.setCustomParameters({
          prompt: 'consent'
        });
        provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
        provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
      }
      const userCredential = await this.oAuthProvider(provider);
      let response: any;
      try {
        response = await this.getProfileDataFromUser(JSON.stringify(userCredential));
      } catch (err) {
        this.errorService.recordError(err, 'user.service.ts', 'this.getProfileDataFromUser()', 'Error al ejecutar getProfileDataFromUser');
      }
      if (registerForm) {
        if (response && response.birthdays?.length > 0) {
          if (response.birthdays[0].date) {
            registerForm.birthDate = response.birthdays[0].date;
          }
        }
      } else {
        const userToken = await userCredential.user.getIdToken(true);
        const userRegistrationConsistency = await this.cloudService.userRegistrationConsistency(userToken);
        if (userRegistrationConsistency?.redirect?.includes('registrar')) {
          this.router.navigate(['registrar']);
          this.swalService.swalError2('No existe cuenta Neat con ese correo',
            `Revisa si te registraste con otro correo o crea una nueva cuenta en Neat`, IconErrorModals.mailWarning, false, false);
          return null;
        }
      }
      await this.setExtraConfig(userCredential);
      if (userCredential!.additionalUserInfo.isNewUser) {
        if (registerForm) {
          // Heap Tracks 2.0
          this.fireAnalytics.logEvent('Registro', {
            Respuesta: 'Exito',
            Canal: isMobile ? 'Web-mobile' : 'Web-desktop',
            Versión: appVersion,
            Método: 'Google'
          });
          return await this.setInitialUserConfig(userCredential, registerForm);
        } else {
          this.router.navigate(['registrar']);
          return null;
        }
      } else {
        return await this.getInitialUserConfig(userCredential);
      }

    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts', 'this.oAuthProvider()', 'Error al iniciar sesión con google');
      return err;
    }
  }

  async getInitialUserConfig(userCredential: firebase.auth.UserCredential) {
    const trace = await this.firePerformance.trace('user from getInitialUserConfig()');
    trace.start();
    try {
      const getUserDoc = await this.getUserfromUserDoc(userCredential.user.uid)
        .catch((err) => {
          trace.stop();
          this.errorService.recordError(err, 'user.service.ts',
            'getUserfromUserDoc()', 'Error al obtener ususario desde doc data');
          throw err;
        });
      this.swalService.swalToastGeneral('¡Bienvenido de vuelta!', 'success');
      this.currentUser = getUserDoc;
      this.intercomInit(userCredential);
      this.currentUser.extend_with(userCredential.user, userCredential.additionalUserInfo);
      await this.fireAnalytics.logEvent('login', { method: 'Google' });
      trace.stop();
      return this.currentUser;
    } catch (err) {
      trace.stop();
      this.errorService.recordError(err, 'user.service.ts',
        'this.getInitialUserConfig()', 'Error al registrarse con Google');
    }

  }

  intercomInit(userCredential: firebase.auth.UserCredential) {
    this.intercomService.updateIntercomUser(
      this.currentUser,
      Number(userCredential.user.metadata.creationTime) / 1000,
      Number(userCredential.user.metadata.creationTime) / 1000
    );
  }

  partialIntercomInit(userCredential: firebase.auth.UserCredential, newUser: User) {
    this.intercomService.updateIntercomUser(
      newUser,
      Number(userCredential.user.metadata.creationTime) / 1000,
      Number(userCredential.user.metadata.creationTime) / 1000
    );
  }

  async setExtraConfig(userCredential: firebase.auth.UserCredential) {
    const trace = await this.firePerformance.trace('user from setExtraConfig()');
    trace.start();
    await this.fireAnalytics.setUserId(userCredential.user.uid);
    await this.fireAnalytics.setUserProperties({
      name: userCredential.user.displayName,
      email: userCredential.user.email
    });
    trace.stop();
    this.errorService.setUser(userCredential.user);
  }

  async setInitialUserConfig(userCredential: firebase.auth.UserCredential, registerForm?: {
    acceptTerms: boolean;
    referrerId: string | null;
    comesFrom: string | null;
    birthDate?: { day: number, month: number, year: number };
  }) {
    const trace = await this.firePerformance.trace('user from setInitialUserConfig()');
    trace.start();
    try {
      const userToken = await userCredential.user.getIdToken(true);
      this.addIdToken(userToken);
      this.currentUser = new User().from_authProvider(AuthProvider.google, userCredential, registerForm);
      await Promise.all([this.createUserDocument('registerGoogle'),
        this.storageNewBucket(this.currentUser), this.fireAnalytics.logEvent('sign_up', { method: 'Google' })]);
      this.intercomInit(userCredential);
      try {
        this.setInitialHeapConfig(userCredential.user.uid, userCredential.user.email);
      } catch (e) {
        this.errorService.recordError(e, 'user.service.ts',
          `heap.addUserProperties()', 'Error al asignar heap property to ${userCredential.user.email} in setInitialUserConfig`);
      }
      this.currentUser.extend_with(userCredential.user, userCredential.additionalUserInfo);
      await this.fireAnalytics.logEvent('sign_up', { method: 'Google' });
      await this.waitForUserDocCreation(this.currentUser.id);
      trace.stop();
      return this.currentUser;
    } catch (err) {
      trace.stop();
      this.errorService.recordError(err, 'user.service.ts',
        'setInitialUserConfig', 'Error en creación de usuario (registerGoogle)');
      throw err;
    }
  }

  async createGoogleUserDocument(partialNewUser: User): Promise<void> {
    return await this.db.firestoreNewDoc('users', Object.assign({}, partialNewUser), partialNewUser.id)
      .catch((err) => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.db.firestoreNewDoc', 'Error en creación de user doc (registerGoogle)');
      });
  }

  /**
   * Inicio de sesión mediante "EmailAndPassword", retorna una promesa de tipo User
   * @param value
   * Debe ser un objeto que contenga la propiedad email y password, ambos string
   */
  async signInWithEmail(value: { email: string; password: string }): Promise<User> {
    try {
      await this.fireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .catch(err => {
          this.errorService.recordError(err, 'user.service.ts',
            'this.fireAuth.setPersistence()', 'Error al setear persistencia de usuario en inicio de sesión');
          throw err;
        });
      const userCredential = await this.fireAuth.signInWithEmailAndPassword(value.email, value.password)
        .catch(err => {
          this.errorService.recordError(err, 'user.service.ts',
            'signInWithEmailAndPassword()', 'Error al iniciar sesión');
          throw err;
        });
      const userToken = await userCredential.user.getIdToken(true);
      this.addIdToken(userToken);
      this.fireAnalytics.logEvent('login', { method: 'Email' });
      this.fireAnalytics.setUserId(userCredential.user.uid);
      this.errorService.setUser(userCredential.user);
      const userDoc = await this.getUserfromUserDoc(userCredential.user.uid)
        .catch(err => {
          this.errorService.recordError(err, 'user.service.ts',
            'this.getUserDoc()', 'Error al obtener userType en inicio de sesión');
          throw err;
        });
      this.currentUser = userDoc;
      this.intercomService.updateIntercomUser(
        this.currentUser,
        Number(userCredential.user.metadata.creationTime) / 1000,
        Number(userCredential.user.metadata.lastSignInTime) / 1000
      );
      this.currentUser.extend_with(userCredential.user, userCredential.additionalUserInfo);
      return this.currentUser;
    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts',
        'signInWithEmail()', 'Error al iniciar sesión');
      let msg = 'Error al iniciar sesión. ';
      msg += this.errorService.getErrorMessage(err.code);
      throw msg;
    }
  }

  async emailVerification(): Promise<void> {
    return await firebase.auth().currentUser.sendEmailVerification(this.actionCodeSettings)
      .catch(err => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.fireAuth.currentUser.sendEmailVerification()', 'Error al enviar email de notificación por Firebase');
        let msg = 'Error en verificación de correo. ';
        msg += this.errorService.getErrorMessage(err.code);
        throw msg;
      });
  }

  async rutEnroller(rut: string) {
    const trace = await this.firePerformance.trace('user from rutEnroller()');
    trace.start();
    const cfResponse: { status: boolean, message: string } = await this.cloudService.rutEnroller(rut)
      .catch(err => {
        trace.stop();
        this.errorService.recordError(err, 'google-register.component.ts',
          'this.cloudService.rutEnroller()', 'Error al enrolar rut con Cloud Funtion');
      });
    trace.stop();
    return cfResponse;
  }

  // Not sure if these can be use with async await pattern
  getUserBrowserCredentials(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      try {
        if ((window as any).PasswordCredential || (window as any).FederatedCredential) {
          (navigator as any).credentials.get({
            password: true,
            unmediated: false,
            federated: {
              providers: [
                'https://account.google.com',
                // 'https://www.facebook.com'
              ]
            }
          }).then((cred) => {
            if (cred) {
              resolve(cred);
            } else {
              resolve();
            }
          })
            .catch(err => {
              this.errorService.recordError(err, 'user.service.ts',
                'getUserBrowserCredentials()', 'Error al obtener credenciales en then');
              reject();
            });
        } else {
          // public-key credentials not supported
          resolve();
        }
      } catch (err) {
        this.errorService.recordError(err, 'user.service.ts',
          'getUserBrowserCredentials()', 'Error al obtener credenciales');
        reject();
      }
    });
  }
  // Not sure if these can be use with async await pattern
  userAutoLoginWithCreds(cred: any) {
    return new Promise<boolean>(async (resolve, reject) => {
      if (cred) {
        switch (cred.type) {
        case 'password':
          try {
            this.signInWithEmail({ email: cred.id, password: cred.password })
              .then(() => resolve(true))
              .catch(err => {
                reject(err);
              });
          } catch (err) {
            reject(err.message);
          }
          break;
        case 'federated':
          switch (cred.provider) {
          case 'https://accounts.google.com':
          }
          break;
        }
      } else {
        resolve(false);
      }
    });
  }

  async passwordReset(email: string): Promise<void> {
    await this.fireAuth.sendPasswordResetEmail(email, this.actionCodeSettings)
      .catch(err => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.fireAuth.sendPasswordResetEmail()', 'Error al resetear la contraseña por Firebase');
        let msg = 'Error al restablecer la contraseña. ';
        msg += this.errorService.getErrorMessage(err.code);
        throw msg;
      });
    this.fireAnalytics.logEvent('User password reset');
  }

  async requestLoginWithLink(email: string) {
    const actionCodeSettings = {
      url: `${AppGlobals.neatUrl}`,
      handleCodeInApp: true
    };
    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
      window.localStorage.setItem('emailForSignIn', email);
      this.fireAnalytics.logEvent('requestLogInLink', { email: email, date: new Date() });
      this.fireAnalytics.logEvent(`User request login without pass, using link sended to his email: ${email}`);
    }).catch((error) => {
      this.errorService.recordError(error, 'user.service.ts',
        'loginWithLinkRequest()',
        `Error al enviar link para autenticación con vinculo al mail: ${email}, errorCode: ${error.code}, errorMsg: ${error.message} `);
      throw 'Error al enviar link para autenticación con vínculo al mail';
    });
  }

  async loginWithLink(email: string) {
    await firebase.auth().signInWithEmailLink(email, window.location.href).then((result) => {
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn');
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      if (!result.additionalUserInfo.isNewUser) {
        this.fireAnalytics.logEvent('SignInViaLink', { email: email, date: new Date() });
        this.fireAnalytics.logEvent(`User request login without pass, using link sended to his email: ${email}`);
      } else {
        throw 'No tienes una cuenta creada en Neat';
      }
    })
      .catch((error) => {
        this.errorService.recordError(error, 'user.service.ts',
          'loginWithLink()', `Error al iniciar sesión con link - email: ${email}`);
        throw 'Error al iniciar sesión con enlace';
      });
  }

  async registerWithEmail(registerFormValues: RegisterForm): Promise<User> {
    const trace = await this.firePerformance.trace('user from registerWithEmail()');
    trace.start();
    try {
      const userCredential = await this.fireAuth.createUserWithEmailAndPassword(registerFormValues.email, registerFormValues.password);
      this.errorService.setUser(userCredential.user);
      this.currentUser = new User().from_authProvider(AuthProvider.firebase, userCredential, registerFormValues);
      this.fireAnalytics.logEvent('sign_up', { method: 'Email' });
      this.fireAnalytics.setUserId(userCredential.user.uid);
      this.fireAnalytics.setUserProperties({
        name: this.currentUser.displayName,
        email: userCredential.user.email
      });
      this.intercomService.updateIntercomUser(
        this.currentUser,
        Number(userCredential.user.metadata.creationTime) / 1000,
        Number(userCredential.user.metadata.creationTime) / 1000
      );
      await Promise.all([this.createUserDocument('registerWithEmail'), this.updateProfile(), this.storageNewBucket(this.currentUser)]);
      this.currentUser = this.currentUser.extend_with(userCredential.user, userCredential.additionalUserInfo);
      await this.waitForUserDocCreation(this.currentUser.id);
      trace.stop();
      this.userSaved = this.currentUser;
      return this.currentUser;
    } catch (err) {
      trace.stop();
      this.errorService.recordError(err, 'user.service.ts',
        'registerWithEmail()', `Error al registrar usuario ${JSON.stringify(this.currentUser)}`);
      let msg = 'Error al registrarse. ';
      msg += this.errorService.getErrorMessage(err.code);
      throw msg;
    }
  }

  async createUserDocument(provider: string): Promise<void> {
    return await this.db.firestoreNewDoc('users', Object.assign({}, this.currentUser), this.currentUser.id)
      .catch(err => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.db.firestoreNewDoc()', `Error en creación de doc para user${this.currentUser.id} ${provider}`);
      });
  }

  async updateProfile(): Promise<void> {
    return await (await this.fireAuth.currentUser).updateProfile({ displayName: this.currentUser.displayName });
  }

  async storageNewBucket(user: User): Promise<void> {
    return await this.db.storageNewBucket(`/users/${user.id}/createBucket`)
      .catch(err => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.db.storageNewBucket()', `Error crear bucket user para ${user.id}`);
      });
  }

  async waitForUserDocCreation(id: string) {
    const trace = await this.firePerformance.trace('waitForUserDocCreation');
    trace.start();
    await sleep(1000);
    let userDocData = (await this.db.firestoreGetData('users', id)).data();
    while (!userDocData.creationDone) {
      await sleep(350);
      userDocData = (await this.db.firestoreGetData('users', id)).data();
    }
    trace.stop();
  }

  isLoggedIn() {
    return this.fireAuth.currentUser;
  }

  // Are we using the seaction?

  cargarStorage() {
    if (localStorage.getItem('id')) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.userId = localStorage.getItem('id');
      this.displayName = localStorage.getItem('userName');
      this.userRut = localStorage.getItem('userRut');
      this.displayName = localStorage.getItem('userName');
      this.userPhotoUrl = localStorage.getItem('userPhotoUrl');
    } else {
      this.user = null;
      this.userId = '';
      this.userInfo = null;
      this.displayName = '';
      this.userRut = '';
      this.displayName = '';
      this.userPhotoUrl = '';
    }
  }

  guardarStorage(id: string, user: User, userInfo: boolean) {
    localStorage.setItem('id', id);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    this.user = user;
    this.userId = id;
    this.userInfo = userInfo;
  }

  borrarStorage() {
    this.user = null;
    this.userId = '';
    this.displayName = '';
    this.userRut = '';
    this.userPhotoUrl = '';
    this.userInfo = null;

    localStorage.removeItem('userInfo');
    localStorage.removeItem('id');
    localStorage.removeItem('user');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRut');
    localStorage.removeItem('userPhotoUrl');
  }
  userNameStorageSave(userName: string) {
    this.displayName = userName;
    localStorage.setItem('userName', userName);
  }
  rutStorageSave(rut: string = '') {
    this.userRut = rut;
    localStorage.setItem('userRut', rut);
  }
  checkRutStorage(): boolean {
    if (localStorage.getItem('userRut')) {
      return true;
    } else {
      return false;
    }
  }
  photoUrlStorageSave(userPhotoUrl: string) {
    this.userPhotoUrl = userPhotoUrl;
    localStorage.setItem('userPhotoUrl', userPhotoUrl);
  }

  setInitialHeapConfig(userID: string, userEmail: string) {
    heap.identify(userID);
    heap.addUserProperties({ 'email': userEmail });
  }

  setHeapConfig(user: User) {
    heap.identify(user.id);
    heap.addUserProperties({
      'email': user.email,
      'referred': user.referrerId ? true : false,
      'category': user?.neatUniqueData?.currentState?.category ? user?.neatUniqueData?.currentState?.category : 'Starter',
      'comesFrom': user.comesFrom,
      'continuousMonthsPaid': user.continuousMonthsPaid,
      'webVersion': user.webVersion ? user.webVersion : 1,
      'emailVerified': user.emailVerified,
      'referredRegistered': user.referredRegistered ? user.referredRegistered.length : 0,
      'referredWhoPayed': user.referredWhoPayed ? user.referredWhoPayed.length : 0
    });
  }

  deleteUserRequest(tokenId: string): Observable<any> {
    let endpoint: string;
    endpoint = AppGlobals.deleteUser;
    if (isDevMode()) {
      endpoint = AppGlobals.deleteUserBeta;
    }
    const httpHeader = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, {}, httpHeader).pipe(
      tap(() => {
      }),
      catchError(this.handleError<any>('deleteUserRequest'))
    );
  }

  checkMail(email: string): Observable<any> {
    const endpoint = AppGlobals.checkMail;
    const httpHeader = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, { email }, httpHeader);
  }

  private handleError<T>(operation: string, result?: T) {
    return (error: any): Observable<T> => {
      this.errorService.recordError(error, 'user.service.ts',
        operation, 'Error al procesar comunicación https');
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}

interface RegisterForm {
  email: string;
  password: string;
  name: string;
  lastNames: string;
  rut: string;
  birthDate: { day: number, month: number, year: number };
  referrerId: string | null;
  comesFrom: string | null;
  acceptTerms: boolean;
}

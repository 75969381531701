/* eslint-disable indent */
import { ICustomData, IPaymentOption, INeatInformation } from 'neat-lib/dist/Interfaces/IData';

import { Payment } from './abstract-payment.model';
import { IEntity } from '../interfaces/entities.interface';
import { Student, Member, Son } from '../interfaces/people.interface';
import { AppGlobals } from '../shared/constants';
import {
  CurrentPaymentStatus,
  PayingAccountUnit,
  UserType,
  EntityTypeLocale,
  EntityType,
  ProfessionalService,
} from '../shared/enums/enums.enum';


export abstract class Entity implements IEntity {
  public alias: string;
  public id: string;
  public entityType: EntityType;
  public ownerEmail = '';
  public ownerRut: string;
  public ownerFullName: string;
  public ownerPhone: string;
  public customData?: ICustomData;
  public lesseeEmail = '';
  public lesseeRut: string;
  public lesseeFullName: string;
  public lesseePhone: string;
  public adminEmail = '';
  public creationType: any;
  public adminRut: string;
  public adminFullName: string;
  public adminPhone: string;
  public whoseBankData: UserType.owner | UserType.admin;
  public bankName: string;
  public bankAccountNumber: string;
  public bankAccountType: string;
  public bankAccountTypeName: string;
  public hidden: boolean;
  public paymentInProgress?: Payment;
  public lastPayment?: Payment;
  public paymentLate?: Payment;
  public state: string;
  public city: string;
  public streetName: string;
  public streetNumber: string;
  public apartmentNumber: string;
  public neatInformation?: INeatInformation;
  public latitud: number;
  public longitud: number;
  public value: number = 0;
  public valueUF: number = 0;
  public payingAccountUnit: PayingAccountUnit = PayingAccountUnit.CLP;
  public lastTransferComment: string;
  public establishmentName: string;
  public lastPaymentDate?: number | any;
  public transferLimitDate: number;
  public receiverEmailNotification = true;
  // XML1.<Identificador> o XML2.<CodigoIdentificador>
  public entityNumber: number;
  public currentPaymentStatus: CurrentPaymentStatus = CurrentPaymentStatus.waiting;
  public payments: Array<string>;
  public lastUpdate: number;
  public whoseBillingUserType?: UserType;
  public hasPayments: boolean;
  public sendReminderNotification = true;
  public sendPaymentProcessNotification = true;
  public housingType: string;
  public block: string;
  public isDisable = false;
  public payingMonth: string = AppGlobals.nowMonthName;
  public createDate: number;
  public referenceAddress: string;
  public elderName?: string;
  public elderRut?: string;
  public students?: Student[];
  public members?: Member[];
  public son?: Son[];
  public category?: ProfessionalService;
  public serviceType?: string;
  public businessType?: string;
  public billNumber?: number;
  public clientCode?: string;
  public serviceCode?: string;
  //TODO: interface de PaymentOption
  public fixedValue?: boolean;
  public isVerified?: boolean;
  public associatedPayments?: Array<string>;
  public hasDepositeeBenefits?: boolean;
  public monthlyPayment?: boolean;
  public incompleteSchema?: boolean;
  public isExpressPayment?: boolean;
  public smbProvider?: boolean;
  public paymentOption: IPaymentOption = {
    automatic: null,
    product: null,
    paymentMethodId: null,
  };
  public isInProgress?: boolean;
  public paymentCreateDate?: any;
  public logoUrl?: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  // Admin puede no existir..
  showAdminName(): string {
    switch (this.adminFullName) {
      case '':
        return '-';
      default:
        return String(this.adminFullName);
    }
  }

  isEntityPayed(): boolean {
    if (
      this.currentPaymentStatus !== CurrentPaymentStatus.late &&
      this.currentPaymentStatus !== CurrentPaymentStatus.waiting
    ) {
      return true;
    } else {
      return false;
    }
  }

  showCurrentPaymentStatus(): string {
    switch (this.currentPaymentStatus) {
      case CurrentPaymentStatus.waiting:
        return 'no pagado';
      case CurrentPaymentStatus.late:
        return 'atrasado';
      case CurrentPaymentStatus.done:
        return 'pagado';
      case CurrentPaymentStatus.process:
        return 'en proceso';
      case CurrentPaymentStatus.error:
        return 'error';
      default:
        return '-';
    }
  }

  showWhoseBankData(): string {
    switch (this.whoseBankData) {
      case 'OWNER':
        return 'Propietario';
      case 'ADMIN':
        return 'Administrador';
      default:
        return 'No hay información';
    }
  }

  showAccountType(): string {
    switch (this.bankAccountType) {
      case '1':
        return 'Cuenta Corriente';
      case '2':
        return 'Cuenta Vista';
      case '3':
        return 'Chequera Electrónica';
      case '4':
        return 'Cuenta de Ahorro';
      default:
        return '-';
    }
  }

  showAccountNumber(): string {
    if (!this.bankAccountNumber) {
      return '-';
    } else {
      return this.bankAccountNumber;
    }
  }

  showRecieverEmail(): string {
    switch (this.whoseBankData) {
      case 'OWNER':
        return this.ownerEmail;
      case 'ADMIN':
        return this.adminEmail;
      default:
        return '-';
    }
  }

  showRecieverFullName(): string {
    switch (this.whoseBankData) {
      case 'OWNER':
        return this.ownerFullName;
      case 'ADMIN':
        return this.adminFullName;
      default:
        return '-';
    }
  }

  showRecieverRut(): string {
    switch (this.whoseBankData) {
      case 'OWNER':
        return this.ownerRut;
      case 'ADMIN':
        return this.adminRut;
      default:
        return '-';
    }
  }

  showFullAddress(): string {
    if (!this.apartmentNumber || typeof this.apartmentNumber === 'undefined') {
      return `${this.streetName} ${this.streetNumber}, ${this.city}`;
    } else {
      return `${this.streetName} ${this.streetNumber} - ${this.apartmentNumber}, ${this.city}`;
    }
  }

  showPartialAddress(): string {
    if (!this.apartmentNumber || typeof this.apartmentNumber === 'undefined') {
      return `${this.streetName} ${this.streetNumber}`;
    } else {
      return `${this.streetName} ${this.streetNumber}, depto. ${this.apartmentNumber}`;
    }
  }


  validatePayableStatus(): boolean {
    if (
      this.validateMinimumNecessaryFields() &&
      this.validatePayableCurrentPaymentStatuses() &&
      this.validateValues()
    ) {
      return true;
    }
    return false;
  }

  validateMinimumNecessaryFields(): boolean {
    const wrongFields = this.wrongFields();
    if (wrongFields.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  wrongFields(): Array<string> {
    const entityFields = [
      'lesseeEmail',
      'lesseeRut',
      'lesseeFullName',
      'whoseBankData',
      'bankName',
      'bankAccountNumber',
      'bankAccountType',
      'city',
      'streetName',
      'streetNumber'
    ];
    if (this.payingAccountUnit === PayingAccountUnit.CLP) {
      entityFields.push('value');
    } else {
      entityFields.push('valueUF');
    }
    const counterpartFields = ['Email', 'Rut', 'FullName'];

    const wrongFields = [];
    for (const field of entityFields) {
      if (!this[field]) {
        wrongFields.push(field);
      }
    }
    if (this.whoseBankData) {
      for (const field of counterpartFields) {
        if (!this[this.whoseBankData.toLowerCase() + field]) {
          wrongFields.push(this.whoseBankData.toLowerCase() + field);
        }
      }
    }

    return wrongFields;
  }

  validatePayableCurrentPaymentStatuses(): boolean {
    if (
      this.currentPaymentStatus === CurrentPaymentStatus.waiting ||
      this.currentPaymentStatus === CurrentPaymentStatus.done ||
      this.currentPaymentStatus === CurrentPaymentStatus.late ||
      this.currentPaymentStatus === CurrentPaymentStatus.process
    ) {
      return true;
    }
    return false;
  }

  validateValues(): boolean {
    if (this.value > 2990000 || this.value < AppGlobals.minNeatCost()) {
      return false;
    }
    return true;
  }

  showEntityTypeLocale(): EntityTypeLocale {
    return EntityTypeLocale[this.entityType];
  }
}

<div>
    <div class="d-flex justify-content-end">
        <span data-dismiss="alert" aria-label="close" class="ml-3 close-box" style="cursor: pointer;" (click)="closeBtn()">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                <g opacity="0.5">
                <mask id="mask0_60_809" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
                <rect x="0.922852" y="0.370117" width="16" height="16" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_60_809)">
                <path d="M5.1895 13.0365L4.25616 12.1031L7.9895 8.36979L4.25616 4.63646L5.1895 3.70312L8.92283 7.43646L12.6562 3.70312L13.5895 4.63646L9.85616 8.36979L13.5895 12.1031L12.6562 13.0365L8.92283 9.30313L5.1895 13.0365Z" fill="#1C1B1F"/>
                </g>
                </g>
            </svg>
        </span>
    </div>
    <div class="d-flex align-items-center flex-column">
        <div class="py-2">
            <img *ngIf="!entity?.customData && entity?.entityType !== 'basicService' && !entity?.smbProvider" [src]="entity?.logoUrl ? entity?.logoUrl : 'assets/img/entity-icons-3/'+entity?.entityType+'.svg'" style="height: 60px;" alt="{{ entity?.showEntityTypeLocale()}}">
            <img *ngIf="!entity?.customData && entity?.entityType !== 'basicService' && entity?.smbProvider" src="assets/img/smb-providers-logos/{{entity.ownerFullName}}.png" style="height: 60px;" alt="{{ entity?.showEntityTypeLocale()}}">
            <img *ngIf="!entity?.customData && entity?.entityType === 'basicService'" src="{{getBasicServiceLogo(entity?.utilityNumber)}}" onerror="this.src='assets/img/basic-services-icons/other.svg'"  style="max-height: 60px;" alt="{{ entity?.utilityName}}">
            <img *ngIf="entity?.customData && entity?.customData.providerName && ['Security Principal', 'Hipotecaria Security', 'Tango', 'Club de Polo San Cristóbal', 'Creditú', 'Edipro'].includes(entity?.customData.providerName)"
            [src]="entity?.logoUrl ? entity?.logoUrl : 'assets/img/entity-icons-4/'+entity?.customData.providerName+'.svg'" style="height: 60px;" alt="{{ entity?.showEntityTypeLocale()}}">
        </div>
        <span *ngIf="entity?.utilityName" class="d-block py-2 modal-title">Tu cuenta de {{entity?.utilityName}} fue agregada</span>
        <span *ngIf="entity?.customData?.providerName" class="d-block py-2 modal-title">Tu cuenta de {{entity?.customData.providerName}} fue agregada</span>
        <div class="py-2 my-2 blue-card w-100">
            <span *ngIf="!entity?.currentDebtError && enabledToPay && (entity?.value > 0)" class="d-block py-2">Deuda pendiente: <b>{{entity?.value | clpCurrency}}</b></span>
            <span *ngIf="!entity?.currentDebtError && enabledToPay && (entity?.value === 0)" class="d-block py-2">No tienes deuda pendiente 🙌</span>
            <span *ngIf="entity?.currentDebtError || !enabledToPay" class="d-block py-2">⚠️ No pudimos obtener una deuda</span>
        </div>
        <div class="py-2">
            <span *ngIf="!entity?.currentDebtError && enabledToPay && (entity?.value > 0) && (allEntities$ | async)?.length > 1" class="d-block secundary-text mobile-max-width">Puedes pagarla ahora, o programar el pago para un día fijo</span>
            <span *ngIf="!entity?.currentDebtError && enabledToPay && (entity?.value === 0)" class="d-block secundary-text">Programa tu próximo pago, y despreocúpate de pagar</span>
            <span *ngIf="entity?.currentDebtError || !enabledToPay" class="d-block secundary-text">Revisa si los datos de la cuenta que agregaste son correctos</span>
        </div>
        <div class="d-flex justify-content-center py-2 mobile-flex-column">
            <button *ngIf="(entity?.value > 0 || entity?.valueUF > 0) && enabledToPay" (click)="goToPay(entity)" class="neat-btn neat-btn__primary ml-0 ml-md-2">
                <span class="mr-2">
                    Ir a pagar 
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <mask id="mask0_1_1683" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
                    <rect x="0.5" width="16" height="16" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_1_1683)">
                    <path d="M9.83317 12L8.89984 11.0333L11.2665 8.66667H3.1665V7.33333H11.2665L8.89984 4.96667L9.83317 4L13.8332 8L9.83317 12Z" fill="white"/>
                    </g>
                </svg>
            </button>
            <button *ngIf="(allEntities$ | async)?.length > 1 || entity?.value === 0 || entity?.currentDebtError || !enabledToPay" (click)="entity?.currentDebtError || !enabledToPay ? goToEdit(entity) : goToAutomate(entity)" class="neat-btn neat-btn__primary mr-0 mr-md-2 mt-2 mt-md-0">
                <span class="mr-2">
                    {{entity?.currentDebtError || !enabledToPay ? 'Revisar datos' : 'Programar pago'}}
                </span>
                <svg *ngIf="!entity?.currentDebtError && enabledToPay" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <mask id="mask0_1_1688" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
                    <rect x="0.5" width="16" height="16" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_1_1688)">
                    <path d="M3.83333 5.33301H13.1667V3.99967H3.83333V5.33301ZM3.83333 14.6663C3.46667 14.6663 3.15278 14.5358 2.89167 14.2747C2.63056 14.0136 2.5 13.6997 2.5 13.333V3.99967C2.5 3.63301 2.63056 3.31912 2.89167 3.05801C3.15278 2.7969 3.46667 2.66634 3.83333 2.66634H4.5V1.33301H5.83333V2.66634H11.1667V1.33301H12.5V2.66634H13.1667C13.5333 2.66634 13.8472 2.7969 14.1083 3.05801C14.3694 3.31912 14.5 3.63301 14.5 3.99967V7.78301C14.2889 7.68301 14.0722 7.59967 13.85 7.53301C13.6278 7.46634 13.4 7.41634 13.1667 7.38301V6.66634H3.83333V13.333H8.03333C8.11111 13.5775 8.20278 13.8108 8.30833 14.033C8.41389 14.2552 8.53889 14.4663 8.68333 14.6663H3.83333ZM12.5 15.333C11.5778 15.333 10.7917 15.008 10.1417 14.358C9.49167 13.708 9.16667 12.9219 9.16667 11.9997C9.16667 11.0775 9.49167 10.2913 10.1417 9.64134C10.7917 8.99134 11.5778 8.66634 12.5 8.66634C13.4222 8.66634 14.2083 8.99134 14.8583 9.64134C15.5083 10.2913 15.8333 11.0775 15.8333 11.9997C15.8333 12.9219 15.5083 13.708 14.8583 14.358C14.2083 15.008 13.4222 15.333 12.5 15.333ZM13.6167 13.583L14.0833 13.1163L12.8333 11.8663V9.99967H12.1667V12.133L13.6167 13.583Z" fill="white"/>
                    </g>
                </svg>
            </button>
        </div>
        <hr style="opacity: 0.3;background: #B7D3F9;width: 100%;">
        <div *ngIf="!entity?.currentDebtError && enabledToPay" class="pt-2">
            <span class="decide-later" (click)="closeBtn()">
                Decidiré más tarde
            </span>
        </div>
        <div *ngIf="entity?.currentDebtError || !enabledToPay" class="pt-2">
            <span class="decide-later" (click)="openIntercom()">
                Necesito ayuda
            </span>
        </div>
    </div>
</div>
import { Injectable, isDevMode } from '@angular/core';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(
    private errorHandler: ErrorHandlerService,
  ) { }

  setCookie(cname:string, cvalue: string, dname: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires=${ d.toUTCString()}`;
    const domain = `domain=${dname}`;
    if (isDevMode()) {
      document.cookie = `${cname}=${cvalue};${expires};path=/`;
    } else {
      document.cookie = `${cname}=${cvalue};${domain};${expires};path=/`;
    }
  }

  getCookie(cname:string): string {
    try {
      const name = `${cname  }=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    } catch(err) {
      this.errorHandler.recordError(
        err,
        'cookies.service.ts',
        'getCookie()',
        'Error al obtener cookies desde getCookie'
      );
    }
  }

  deleteCookie(cname: string, dname: string): void {
    const domain = `domain=${dname}`;
    if (isDevMode()) {
      document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    } else {
      document.cookie = `${cname}=; ${domain} ; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  }

}

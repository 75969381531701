import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RentListService } from '@services/rent-list/rent-list.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { EntityType, EntityTypeLocale, ProfessionalService, UserTypeLocale } from '@shared/enums/enums.enum';
import { MergeTypes } from '@shared/utils';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';
declare let Intercom: any;

@Component({
  selector: 'app-basic-services-payment-modal',
  templateUrl: './basic-services-payment-modal.component.html',
  styleUrls: ['./basic-services-payment-modal.component.scss']
})
export class BasicServicesPaymentModalComponent implements OnInit {
  entity: Entity;
  basicServicesDone: MergeTypes<BasicService, Entity>[];
  basicServicesIssue: any;
  basicServicesIssues: any;
  errorMessage: string;
  userTypeLocale = UserTypeLocale;
  entityTypeLocale = EntityTypeLocale;
  professionalServices = ProfessionalService;
  entityType = EntityType;
  today = new Date();
  basicServicePayed: boolean;
  customProviderPayed: boolean;
  neatEntityPayed: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BasicServicesPaymentModalComponent>,
    private router: Router,
    private errorService: ErrorHandlerService,
    private rentListService: RentListService,
  ) {}

  ngOnInit(): void {
    try {
      const deltaValues = this.data?.deltaValues ? this.data?.deltaValues : [];
      let response: any;

      if (this.data.payment.response instanceof Array) {
        response = this.data.payment.response;
      } else if (this.data?.payment?.response?.response) {
        response = JSON.parse(this.data.payment?.response?.response?.body ? this.data.payment?.response?.response?.body : this.data.payment?.response?.response.error);
      } else {
        response = JSON.parse(this.data.payment?.response?.body ? this.data.payment?.response?.body : this.data.payment?.response?.error);
      }
      if (response?.error) {
        this.errorMessage = 'Ha ocurrido al realizar este pago';
        const entitiesIdsDone = [];
        const entitiesIdsFailed = this.data.payment.entity.map(res => res.id);
        this.basicServicesDone = this.data.payment.entity.filter(entity => entitiesIdsDone.includes(entity.id))?.map(entity => Object.assign(entity, {deltaValue: deltaValues?.find(deltaValue => deltaValue.entityId === entity.id)?.deltaValue}));
        this.basicServicePayed = this.basicServicesDone.some(res => res.utilityName);
        this.customProviderPayed = this.basicServicesDone.some(res => res.customData);
        this.basicServicesIssues = this.data.payment.entity.filter(entity => entitiesIdsFailed.includes(entity.id));
        this.basicServicesIssue = this.basicServicesIssues;
      } else if (this.data?.payment?.response?.body) {
        const response = JSON.parse(this.data.payment?.response?.body ? this.data.payment?.response?.body : this.data.payment?.response?.error);
        const entitiesIdsDone = response.filter(response => response?.message).map(res => res.entityId);
        const entitiesIdsFailed = response.filter(response => !response?.message).map(res => res.entityId);
        const errorMessages = response.filter(response => response?.error).map(res => {return {entityId: res.entityId, message: res.error.message};});
        this.basicServicesDone = this.data.payment.entity.filter(entity => entitiesIdsDone.includes(entity.id))?.map(entity => Object.assign(entity, {deltaValue: deltaValues?.find(deltaValue => deltaValue.entityId === entity.id)?.deltaValue}));
        this.basicServicePayed = this.basicServicesDone.some(res => res.utilityName);
        this.customProviderPayed = this.basicServicesDone.some(res => res.customData);
        this.neatEntityPayed =  this.basicServicesDone.some(res => !res.utilityName && !res.customData);
        this.basicServicesIssues = this.data.payment.entity.filter(entity => entitiesIdsFailed.includes(entity.id));
        this.basicServicesIssue = this.data.payment.entity.filter(entity => entitiesIdsFailed.includes(entity.id)).map(res => Object.assign(res, {message: errorMessages?.find(error => error.entityId === res.id)?.message}));
        this.errorMessage = errorMessages?.length > 0 && this.allAreEqual(errorMessages) ? errorMessages[0].message.replace('este servicio', 'estos servicios') : null;
      } else {
        const entitiesIdsDone = response.find(response => response.body && JSON.parse(response.body) && JSON.parse(response.body).entityId) ? response.filter(response => response?.body && JSON.parse(response?.body) && JSON.parse(response?.body).entityId && !response?.error).map(res => JSON.parse(res?.body).entityId) : response.filter(response => response?.message && !response?.error).map(res => res.entityId);
        const entitiesIdsFailed = response.filter(response => (!response?.message || response?.error) && response.entityId).map(res => res.entityId).length !== 0 ? this.data.payment.entity.map(res => entitiesIdsDone.length > 0 ? !entitiesIdsDone.includes(res.id) && res.id : res.id) : [];
        const errorMessages = response.filter(response => response?.error).map(res => {return {entityId: res.entityId, message: res.error.message};});
        this.basicServicesDone = this.data.payment.entity.filter(entity => entitiesIdsDone.includes(entity.id))?.map(entity => Object.assign(entity, {deltaValue: deltaValues?.find(deltaValue => deltaValue.entityId === entity.id)?.deltaValue}));
        this.basicServicePayed = this.basicServicesDone.some(res => res.utilityName);
        this.customProviderPayed = this.basicServicesDone.some(res => res.customData);
        this.basicServicesIssues = this.data.payment.entity.filter(entity => entitiesIdsFailed?.includes(entity.id));
        this.basicServicesIssue = this.data.payment.entity.filter(entity => entitiesIdsFailed?.includes(entity.id)).map(res => Object.assign(res, {message: errorMessages?.find(error => error.entityId === res.id)?.message}));
        this.errorMessage = errorMessages?.length > 0 && this.allAreEqual(errorMessages) && errorMessages[0].message ? errorMessages[0].message.replace('este servicio', 'estos servicios') : null;
      }
    }
    catch (err) {
      this.errorService.recordError(err, 'basic-service-payment-modal.component.ts',
        'init()', 'Error al mostrar pagos success y fallidos');
    }
  }

  allAreEqual(array: any[]) {
    const result = array.every(element => {
      if (element.message === array[0].message) {
        return true;
      }
    });
    return result;
  }

  showAccountType(bankAccountType: string): string {
    switch (bankAccountType) {
    case '1':
      return 'Cuenta Corriente';
    case '2':
      return 'Cuenta Vista';
    case '3':
      return 'Chequera Electrónica';
    case '4':
      return 'Cuenta de Ahorro';
    default:
      return '-';
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async goToBasicServices() {
    await this.router.navigate(['/dashboard/home']);
    this.onNoClick();
  }

  async retryPayments() {
    if (this.basicServicesDone.length > 0) {
      await this.router.navigate(['/dashboard/home']);
    }
    this.onNoClick();
  }

  openChat() {
    Intercom('showNewMessage', '');
    this.onNoClick();
  }

  goToAutomate(): void {
    const analyticsParams = {event: 'goToAutomate', section: 'preloadedEntityPayedModal'};
    this.rentListService.goToAutomate(null, true, analyticsParams, this.dialogRef, this.basicServicesDone);
  }

}


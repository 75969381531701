import { Injectable } from '@angular/core';
import { User } from '../../../models/users/user.model';
declare global {
  interface Window {
    Intercom(
      arg0: string,
      arg1?: {
        app_id?: string;
        user_id?: string;
        name?: string;
        email?: string;
        created_at?: number;
        user_type?: string;
        last_seen?: number;
      }
    );
  }
}
@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  constructor() {}
  initIntercom() {
    window.Intercom('boot', {
      app_id: 'kvqogpa7',
    });
  }

  updateIntercomUser(user: Partial<User>, signUpDate: number, lastSignIn: number) {
    window.Intercom('update', {
      user_id: user.id,
      name: user.displayName, // Full name
      email: user.email, // Email address
      created_at: signUpDate, // Signup date as a Unix timestamp [seconds]
      user_type: user.userType,
      last_seen: lastSignIn
    });
    // if(window && window.Intercom){
    //   window.Intercom('boot', {
    //     app_id: 'kvqogpa7'
    //   });
    // }
  }
  // updateUser(user: Partial<User>, signUpDate: number, lastSignIn: number){
  //   if(window && window.Intercom){
  //     window.Intercom('update', {
  //       user_id: user.id,
  //       name: user.displayName, // Full name
  //       email: user.email, // Email address
  //       created_at: signUpDate, // Signup date as a Unix timestamp [seconds]
  //       user_type: user.userType,
  //       last_seen: lastSignIn,
  //       // user_hash: CryptoJS.HmacSHA256(user.id, key)
  //     });
  //   }
  // }
}

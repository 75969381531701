import { EntityType, EntityCollectionName } from '../shared/enums/enums.enum';
import { IEntityFactory } from '../interfaces/entities.interface';
import { Rent } from './entities/rent.model';
import { CommonExpenses } from './entities/common-expenses.model';
import { Deserializable } from '../interfaces/deserializable.interface';
import { Entity } from './abstract-entity.model';
import { Warehouse } from './entities/warehouse.model';
import { Parking } from './entities/parking.model';
import { MortgageFoot } from './entities/mortgage-foot';
import { Kindergarten } from './entities/kindergarten.model';
import { School } from './entities/school.model';
import { DomesticWorker } from './entities/domestic-worker.model';
import { NursingHome } from './entities/nursing-home.model';
import { SportClub } from './entities/sport-club.model';
import { SocialClub } from './entities/social-club.model';
import { Gym } from './entities/gym.model';
import { Alimony } from './entities/alimony.model';
import { Professional } from './entities/professional.model';
import { Others } from './entities/others.model';

class EntityFactory implements IEntityFactory {
  createInstance<T extends Deserializable>(
    EntityClass: new () => T,
    data: any
  ): T {
    return new EntityClass().deserialize(data);
  }

  deserializeEntity(
    entityType: EntityType | string,
    data: any
  ): Entity {
    switch (entityType) {
    case EntityType.rent:
    case 'Rent':
      return this.createInstance(Rent, data);
    case EntityType.commonExpenses:
    case 'CommonExpenses':
      return this.createInstance(CommonExpenses, data);
    case EntityType.warehouse:
    case 'Warehouse':
      return this.createInstance(Warehouse, data);
    case EntityType.parking:
    case 'Parking':
      return this.createInstance(Parking, data);
    case EntityType.mortgageFoot:
    case 'MortgageFoot':
      return this.createInstance(MortgageFoot, data);
    case EntityType.kindergarten:
    case 'Kindergarten':
      return this.createInstance(Kindergarten, data);
    case EntityType.school:
    case 'School':
      return this.createInstance(School, data);
    case EntityType.domesticWorker:
    case 'DomesticWorker':
      return this.createInstance(DomesticWorker, data);
    case EntityType.nursingHome:
    case 'NursingHome':
      return this.createInstance(NursingHome, data);
    case EntityType.sportClub:
    case 'SportClub':
      return this.createInstance(SportClub, data);
    case EntityType.socialClub:
    case 'SocialClub':
      return this.createInstance(SocialClub, data);
    case EntityType.gym:
    case 'Gym':
      return this.createInstance(Gym, data);
    case EntityType.alimony:
    case 'Alimony':
      return this.createInstance(Alimony, data);
    case EntityType.professionals:
    case 'Professional':
      return this.createInstance(Professional, data);
    case EntityType.others:
    case 'Others':
      return this.createInstance(Others, data);
    default:
      break;
    }
  }

  getCollectionName(entityType: EntityType | string): EntityCollectionName {
    switch (entityType) {
    case EntityType.rent:
    case 'Rent':
      return EntityCollectionName.rent;
    case EntityType.commonExpenses:
    case 'CommonExpenses':
      return EntityCollectionName.commonExpenses;
    case EntityType.warehouse:
    case 'Warehouse':
      return EntityCollectionName.warehouse;
    case EntityType.parking:
    case 'Parking':
      return EntityCollectionName.parking;
    case EntityType.mortgageFoot:
    case 'MortgageFoot':
      return EntityCollectionName.mortgageFoot;
    case EntityType.kindergarten:
    case 'Kindergarten':
      return EntityCollectionName.kindergarten;
    case EntityType.school:
    case 'School':
      return EntityCollectionName.school;
    case EntityType.domesticWorker:
    case 'DomesticWorker':
      return EntityCollectionName.domesticWorker;
    case EntityType.nursingHome:
    case 'NursingHome':
      return EntityCollectionName.nursingHome;
    case EntityType.sportClub:
    case 'SportClub':
      return EntityCollectionName.sportClub;
    case EntityType.socialClub:
    case 'SocialClub':
      return EntityCollectionName.socialClub;
    case EntityType.gym:
    case 'Gym':
      return EntityCollectionName.gym;
    case EntityType.alimony:
    case 'Alimony':
      return EntityCollectionName.alimony;
    case EntityType.professionals:
    case 'Professional':
      return EntityCollectionName.professionals;
    case EntityType.others:
    case 'Others':
      return EntityCollectionName.others;
    default:
      break;
    }
  }
}

export { EntityFactory };

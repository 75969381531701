<app-loading [showLoader]="showLoader"></app-loading>
<div class="payment-modal">
    <div class="d-flex mb-3 mb-md-4" style="justify-content: space-between;">
        <span style="color: #3368f0; font-size: 16px; font-weight: 600;">Eliminar cuenta</span><mat-icon class="c-pointer" (click)="onNoClick()">close</mat-icon>
    </div>
    <div>
        <div class="d-flex mb-2">
            <span class="subtitle">¿Por qué motivo deseas eliminar tu cuenta?</span>
        </div>
        <form [formGroup]="deleteUserForm" (ngSubmit)="deleteUserAccount()">
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
                        <mat-label>¿Por qué motivo?</mat-label>
                        <mat-select class="notranslate"  id="reason" formControlName="reason" name="reason" [errorStateMatcher]="matcher" required>
                            <ng-container *ngFor="let reason of reasons">
                                <mat-option [value]="reason" class="notranslate">{{deleteUserReason[reason]}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="deleteUserForm.controls['reason'].hasError('required')">El motivo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" *ngIf="deleteUserForm.get('reason').value === 'others'">
                    <mat-form-field class="w-100" outline="border" floatLabel="always" appearance="outline">
                        <mat-label>¿Cuál fue el motivo?</mat-label>
                        <textarea matInput id="additionalComment" rows="2" style="resize:none" formControlName="additionalComment" name="additionalComment" maxlength="255" [errorStateMatcher]="matcher"></textarea>
                        <mat-error *ngIf="deleteUserForm.controls['additionalComment'].hasError('required')">El comentario es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
                <div class="d-flex w-100 my-2">
                    <div class="cont-button text-md-right w-100">
                        <button id="delete-account-button" [disableRipple]="true" type="submit" style="color: white; font-size: 18px !important;" class="w-100 payment-btn" [disabled]="deleteUserForm.invalid" mat-raised-button>
                            Eliminar cuenta
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

import { Component } from '@angular/core';
import { LoaderScreenService } from '@services/loader-screen/loader-screen.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-loading-screen-global',
  templateUrl: './loading-screen-global.component.html',
  styleUrls: ['./loading-screen-global.component.scss']
})
export class GlobalLoadingScreenComponent {
  showLoader: boolean;
  textColor: string;
  secundaryColor: string;
  backgroundColor: string;
  loadingText: string;
  loadingSubtitle: string;

  constructor(private loaderService: LoaderScreenService) {
    combineLatest([
      this.loaderService.getShowLoading$,
      this.loaderService.getLoadingText$,
      this.loaderService.getLoadingSubtitle$,
      this.loaderService.getTextColor$,
      this.loaderService.getSecundaryColor$,
      this.loaderService.getBackgroundColor$,
    ]).subscribe(([showLoading, loadingText, loadingSubtitle, textColor, secundaryColor, backgroundColor]) => {
      this.showLoader = showLoading;
      this.loadingText = loadingText;
      this.loadingSubtitle = loadingSubtitle;
      this.textColor = textColor;
      this.secundaryColor = secundaryColor;
      this.backgroundColor = backgroundColor;
      if(showLoading) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('cdk-global-scrollblock');
      } else {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('cdk-global-scrollblock');
      }
    });
  }

}

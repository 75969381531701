import { BasicServiceCollectionName, BasicServiceType } from '../shared/enums/enums.enum';
import { Deserializable } from '../interfaces/deserializable.interface';
import { BasicServices } from './entities/basic-services.model';
import { IBasicServiceFactory } from '../interfaces/basic-services.interface';

class BasicServiceFactory implements IBasicServiceFactory {
  createInstance<T extends Deserializable>(
    BasicServiceClass: new () => T,
    data: any
  ): T {
    return new BasicServiceClass().deserialize(data);
  }

  deserializeBasicService(
    basicServices: BasicServices | string,
    data: any
  ): BasicServices {
    switch (basicServices) {
    case BasicServiceType.basicService:
    case 'BasicService':
      return this.createInstance(BasicServices, data);
    default:
      break;
    }
  }

  getBasicServiceCollectionName(basicServiceType: BasicServices | string): BasicServiceCollectionName {
    switch (basicServiceType) {
    case BasicServiceType.basicService:
    case 'BasicService':
      return BasicServiceCollectionName.basicServices;
    default:
      break;
    }
  }
}

export { BasicServiceFactory };

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPaymentMethod } from 'neat-lib/dist/Interfaces/IData';

@Component({
  selector: 'app-deleted-credit-card-success',
  templateUrl: './deleted-credit-card-success.component.html',
  styleUrls: ['./deleted-credit-card-success.component.scss']
})
export class DeletedCreditCardSuccessComponent implements OnInit {

  paymentMethod: IPaymentMethod;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeletedCreditCardSuccessComponent>) {
  }

  ngOnInit(): void {
    this.paymentMethod = this.data.paymentMethod;
  }

  formatCreditCardNumbers(text: any) {
    const lastNumbers = text.substr(text.length - 4);
    return `•••• •••• •••• ${lastNumbers}`;
  }

  close(): void {
    this.dialogRef.close();
  }
}

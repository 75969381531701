<section class="h-100">
    <span class="success-modal-header" (click)="closeModal()">
        <mat-icon
            class="close-icon"
        >close</mat-icon>
    </span>
    <section class="d-flex align-items-center flex-column px-2">
        <img src="assets/img/icons/retry-error.svg" alt="success icon" width="69px">
        <h3 class="error-title">{{'Ocurrió un problema :('}}</h3>
        <p *ngIf="!paymentError" class="success-main-text">{{translatedErrorMessage}}</p>
        <p *ngIf="paymentError" class="success-main-text"> Lo sentimos, pero no pudimos recuperar la información de tu pago. Por favor, escríbenos al chat y alguien del equipo te va a ayudar.</p>
    </section>
    <section class="button-wrapper d-flex w-100 justify-content-center">
        <button
            mat-raised-button class="success-btn"
            style="height: fit-content;"
            (click)="openIntercom()"
        >
            <span>Ir al chat</span>
        </button>
    </section>
</section>

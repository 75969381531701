import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@services/user/user.service';
import { take } from 'rxjs/internal/operators/take';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-neat-club-terms',
  templateUrl: './neat-club-terms.component.html',
  styleUrls: ['./neat-club-terms.component.scss']
})
export class NeatClubTermsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NeatClubTermsComponent>,
    private userService: UserService,
    private fireAnalytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
  }

  close(rejectTerms?: boolean): void {
    if (rejectTerms) {
      this.fireAnalytics.logEvent('nc_cierra_tyc');
    } else {
      this.fireAnalytics.logEvent('nc_no_acepta_tyc');
    }
    this.dialogRef.close(false);
  }
  
  saveTermsAnswer() {
    this.fireAnalytics.logEvent('nc_acepta_tyc');
    this.userService.currentUserData$.pipe(take(1)).subscribe(currentUser => {
      this.userService.updateNeatClubUserInfoDoc(currentUser.id);
      this.dialogRef.close(true);
    });
  }
}

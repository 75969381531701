<div *ngIf="showLoader" class="preloader-2 d-flex align-items-center justify-content-center" [style.backgroundColor]="backgroundColor">
    <div>
        <div></div>
        <div class="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="32" viewBox="0 0 46 32" fill="textColor">
                <path d="M39.1 0H16.56C11.5 0 6.9 4 6.9 9.71429V11.4286L3.45 8L0 11.4286L9.2 20.5714L18.4 11.4286L14.95 8L11.5 11.4286V9.71429C11.5 6.87396 13.8169 4.57143 16.675 4.57143H34.5V9.14286H39.1V0Z" [style.fill]="textColor"/>
                <path d="M6.9 32L29.44 32C34.8913 32 39.1 27.4286 39.1 22.2857V20.5714L42.55 24L46 20.5714L36.8 11.4286L27.6 20.5714L31.05 24L34.5 20.5714V22.2857C34.5 25.126 32.1831 27.4286 29.325 27.4286L11.5 27.4286V22.8571H6.9V32Z" [style.fill]="textColor"/>
            </svg>
            <span class="primary-text d-block mt-2" [style.color]="textColor">{{loadingText}}</span>
            <span *ngIf="loadingSubtitle" class="secundary-text d-block mt-2" [style.color]="secundaryColor">{{loadingSubtitle}}</span>
            <div class="svg-container mt-5 mobile-center">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 10 24" fill="none">
                  <path id="svg1" opacity="0.3" d="M10 0H0V24H10V0Z" [style.fill]="textColor"/>
                </svg>
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 10 24" fill="none">
                  <path id="svg2" opacity="0.3" d="M10 0H0V24H10V0Z" [style.fill]="textColor"/>
                </svg>
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 10 24" fill="none">
                  <path id="svg3" opacity="0.3" d="M10 0H0V24H10V0Z" [style.fill]="textColor"/>
                </svg>
            </div>
        </div>
    </div>
</div>
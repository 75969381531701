import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookiesService } from '@services/cookies/cookies.service';
import { RegisterCreditCardService } from '@services/credit-card-registration/register-credit-card.service';
import { DialogService } from '@services/shared/dialog/dialog.service';
import { SweetalertService } from '@services/shared/sweetalert/sweetalert.service';
import { UserService } from '@services/user/user.service';
import { CardTypes, EntityTypeLocale, IconErrorModals, paymentMethodsOptions, ProfessionalService } from '@shared/enums/enums.enum';
import { MergeTypes } from '@shared/utils';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';
import { IPaymentMethod } from 'neat-lib/dist/Interfaces/IData';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

enum UtilityCategoriesLocale {
  Agua = 'water',
  Luz = 'electricity',
  Gas = 'naturalGas',
  Telefonía = 'telephony',
  Internet = 'internetAndCable',
  Autopistas = 'highways',
  Otro = 'other'
}

@Component({
  selector: 'app-update-automatic-payment-method',
  templateUrl: './update-automatic-payment-method.component.html',
  styleUrls: ['./update-automatic-payment-method.component.scss']
})
export class UpdateAutomaticPaymentMethodComponent implements OnInit {

  utilityCategoriesLocale = UtilityCategoriesLocale;
  entityTypeLocale = EntityTypeLocale;
  professionalServices = ProfessionalService;
  showLoading: boolean;
  paymentMethods$: Observable<IPaymentMethod[]>;
  entities: MergeTypes<BasicService, Entity>[];
  selectPaymentMethodIds: string[];
  currentPath: string;
  private errorSubscription: Subscription;
  currentUserIdToken: string;
  cardTypes = CardTypes;
  affectedEntities: boolean;
  isMobile: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateAutomaticPaymentMethodComponent>,
    private userService: UserService,
    private registerCreditCard: RegisterCreditCardService,
    private cookiesService: CookiesService,
    private deviceDetector: DeviceDetectorService,
    private swalService: SweetalertService,
    private dialogService: DialogService,) {

  }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.entities = this.data.entities;
    this.currentUserIdToken = this.data.currentUserIdToken;
    this.currentPath = this.data.currentPath;
    this.selectPaymentMethodIds = this.entities.map(entity => entity.paymentOption.paymentMethodId);
    this.paymentMethods$ = this.userService.currentUserData$.pipe(map(user => {
      return user.paymentMethods.filter(paymentMethods => !this.selectPaymentMethodIds.includes(paymentMethods.id));
    }));
  }

  seeAffectedEntities(): void {
    this.affectedEntities = !this.affectedEntities;
  }

  selectPaymentMethod(paymentMethodId: string): void {
    this.dialogRef.close({
      saveChanges: paymentMethodId,
    });
  }

  formatCreditCardNumbers(text: any) {
    const lastNumbers = text.substr(text.length - 4);
    return `•••• •••• •••• ${lastNumbers}`;
  }

  close(): void {
    this.dialogRef.close();
  }


  openKushkiDialog(): void {
    this.dialogRef.close();
    this.dialogService.openKushkiDialog(this.isMobile);
  }


  async creditCardRegistration() {
    this.showLoading = true;
    const res = await this.registerCreditCard.getCreditCardRegistrationToken(this.currentUserIdToken, this.currentPath);
    if(res === false) {
      this.showLoading = false;
    }
    this.errorSubscription = this.registerCreditCard.detectErrorOnRequest$.subscribe(error => {
      if (error) {
        this.showLoading = false;
        this.swalService.swalError2('No se ha podido agregar tu tarjeta', `Esto se debe a problemas con tu conexión a internet. Por favor intenta con otra red Wifi. 
        Si estás conectado al internet de tu compañía de celular, inténtalo de nuevo más tarde.`, IconErrorModals.sadCard, true, false);
        this.errorSubscription.unsubscribe();
      }
    });
  }


  formatBankName(bankName: string, cardType?: string): paymentMethodsOptions | string {
    if (bankName) {
      const formatBankName = bankName.toLocaleLowerCase().replace(/\ /g, '').replace(/\//g, '').replace(/\./g, '').replace(/\-/g, '').replace(/\)/g, '').replace(/\(/g, '');
      const findBankName = paymentMethodsOptions[formatBankName];
      if (cardType === 'Redcompra' && paymentMethodsOptions.scotiabank === findBankName) {
        return findBankName + cardType.toLocaleLowerCase();
      }
      if (cardType === 'Prepago' && [paymentMethodsOptions.bancodecreditoeinversiones, paymentMethodsOptions.bancosantanderchile, paymentMethodsOptions.bancoripley].includes(findBankName)) {
        return findBankName + cardType.toLocaleLowerCase();
      }
      return findBankName;
    }
  }

  formatBankNameClass(bankName: string, cardType?: string): paymentMethodsOptions | string {
    if (bankName) {
      const formatBankName = bankName.toLocaleLowerCase().replace(/\ /g, '').replace(/\//g, '').replace(/\./g, '').replace(/\-/g, '').replace(/\)/g, '').replace(/\(/g, '');
      const findBankName = paymentMethodsOptions[formatBankName];
      if (cardType === 'Prepago' && [paymentMethodsOptions.bancodecreditoeinversiones, paymentMethodsOptions.bancosantanderchile, paymentMethodsOptions.bancoripley].includes(findBankName)) {
        return `${findBankName  }-${  cardType.toLocaleLowerCase()}`;
      }
      return findBankName;
    }
  }
}

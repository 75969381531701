<div>
    <div class="d-flex py-2 justify-content-between align-items-center">
        <span class="title-modal">
            Recordatorio de pago
        </span>
        <span class="close-btn" (click)="closeBtn()">&times;</span>
    </div>
    <span class="subtitle-modal d-block mb-3">¿Cuándo debes pagar tu {{entityType}}?</span>
    <div class="transferDate text-center">
        <form [formGroup]="paymentForm">
            <mat-form-field class="w-100 mt-2 mx-auto" style="max-width: 250px;" floatLabel="always" appearance="outline">
                <mat-label>Día del mes</mat-label>
                <mat-icon style="color: #3368f0;" matSuffix>date_range</mat-icon>
                <input matInput type="text" id="transfer-limit-date-automate-payment-modal" formControlName="transferLimitDate" name="transferLimitDate" autocomplete="off" placeholder="Por ejemplo 10" [errorStateMatcher]="matcher" required>
                <mat-error *ngIf="paymentForm.controls['transferLimitDate'].hasError('required')">Es necesario el día de pago.</mat-error>
                <mat-error *ngIf="paymentForm.controls['transferLimitDate'].hasError('min')">No puedes ingresar un día menor al <strong>1</strong>.</mat-error>
                <mat-error *ngIf="paymentForm.controls['transferLimitDate'].hasError('max')">No puedes ingresar un día mayor al <strong>31</strong>.</mat-error>
                <mat-error *ngIf="paymentForm.controls['transferLimitDate'].hasError('invalidNumber')">Solo se pueden ingresar números</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="btns my-3 w-100 text-center">
        <button id='button-automatizate-payment-from-automate-modal' [disableRipple]="true" style="max-width: 250px;"
        type="submit" (click)="updateEntityForAutomate()" class="w-100 neat-blue-btn" [disabled]="paymentForm.invalid" mat-raised-button>
            Confirmar
        </button>
    </div>
</div>

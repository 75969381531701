<section id="password-reset-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="dialog-header">
                    <h6 mat-dialog-title>Recupera tu contraseña</h6>
                    <button type="button" mat-button class="close" (click)="onNoClick()"><i class="material-icons notranslate">close</i></button>
                </div>
                <form [formGroup]="passwordResetForm" (ngSubmit)="resetPass()" action="" class="d-flex justify-content-center flex-column">
                    <mat-form-field appearance="outline">
                        <mat-label>
                            <mat-icon>email</mat-icon> Tu email
                        </mat-label>
                        <input matInput formControlName="email" [errorStateMatcher]="matcher" required>
                        <mat-error *ngIf="passwordResetForm.controls['email'].hasError('email') && !passwordResetForm.controls['email'].hasError('required')">Ingresa un email valido, <i>ejemplo: tuemail@email.cl</i></mat-error>
                        <mat-error *ngIf="passwordResetForm.controls['email'].hasError('required')">Tu email es <strong>requerido</strong></mat-error>
                    </mat-form-field>
                    <div class="d-flex justify-content-between my-2">
                        <button type="button" mat-button (click)="onNoClick()">Cancelar</button>
                        <button id="button-modal-login-forgetPass" mat-raised-button color="primary" [disabled]="passwordResetForm.invalid" type="submit" class="transition-3d-hover">Recuperar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

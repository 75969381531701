<div>
    <div class="d-flex py-2 justify-content-between align-items-center">
        <span class="title-modal">
            Datos del destinatario
        </span>
        <span class="close-btn" (click)="closeBtn()">&times;</span>
    </div>
    <div class="w-100 py-3">
        <div class="row py-1 border-bottom border-top" *ngIf="entity.adminFullName || entity.ownerFullName">
            <span class="col-6 secundary-text">Nombre</span> 
            <span class="col-6 main-text" *ngIf="entity.adminFullName">{{entity.adminFullName}}</span> 
            <span class="col-6 main-text" *ngIf="entity.ownerFullName">{{entity.ownerFullName}}</span>
        </div>
        <div class="row py-1 border-bottom" *ngIf="entity.adminFullName || entity.ownerFullName">
            <span class="col-6 secundary-text">RUT</span> 
            <span class="col-6 main-text" *ngIf="entity.adminRut">{{entity.adminRut | formatRut}}</span> 
            <span class="col-6 main-text" *ngIf="entity.ownerRut">{{entity.ownerRut | formatRut}}</span>
        </div>
        <div class="row py-1 border-bottom" *ngIf="entity.adminEmail || entity.ownerEmail">
            <span class="col-6 secundary-text">Mail</span> 
            <span class="col-6 main-text" *ngIf="entity.adminEmail">{{entity.adminEmail}}</span> 
            <span class="col-6 main-text" *ngIf="entity.ownerEmail">{{entity.ownerEmail}}</span>
        </div>
        <div class="row py-1 border-bottom" *ngIf="entity.bankAccountNumber">
            <span class="col-6 secundary-text">Cuenta</span> 
            <span class="col-6 main-text">{{entity.bankAccountNumber}}</span>
        </div>
        <div class="row py-1 border-bottom" *ngIf="entity.bankAccountType && !entity?.bankAccountTypeName && !['Mach', 'Tenpo', 'Caja Compensación Los Héroes', 'Caja Los Andes', 'Mercado Pago'].includes(entity?.bankName)">
            <span class="col-6 secundary-text">Tipo de cuenta</span> 
            <span class="col-6 main-text">{{ paymentClass.showAccountType(entity.bankAccountType)}}</span>
        </div>
        <div class="row py-1 border-bottom" *ngIf="entity?.bankAccountTypeName && !['Mach', 'Tenpo', 'Caja Compensación Los Héroes', 'Caja Los Andes', 'Mercado Pago'].includes(entity?.bankName)">
            <span class="col-6 secundary-text">Tipo de cuenta</span>
            <span class="col-6 main-text">{{ entity?.bankAccountTypeName }}</span>
        </div>
        <div class="row py-1 border-bottom" *ngIf="entity.bankName">
            <span class="col-6 secundary-text">Banco</span> 
            <span class="col-6 main-text">{{entity.bankName}}</span>
        </div> 
    </div>
    <div class="text-center w-100">
        <span class="box-btn my-2 d-block" *ngIf="router.url.includes('pagar')" (click)="closeBtn()" 
        [routerLink]="['/dashboard/pagos', entity?.id]">Corregir datos de pago</span>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityTypeLocale, ProfessionalService } from '@shared/enums/enums.enum';
enum UtilityCategoriesLocale {
  Agua = 'water',
  Luz = 'electricity',
  Gas = 'naturalGas',
  Telefonía = 'telephony',
  Internet = 'internetAndCable',
  Autopistas = 'highways',
  Otro = 'other'
}
@Component({
  selector: 'app-confirm-change-cards-for-automate',
  templateUrl: './confirm-change-cards-for-automate.component.html',
  styleUrls: ['./confirm-change-cards-for-automate.component.scss']
})
export class ConfirmChangeCardsForAutomateComponent implements OnInit {
  entities: any;
  utilityCategoriesLocale = UtilityCategoriesLocale;
  entityTypeLocale = EntityTypeLocale;
  professionalServices = ProfessionalService;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmChangeCardsForAutomateComponent>,) { }
  ngOnInit(): void {
    this.entities = this.data.entities;
  }
  confirm(): void {
    this.dialogRef.close({
      saveChanges: true,
    });
  }
  cancel(): void {
    this.dialogRef.close({
      saveChanges: false,
    });
  }
}
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFields, INeatServices } from 'neat-lib';
import { PayValidation } from 'neat-lib/dist/Enums/Constants';
import { areInvoicesToBePaidValid } from 'neat-lib/dist/Functions/Functions';
import { take } from 'rxjs/internal/operators/take';
import { Subscription } from 'rxjs/internal/Subscription';


import { CookiesService } from '@services/cookies/cookies.service';
import { PaymentsService } from '@services/payments/payments.service';
import { RentListService } from '@services/rent-list/rent-list.service';
import { IDebtSummary } from 'app/interfaces/basic-services.interface';

@Component({
  selector: 'app-tag-total-summary-modal',
  templateUrl: './tag-total-summary-modal.component.html',
  styleUrls: ['./tag-total-summary-modal.component.scss']
})
export class TagTotalSummaryModalComponent implements OnInit {
  showLoader: boolean;
  currentUserIdToken: string;
  payValidationForm: FormGroup;
  paymentTypeValidationForm: FormGroup;
  validPin: boolean;
  errorMessage: string;
  payValidation: PayValidation;
  traceSubmit: any;
  rentListSubscription: Subscription;
  debtSummary: IDebtSummary;
  payValidationValue: string;
  firstDebt: boolean;
  firstAndTotalDebt: boolean;
  oneOrMoreInOrderDebt: boolean;
  totalDebt: boolean;
  totalAmountArray: number[] = [];
  invoicesToBePaid: IFields[] = [];
  totalAmount = 0;
  showText = false;
  isSelectionValid = false;
  isSelectionValidPaymentType = true;
  neatServices: INeatServices[] = null;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IDebtSummary,
    public dialogRef: MatDialogRef<TagTotalSummaryModalComponent>,
    public paymentsService: PaymentsService,
    private cookiesService: CookiesService,
    private rentListService: RentListService,
  ) {
  }

  ngOnInit(): void {
    this.debtSummary = this.data;
    this.payValidationValue = PayValidation[this.debtSummary.payValidation];
    this.rentListService.neatServicesActive$.pipe(take(1)).subscribe(res => {
      this.neatServices = res.filter(res => res.neatCategory === 'NT-CAT-00020');
    });
    this.buildFormGroups();
    this.verifyPayValidationType();
    if (this.paymentsService?.invoicesToBePayed[this.debtSummary.entityId] && this.paymentsService?.invoicesToBePayed[this.debtSummary.entityId]?.invoices) {
      this.paymentsService?.invoicesToBePayed[this.debtSummary.entityId].invoices.forEach(res => {
        this.payValidationForm.patchValue({
          [res.order]: true
        });
      });
    }
    this.verifyIfSelectionIsValid();
    this.payValidationFormSubscription();
    this.paymentTypeFormSubscription();
    if (this.paymentsService.invoicesToBePayed[this.debtSummary.entityId]) {
      this.invoicesToBePaid = this.paymentsService.invoicesToBePayed[this.debtSummary.entityId].invoices;
    }
    // overwrite values when read only true, for modal when is opened from automatic section
    if (this.debtSummary.onlyRead === true) {
      this.firstDebt = true;
      this.firstAndTotalDebt = false;
      this.oneOrMoreInOrderDebt = false;
      this.totalDebt = false;
    }
  }

  buildFormGroups(): void {
    const groupObject = this.buildGroupObject();
    this.payValidationForm = this.fb.group(groupObject);
    this.paymentTypeValidationForm = this.fb.group({ paymentType: this.paymentsService?.invoicesToBePayed[this.debtSummary.entityId] && this.paymentsService?.invoicesToBePayed[this.debtSummary.entityId]?.paymentType ? this.paymentsService?.invoicesToBePayed[this.debtSummary.entityId]?.paymentType : 'total' });
  }

  verifyPayValidationType(): void {
    this.firstDebt = [PayValidation.First, PayValidation.OnlyOne].some(value => value === this.debtSummary.payValidation);
    this.firstAndTotalDebt = [PayValidation.FirstAndTotal].some(value => value === this.debtSummary.payValidation);
    this.oneOrMoreInOrderDebt = [PayValidation.AllSorted, PayValidation.SomeAndTotal].some(value => value === this.debtSummary.payValidation);
    this.totalDebt = [PayValidation.Total].some(value => value === this.debtSummary.payValidation);
  }

  verifyIfSelectionIsValid(): void {
    if ((this.firstAndTotalDebt || this.totalDebt || this.oneOrMoreInOrderDebt) && !this.paymentsService.invoicesToBePayed[this.debtSummary.entityId]) {
      this.totalAmount = this.debtSummary.currentDebt.totalAmount;
      this.isSelectionValid = areInvoicesToBePaidValid(this.debtSummary.currentDebt.invoices, this.debtSummary.currentDebt.invoices, this.debtSummary.payValidation);
    } else if ((this.firstAndTotalDebt || this.totalDebt || this.oneOrMoreInOrderDebt) && this.paymentsService.invoicesToBePayed[this.debtSummary.entityId]) {
      this.totalAmount = this.paymentsService.invoicesToBePayed[this.debtSummary.entityId].totalAmount;
      this.isSelectionValid = areInvoicesToBePaidValid(this.paymentsService.invoicesToBePayed[this.debtSummary.entityId].invoices, this.debtSummary.currentDebt.invoices, this.debtSummary.payValidation);
    }
    if (this.totalDebt || this.oneOrMoreInOrderDebt || this.firstAndTotalDebt) {
      this.invoicesToBePaid = this.paymentsService.invoicesToBePayed[this.debtSummary.entityId] && this.paymentsService.invoicesToBePayed[this.debtSummary.entityId].invoices ? this.paymentsService.invoicesToBePayed[this.debtSummary.entityId].invoices : this.debtSummary.currentDebt.invoices;
    }
    if (this.firstDebt) {
      this.totalAmount = this.debtSummary.currentDebt.invoices[0].amount;
      this.isSelectionValid = areInvoicesToBePaidValid([this.debtSummary.currentDebt.invoices[0]], this.debtSummary.currentDebt.invoices, this.debtSummary.payValidation);
    }
  }

  payValidationFormSubscription(): void {
    this.payValidationForm.valueChanges.subscribe(res => {
      this.totalAmountArray = [];
      this.invoicesToBePaid = [];
      this.showText = false;
      let cont = 0;
      Object.entries(res).forEach((value) => {
        if (value[1] === true) {
          cont++;
          const getInvoice = this.debtSummary.currentDebt.invoices.find(invoice => invoice.order === Number(value[0]));
          this.invoicesToBePaid.push(getInvoice);
          const getInvoiceAmount = this.debtSummary.currentDebt.invoices.find(invoice => invoice.order === Number(value[0]))?.amount;
          this.totalAmountArray.push(getInvoiceAmount);
        }
        if (cont >= 2) {
          this.showText = true;
        }
      });
      const totalValue = this.totalAmountArray.reduce(
        (total: number, amount: number) => {
          return total + amount;
        },
        0
      );
      this.totalAmount = totalValue;
      try {
        this.isSelectionValid = areInvoicesToBePaidValid(this.invoicesToBePaid, this.debtSummary.currentDebt.invoices, this.debtSummary.payValidation);
      } catch { }
    });
  }

  paymentTypeFormSubscription(): void {
    this.paymentTypeValidationForm.valueChanges.subscribe((paymentType) => {
      this.invoicesToBePaid = paymentType.paymentType === 'total' ? this.debtSummary.currentDebt.invoices : [this.debtSummary.currentDebt.invoices[0]];
      const totalValue = this.invoicesToBePaid.map(res => res.amount).reduce(
        (total: number, amount: number) => {
          return total + amount;
        },
        0
      );
      this.totalAmount = totalValue;
      try {
        this.isSelectionValidPaymentType = areInvoicesToBePaidValid(this.invoicesToBePaid, this.debtSummary.currentDebt.invoices, this.debtSummary.payValidation);
      } catch { }
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveSelectedInvoices() {
    try {
      if (!this.firstDebt && !this.totalDebt) {
        this.paymentsService.invoicesToBePayed[this.debtSummary.entityId] = {
          invoices: this.invoicesToBePaid.length > 0 ? this.invoicesToBePaid : [this.debtSummary.currentDebt.invoices[0]],
          totalAmount: this.totalAmount,
          paymentType: this.paymentTypeValidationForm.get('paymentType').value,
        };
        this.cookiesService.setCookie('paymentPreference', JSON.stringify(this.paymentsService.invoicesToBePayed), '.neatpagos.com', 1);
      }
      this.dialogRef.close({
        invoiceUpdated: true
      });
    } catch (err) {
      this.dialogRef.close({
        invoiceUpdated: false
      });
    }
  }

  buildGroupObject(): any {
    const obj = {};
    this.debtSummary.currentDebt.invoices.map((value) => {
      obj[value.order] = !this.paymentsService?.invoicesToBePayed[this.debtSummary.entityId]?.invoices ? true : false;
    });
    return obj;
  }

  removeAll(): void {
    Object.entries(this.payValidationForm.value).forEach(res => {
      this.payValidationForm.patchValue({
        [res[0]]: false
      });
    });
  }

  selectAll(): void {
    Object.entries(this.payValidationForm.value).forEach(res => {
      this.payValidationForm.patchValue({
        [res[0]]: true
      });
    });
  }

  selectPaymentType(type: string): void {
    this.paymentTypeValidationForm.patchValue({
      paymentType: type
    });
  }

  getHighwayLogo(neatCode: string | number): string {
    const neatService = this.neatServices.find(service => service.providers.sencillito === neatCode.toString() || service.providers.tapi === neatCode.toString());
    return neatService?.logoUrl;
  }

}

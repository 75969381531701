import { Injectable, isDevMode } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import firebase from 'firebase/compat';

import { Observable, of, Subject, BehaviorSubject, lastValueFrom } from 'rxjs';
import { catchError, map, tap, filter } from 'rxjs/operators';
import { DatabaseService } from '../shared/database/database.service';
import { AppGlobals } from '../../shared/constants';
import { IFee } from '../../interfaces/payments.interface';
import { ErrorHandlerService } from '../../services/shared/error-handler/error-handler.service';
import { Entity } from 'app/models/abstract-entity.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IFields } from 'neat-lib/dist/Interfaces/IData';

import packageJson from './../../../../package.json';

const appVersion = packageJson.version;
let neatProduct = `Platform: ${'Web'} Version: ${appVersion} Device: ${window.navigator.userAgent}`;

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  UF: number;
  private sharedUF: Subject<number> = new BehaviorSubject<number>(null);
  private sharedTodayUF: Subject<number> = new BehaviorSubject<number>(null);
  private sharedFee: Subject<number> = new BehaviorSubject<number>(null);
  private feesMapper: Subject<IFee[]> = new BehaviorSubject<IFee[]>(null);
  private multipayEntities: Subject<Array<Entity>> = new BehaviorSubject<Array<Entity>>([]);
  private selectedEntities: Array<Entity> = [];
  currentMultipayEntities = this.multipayEntities.asObservable();
  public automaticRetryLoader = false;
  public todayUF;
  public invoicesToBePayed: {
    invoices?: IFields[],
    totalAmount?: number,
    paymentType?: string,
  } = {};

  constructor(private http: HttpClient,
              private db: DatabaseService,
              private errorService: ErrorHandlerService,
              private deviceService: DeviceDetectorService,
  ) {
    const isMobile = this.deviceService.isMobile();
    const isDesktop = this.deviceService.isDesktop();
    const isTablet = this.deviceService.isTablet();
    let device: string;
    if (isMobile) {
      device = 'Mobile';
    } else if (isDesktop) {
      device = 'Desktop';
    } else if (isTablet) {
      device = 'Tablet';
    }
    if (device) {
      neatProduct = `Platform: ${'Web'}-${device} Version: ${appVersion} Device: ${window.navigator.userAgent}`;
    }
  }

  get UF$(): Observable<number> {
    return this.sharedUF.asObservable().pipe(filter((uf) => !!uf));
  }

  get todayUF$(): Observable<number> {
    return this.sharedTodayUF.asObservable().pipe(filter((uf) => !!uf));
  }

  get fee$(): Observable<number> {
    return this.sharedFee.asObservable().pipe(filter((fee) => !!fee || fee === 0));
  }

  get feesMapper$(): Observable<IFee[]> {
    return this.feesMapper.asObservable().pipe(filter((fees) => !!fees));
  }

  addUF(data: number): void {
    this.sharedUF.next(data);
  }

  addTodayUF(data: number): void {
    this.sharedTodayUF.next(data);
  }

  addFee(data: number): void {
    this.sharedFee.next(data);
  }

  addFeesMapper(data: IFee[]): void {
    this.feesMapper.next(data);
  }

  setEntity(entity: Entity) {
    this.selectedEntities.push(entity);
    this.multipayEntities.next(this.selectedEntities);
  }

  removeEntity(entity: Entity) {
    const removeEntity = this.selectedEntities.indexOf(entity);
    if (removeEntity !== -1) {
      this.selectedEntities.splice(removeEntity, 1);
    }
    this.multipayEntities.next(this.selectedEntities);
  }

  resetMultipay() {
    this.selectedEntities = [];
    this.multipayEntities.next(this.selectedEntities);
  }

  updateEntityData($event: Entity) {
    this.selectedEntities.map( entity => {
      // tslint:disable-next-line: no-unused-expression
      entity.id === $event.id ? {...entity, value: $event.value} : entity;
    });
    this.multipayEntities.next(this.selectedEntities);
  }

  getUFFromFirebase(): Promise<firebase.firestore.DocumentData> {
    return new Promise<firebase.firestore.DocumentData>((resolve, reject) => {
      this.db
        .firestoreGetData('generalData', 'latestUF')
        .then((doc) => resolve(doc.data()))
        .catch((err) => {
          this.errorService.recordError(err, 'payment.service.ts',
            'this.db.firestoreGetData()', 'Error al obtener UF');
          reject(err);
        });
    });
  }

  getUFFromSBIF(): Observable<any> {
    return this.http.get<any>(AppGlobals.sbifAPIEndpoint).pipe(
      map((responseJson) => {
        // tslint:disable-next-line:no-string-literal
        const ufString = responseJson['UFs'][0]['Valor'];
        const ufNumber = ufString.replace('.', '').replace(',', '.');
        return Number(ufNumber);
      })
    );
  }

  coldStartPayment(userIdToken: string): void {
    const httpHeaders = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${userIdToken}`
      })
    };
    if (isDevMode()) {
      this.http.get<any>(AppGlobals.betaColdStartPaymentEndpoint, httpHeaders).subscribe({
        next: () => {},
        error: (error) => {
          this.errorService.recordError(error, 'payment.service.ts',
            'coldStartPayment()', `Error al ejecutar coldStartPayment`);
        }
      });
    } else {
      this.http.get<any>(AppGlobals.coldStartPaymentEndpoint, httpHeaders).subscribe({
        next: () => {},
        error: (error) => {
          this.errorService.recordError(error, 'payment.service.ts',
            'coldStartPayment()', `Error al ejecutar coldStartPayment`);
        }
      });
    }
  }

  getXML1FromPostToExpress(jsonBody, tokenId: string, multipay?: boolean): Observable<any> {
    let endpoint: string;

    endpoint = AppGlobals.neatAPIEndpoint;
    if (multipay) {
      endpoint = AppGlobals.neatMultipayAPIEndpoint;
    }
    if (isDevMode()) {
      endpoint = AppGlobals.neatBetaAPIEndpoint;
      if (multipay) {
        endpoint = AppGlobals.neatBetaMultipayAPIEndpoint;
      }
    }
    const httpHeader = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'x-neat-token': `${tokenId}`,
        'Authorization': `Bearer ${tokenId}`
      }),
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, jsonBody, httpHeader).pipe(
      tap(() => {}),
      catchError(this.handleError<any>('initServipagProcess'))
    );
  }

  commissionRobotMultipayRequest(jsonBody: any, tokenId: string): Observable<any> {
    const endpoint = AppGlobals.commissionRobotMultipayEndpoint;
    const httpHeader = {
      headers: new HttpHeaders({
        'x-neat-token': `${tokenId}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, jsonBody, httpHeader).pipe(
      tap(() => {
      }),
      catchError(this.handleError <any>('commissionRobotRequest'))
    );
  }

  commissionRobotRequest(jsonBody: any, tokenId: string): Observable<any> {
    let endpoint: string;
    endpoint = AppGlobals.commissionRobotEndpoint;
    if (isDevMode()) {
      endpoint = AppGlobals.commissionRobotEndpointBeta;
    }
    const httpHeader = {
      headers: new HttpHeaders({
        'x-neat-token': `${tokenId}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, jsonBody, httpHeader).pipe(
      tap(() => {
      }),
      catchError(this.handleError <any>('commissionRobotRequest'))
    );
  }


  getGroupedBasicServicesDebt(jsonBody: any, tokenId: string, userId: string, isTapiOrGrouped: boolean): Promise<any> {
    const isGroupDebt = jsonBody[0].neatCode === 'NT-CL-G-00400';
    const endpoint: string = isTapiOrGrouped ? (isGroupDebt ? AppGlobals.getBasicServicesGroupedDebtsV2 : AppGlobals.getBasicServicesTAPIDebt) : AppGlobals.getBasicServicesDebt;
    isGroupDebt && (jsonBody[0].saveDebt = true);
    const httpHeader = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${tokenId}`,
        'X-Neat-uid': `${userId}`
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return lastValueFrom(this.http.post<any>(endpoint, jsonBody, httpHeader).pipe(
      catchError(this.handleError('getBasicServicesDebt()'))
    ));
  }

  transbankPaymentRequest(jsonBody: any, idempotencyKey: string, tokenId: string, isMultipay?: boolean): Observable<any> {
    let endpoint: string;
    endpoint = AppGlobals.transbankNeatAPIEndpoint;
    if (isMultipay) {
      endpoint = AppGlobals.multipayTransbankNeatAPIEndpoint;
    }
    const httpHeader = {
      headers: new HttpHeaders({
        'x-neat-token': `${tokenId}`,
        'x-neat-product': neatProduct,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
        'Idempotency-Key': `${idempotencyKey}`,
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, jsonBody, httpHeader).pipe(
      tap(() => {
      }),
      catchError(this.handleError <any>('transbankPaymentRequest'))
    );
  }

  transbankPaymentRequestWithPin(jsonBody: any, idempotencyKey: string, tokenId: string, pin: string, isMultipay?: boolean): Observable<any> {
    let endpoint: string;
    endpoint = AppGlobals.transbankNeatAPIEndpoint;
    if (isMultipay) {
      endpoint = AppGlobals.multipayTransbankNeatAPIEndpoint;
    }
    const httpHeader = {
      headers: new HttpHeaders({
        'x-neat-token': `${tokenId}`,
        'x-neat-product': neatProduct,
        'x-pin': `${pin}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
        'Idempotency-Key': `${idempotencyKey}`,
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, jsonBody, httpHeader).pipe(
      tap(() => {
      }),
      catchError(this.handleError <any>('transbankPaymentRequest'))
    );
  }

  paymentVoucherRequest(jsonBody: any, tokenId: string): Observable<any> {
    let endpoint: string;
    endpoint = AppGlobals.paymentVoucherAPIEndpoint;
    if (isDevMode()) {
      endpoint = AppGlobals.paymentVoucherBetaAPIEndpoint;
    }
    const httpHeader = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, jsonBody, httpHeader).pipe(
      tap(() => {
      }),
      catchError(this.handleError <any>('voucherRequest'))
    );
  }

  transbankPaymentAnulationRequest(jsonBody: any, tokenId: string): Observable<any> {
    const endpoint = AppGlobals.neatAPIEndpointTransbankPaymentAnulation(jsonBody.paymentId, jsonBody.entityType);
    const httpHeader = {
      headers: new HttpHeaders({
        'x-neat-token': `${tokenId}`,
        'x-neat-product': neatProduct,
        'X-Neat-Origin': 'frontUser',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.delete<any>(endpoint, httpHeader).pipe(
      tap(() => {}),
      catchError(this.handleError <any>('transbankPaymentAnulationRequest'))
    );
  }


  automaticRetryByUser(tokenId: string, data: any): Observable<any> {
    let endpoint: string;
    endpoint = AppGlobals.automaticRetryByUser;
    if (isDevMode()) {
      endpoint = AppGlobals.automaticRetryByUserBeta;
    }
    const httpHeader = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`,
        //'testerror': '5'
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, data, httpHeader).pipe(
      tap(() => {
      }),
      catchError(this.handleError<any>('automaticRetryByUser'))
    );
  }

  deletePayment(docID: string): Promise<void> {
    return this.db.firestoreDeleteDoc('rentsPayments', docID);
  }

  private handleError<T>(operation = 'operation') {
    return (error: any): Observable<T> => {
      this.errorService.recordError(error, 'payment.service.ts',
        'handleError()', `Error al procesar comunicación https on operation: ${operation}`);
      return of(error);
    };
  }

  kushkiEnrollerRequest(jsonBody: { token: string, uid: string}, tokenId: string): Observable<any> {
    if (isDevMode()) {
      console.info(jsonBody);
      console.info(tokenId);
    }
    const endpoint = AppGlobals.kushkiEnrollerAPIEndpoint;
    const httpHeader = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, jsonBody, httpHeader).pipe(
      tap(() => {
      }),
      catchError(this.handleError <any>('transbankPaymentRequest'))
    );
  }

  kushkiPaymentRequest(jsonBody: any, tokenId: string, uid: string, pin?: string): Observable<any> {
    const endpoint = AppGlobals.kushkiPaymentAPIEndpoint;
    const httpHeader = {
      headers: new HttpHeaders({
        'x-neat-token': `${tokenId}`,
        'x-neat-product': neatProduct,
        'x-neat-uid': `${uid}`,
        'Access-Control-Allow-Origin': '*',
        ...(pin && {'x-pin': `${pin}`}),
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, jsonBody, httpHeader).pipe(
      tap(() => {
      }),
      catchError(this.handleError <any>('transbankPaymentRequest'))
    );
  }

  kushkiPaymentAnulationRequest(jsonBody: any, tokenId: string): Observable<any> {
    const endpoint = AppGlobals.anulatePaymentKushkiAPIEndpoint(jsonBody.paymentId, jsonBody.entityType);
    const httpHeader = {
      headers: new HttpHeaders({
        'x-neat-token': `${tokenId}`,
        'x-neat-product': neatProduct,
        'X-Neat-Origin': 'frontUser',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
      }),
      observe: 'response' as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.delete<any>(endpoint, httpHeader).pipe(
      tap(() => {}),
      catchError(this.handleError <any>('transbankPaymentAnulationRequest'))
    );
  }

}

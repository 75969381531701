import { EmailHandlerComponent } from './email-handler/email-handler.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NoPageFoundComponent } from './shared/no-page-found/no-page-found.component';
import { AppGuard } from './guards/app.guard';

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [AppGuard],
    loadChildren: () =>
      import('./landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'dashboard',
    canActivate: [AppGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'handlers',
    component: EmailHandlerComponent
  },
  { path: '**', component: NoPageFoundComponent }
];
@NgModule({
  imports: [
    RouterModule,
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule],
  providers: [AppGuard]
})
export class AppRoutingModule {}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface UserInfo {
  rut?: string;
  name?: string;
  email?: string;
  bankName?: string;
  bankAccountType?: string;
  bankAccountNumber?: string;
}

@Component({
  selector: 'app-magic-clipboard-modal',
  templateUrl: './magic-clipboard-modal.html',
  styleUrls: ['./magic-clipboard-modal.scss'],
})

export class MagicClipboardModalComponent {
  userInfo: UserInfo = {};
  formGroup = null;
  bankAccountType = {
    1: 'Cuenta Corriente',
    2: 'Cuenta Vista',
    3: 'Cuenta RUT'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {userInfo: UserInfo, formGroup: any},
    private dialogRef: MatDialogRef<MagicClipboardModalComponent>,
  ) {
    this.userInfo = data.userInfo;
    this.formGroup = data.formGroup;
  }

  closeModal(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }
}
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterUtilsService {
  private previousRoute: Subject<any> = new BehaviorSubject<any>(null);

  private history = [];

  specialUrl: string = '';

  constructor(private router: Router) {}

  get previousRoute$(): Observable<any> {
    return this.previousRoute.asObservable().pipe(filter((event) => !!event));
  }
  addRoute(data: any): void {
    this.previousRoute.next(data);
  }

  addSpecialUrl(route: string): void {
    this.specialUrl = route;
  }

  loadRouting(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  getHistory(): string[] {
    return this.history;
  }

  getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }
}

import { Component } from '@angular/core';
import { MyErrorStateMatcher } from './../../../shared/my-error-state-matcher/my-error-state-matcher.directive';
import { DialogService } from './../../../services/shared/dialog/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user/user.service';
import { SweetalertService } from '../../../services/shared/sweetalert/sweetalert.service';
import { ErrorHandlerService } from '../../../services/shared/error-handler/error-handler.service';
import { Router } from '@angular/router';
import { IconErrorModals } from '@shared/enums/enums.enum';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {
  passwordResetForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });
  matcher = new MyErrorStateMatcher();
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<PasswordResetComponent>,
    private swalService: SweetalertService,
    private errorService: ErrorHandlerService,
    private router: Router,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  openLoginDialog() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }
  resetPass(): void {
    this.userService
      .passwordReset(this.passwordResetForm.value.email)
      .then(() => {
        this.swalResetPassSuccess();
      })
      .catch((err) => {
        this.errorService.recordError(err, 'password-reset.component.ts',
          'this.userService.passwordReset()', 'Error al restablecer la contraseña');
        this.swalPasswordResetError();
      });
  }
  swalResetPassSuccess(): void {
    this.swalService.swalSuccess(
      `Te hemos enviado un mail para que puedas restablecer tu contraseña.`,
      'Por favor revisa tu correo y sigue las instrucciones.'
    );
  }
  swalPasswordResetError(): void {
    this.swalService.swalError2('No se ha podido reestablecer tu contraseña', `Por favor inténtalo de nuevo en unos momentos. Si el problema persiste escríbenos al chat. `, IconErrorModals.sadCloud, false, false);
  }
}

import { CurrentPaymentStatus, EntityType, EntityTypeCreation, UtilityCategories } from 'neat-lib/dist/Enums/Constants';
import { IBasicServiceDebt, IFields, INeatInformation, IPaymentOption } from 'neat-lib/dist/Interfaces/IData';

import { IBasicService } from 'app/interfaces/basic-services.interface';
import { IPaymentUser } from 'app/interfaces/payments.interface';

import {
  PayingAccountUnit,
} from '../shared/enums/enums.enum';

export interface Service {
  name: string;
  value: number;
}

export interface BasicServiceError {
  code: string;
  messagge: number;
}

export abstract class BasicService implements IBasicService {
  public clientNumber: string;
  public entityType: EntityType = EntityType.basicService;
  public id: string;
  public entityNumber: number;
  public category: string;
  public alias: string;
  public isDisable: boolean = false;
  public lesseeEmail: string;
  public payingAccountUnit: PayingAccountUnit = PayingAccountUnit.CLP;
  public sendReminderNotification: boolean;
  public verified: boolean;
  public version?: number = 2;
  public createDate: number;
  public currentDebtError: string;
  public currentPaymentStatus: CurrentPaymentStatus;
  public lastValueChangeDate: Date;
  public lastPaymentDate: Date | undefined;
  public lastValuePaid: number;
  public serviceName: string;
  public serviceCode: string;
  public payer: IPaymentUser;
  public depositee: IPaymentUser;
  public hidden?: boolean;
  public dueDate?: Date | undefined;
  public utilityNumber: string;
  public utilityName: string;
  public utilityType: UtilityCategories;
  public utilityAlias: string;
  public value: number;
  public valueUF: number;
  public currentDebt?: IBasicServiceDebt;
  public paymentOption: IPaymentOption;
  public creationType?: EntityTypeCreation;
  public transferLimitDate: number;
  public payValidation: number;
  public providerResponse?: any;
  public invoicesToBePayed?: IFields[];
  public logoUrl?: string;
  public neatInformation?: INeatInformation;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}

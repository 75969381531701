import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DeviceType } from '@shared/enums/enums.enum';
import { IDeviceData } from '../../../interfaces/device-data.interface';

@Injectable({
  providedIn: 'root'
})
export class DevicesDetectorService {
  isDesktopVar = false;
  isTabletVar = false;
  isMobileVar = false;

  constructor(private deviceService: DeviceDetectorService) { }

  isDesktop() {
    this.isDesktopVar = this.deviceService.isDesktop();
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get deviceType(): DeviceType {
    if (this.deviceService.isDesktop()) {
      return DeviceType.desktop;
    } else if (this.deviceService.isMobile()) {
      return DeviceType.mobile;
    } else if (this.deviceService.isTablet()) {
      return DeviceType.tablet;
    } else {
      return DeviceType.unknown;
    }
  }

  get deviceData(): IDeviceData {
    return <IDeviceData>{
      browser: this.deviceService.getDeviceInfo().browser,
      device: this.deviceService.getDeviceInfo().device,
      os: this.deviceService.getDeviceInfo().os,
      deviceType: this.deviceType,
      userAgent: this.deviceService.getDeviceInfo().userAgent,
    };
  }

}

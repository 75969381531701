import { Injectable } from '@angular/core';
import { User } from 'app/models/users/user.model';

declare let _cio: any;

interface IUserData {
  id: string; // Identificador único del usuario (puede ser el correo electrónico u otro identificador)
  created_at: number | string; // Fecha de creación del usuario (formato: 'YYYY-MM-DDTHH:MM:SSZ')
  email: string; // Correo electrónico del usuario
  first_name: string; // Nombre del usuario
  last_name: string; // Apellido del usuario
  payment_methods: string[]; // Lista de métodos de pago del usuario (nombres de bancos o tipos de tarjeta)
  n_payment_methods: number; // Número de métodos de pago del usuario
  birth_date: string; // Fecha de nacimiento del usuario (formato: 'DD/MM/YYYY')
  comes_from: string; // Origen del usuario (por ejemplo, referrer)
  category: string; // Categoría del usuario (por ejemplo, 'starter', etc.)
  months_paying: number; // Meses que lleva el usuario pagando
  user_app: any | null; // Información adicional sobre la aplicación del usuario (puede ser cualquier tipo o nulo)
}


@Injectable({
  providedIn: 'root'
})
export class CustomerioService {

  constructor() { }

  identifyUser(user: User): void {
    try {
      const userData: IUserData = this.generateUserDataObject(user);
      _cio.identify(userData);
    } catch (error) {}
  }

  trackEvent(eventName: string, eventData?: any): void {
    try {
      _cio.track(eventName, eventData);
    } catch (error) {}
  }

  private generateUserDataObject(user: User): IUserData {
    return {
      id: user.email,
      created_at: user.createDate || '',
      email: user.email || '',
      first_name: user?.firstNames || '',
      last_name: user?.lastNames || '',
      payment_methods: user?.paymentMethods.map(paymentMethod => paymentMethod.bankName || paymentMethod.cardType) || [],
      n_payment_methods: user?.paymentMethods?.length || 0,
      birth_date: user?.birthDate?.day && user?.birthDate?.month && user?.birthDate?.year ? `${user?.birthDate?.day}/${user?.birthDate?.month}/${user?.birthDate?.year}` : '',
      comes_from: user?.comesFrom || '',
      category: user.neatUniqueData?.currentState?.category || 'starter',
      months_paying: user?.continuousMonthsPaid || 0,
      user_app: user?.userApp || null,
    };
  }
}

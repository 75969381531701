<form [formGroup]="retryForm">
    <mat-form-field  floatLabel="always" appearance="outline" class="w-100 mt-3">
        <mat-label>Nombre</mat-label>
            <input matInput id="name" formControlName="name" name="name" type="text">
            <mat-error *ngIf="retryForm.controls['name'].hasError('required')">El nombre es <strong>requerido</strong>.</mat-error>
    </mat-form-field>
    <mat-form-field  floatLabel="always" appearance="outline" class="w-100">
        <mat-label>RUT</mat-label>
            <input matInput onkeypress="onlyNumbersAndK(event)" [value]="retryForm.get('rut').value | formatRut"  maxlength="12" formControlName="rut" id="rut" name="rut" type="text" maxlength="12">
            <mat-error *ngIf="retryForm.controls['rut'].hasError('required')">El RUT es <strong>requerido</strong>.</mat-error>
    </mat-form-field>
    <mat-form-field  floatLabel="always" appearance="outline" class="w-100">
        <mat-label>Banco</mat-label>
        <mat-select id="bank" formControlName="bank" name="bank"  placeholder="Seleccione una opción" >
            <mat-option *ngFor="let bank of banks" [value]="bank">{{bank}}</mat-option>
        </mat-select>
        <mat-error  *ngIf="retryForm.controls['bank'].hasError('required')">El banco es <strong>requerido</strong>.</mat-error>
    </mat-form-field>
    <mat-form-field  floatLabel="always" appearance="outline" class="w-100">
        <mat-label>Tipo de cuenta</mat-label>
        <mat-select id="accountType" formControlName="accountType" name="accountType"  placeholder="Seleccione una opción" >
            <mat-option *ngFor="let accountType of accountTypes" [value]="accountType">{{accountType}}</mat-option>
        </mat-select>
        <mat-error  *ngIf="retryForm.controls['accountType'].hasError('required')">El tipo de cuenta es <strong>requerido</strong>.</mat-error>
    </mat-form-field>
    <mat-form-field  floatLabel="always" appearance="outline" class="w-100">
        <mat-label>Número de cuenta</mat-label>
            <input matInput id="accountNumber" formControlName="accountNumber" name="accountNumber">
            <mat-error *ngIf="retryForm.controls['accountNumber'].hasError('required')">El número de cuenta es <strong>requerido</strong>.</mat-error>
    </mat-form-field>
    <mat-form-field  floatLabel="always" appearance="outline" class="w-100 mb-2">
        <mat-label>email</mat-label>
            <input matInput id="email" formControlName="email" name="email" type="email">
            <mat-error *ngIf="retryForm.controls['email'].hasError('required')">El email es <strong>requerido</strong>.</mat-error>
    </mat-form-field>
    <section *ngIf="formSameData" class="error-disclaimer">
        <p class="error-disclaimer-text mb-0">
            No puedes usar los mismos datos, por favor corrígelos.
        </p>
    </section>
    <section class="d-flex w-100 justify-content-center confirm-button">
        <button
            mat-raised-button class="neat-main-btn"
            style="height: fit-content;"
            (click)="onSubmit()"
        >
            <mat-spinner *ngIf="loading" style="display: inline-block; margin-left: 4px;" [diameter]="16" color="accent"></mat-spinner>
            <span *ngIf="!loading">Confirmar nuevos datos</span>
        </button>
    </section>
</form>
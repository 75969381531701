<div class="w-100 text-right">
    <span id='icon-close-automate-payment-modal' class="ml-auto" (click)="onNoClick()"><mat-icon class="c-pointer">close</mat-icon></span>
</div>
<div class="text-center p-4">
    <h5 class="title-modal">Debes seleccionar un día del mes para agendar tu pago automático</h5>
    <span class="subtitle-modal d-block mb-3">Selecciona la fecha en que deberías pagar tu cuenta.<br/>
                                    (El día en que el pago debe estar en la cuenta del destinatario)</span>
    <div class="transferDate">
        <form [formGroup]="paymentForm">
            <mat-form-field class="w-100 mt-2" style="max-width: 250px;" floatLabel="always" appearance="outline">
                <mat-label>Día del mes</mat-label>
                <mat-icon style="color: #3368f0;" matSuffix>date_range</mat-icon>
                <input matInput type="text" id="transfer-limit-date-automate-payment-modal" formControlName="transferLimitDate" name="transferLimitDate" autocomplete="off" placeholder="Por ejemplo 10" [errorStateMatcher]="matcher" required>
                <mat-error *ngIf="paymentForm.controls['transferLimitDate'].hasError('required')">No puedes automatizar sin definir el día de pago.</mat-error>
                <mat-error *ngIf="paymentForm.controls['transferLimitDate'].hasError('min')">No puedes ingresar un día menor al <strong>1</strong>.</mat-error>
                <mat-error *ngIf="paymentForm.controls['transferLimitDate'].hasError('max')">No puedes ingresar un día mayor al <strong>31</strong>.</mat-error>
                <mat-error *ngIf="paymentForm.controls['transferLimitDate'].hasError('invalidNumber')">Solo se pueden ingresar números</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="btns my-3">
        <button id='button-automatizate-payment-from-automate-modal' [disableRipple]="true" type="submit" (click)="updateEntityForAutomate()" class="w-100 payment-btn" [disabled]="paymentForm.invalid" mat-raised-button>
            Automatizar pago
        </button>
        <button id='btn-omit-automate-payment-modal' [disableRipple]="true" type="submit" (click)="onNoClick()" class="w-100 mt-3 omit-btn" mat-raised-button>
            Omitir
        </button>
    </div>
    <div *ngIf="selectedDay">
        <div class="m-auto" style="max-width: 550px;">
            <div class="mt-2 mt-md-4 payment-summary-text extra-info-text text-center">
                <span><span class="neat-text-medium-bold payment-summary-text notranslate">{{automaticPaymentText}}</span>, ese mismo día enviaremos un comprobante de pago tanto a ti como al destinatario.</span>
            </div>
        </div>
    </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CloudService } from '@services/cloud/cloud.service';
import { UserService } from '@services/user/user.service';
import { User } from 'app/models/users/user.model';
import { Subscription, take } from 'rxjs';
declare let Intercom: any;

@Component({
  selector: 'app-reward-exchange-modal',
  templateUrl: './reward-exchange-modal.component.html',
  styleUrls: ['./reward-exchange-modal.component.scss']
})
export class RewardExchangeModalComponent implements OnInit {
  rewardSelected: any;
  currentUser: User;
  currentUserIdToken: string;
  private allSubscriptions: Subscription = new Subscription();
  success = null;
  error = null;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<RewardExchangeModalComponent>,
    private cloudService: CloudService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.rewardSelected = this.data.rewardSelected;
  }

  ngOnInit(): void {
    this.userService.currentUserData$.pipe(take(1)).subscribe(currentUser => {
      this.currentUser = currentUser;
    });
    this.allSubscriptions.add(this.userService.idToken$.subscribe({
      next: (idToken) => this.currentUserIdToken = idToken,
    }));
  }

  close(): void {
    this.dialogRef.close(false);
  }

  goToNeatClub(): void {
    this.dialogRef.close(true);
  }

  async redeemReward(): Promise<any> {
    try {
      this.loading = true;
      const redeemResponse = await this.cloudService.neatClubRedeemReward(this.rewardSelected.id, this.currentUserIdToken, this.currentUser.id);
      if (redeemResponse?.message === 'Reward on redeem process') {
        this.success = true;
        this.error = null;
      } else {
        this.success = null;
        this.error = true;
      }
      this.loading = false;
    } catch {
      this.success = null;
      this.error = true;
    }
  }

  ngOnDestroy() {
    this.allSubscriptions?.unsubscribe();
  }

  openIntercom() {
    Intercom('showNewMessage', '');
  }

}

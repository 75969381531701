import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-onboarding-response',
  templateUrl: './onboarding-response.component.html',
  styleUrls: ['./onboarding-response.component.scss']
})
export class OnboardingResponseComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OnboardingResponseComponent>,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

<div>
    <div class="d-flex justify-content-end">
        <span style="cursor: pointer;" (click)="closeBtn()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g opacity="0.5">
                <mask id="mask0_6007_975" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_6007_975)">
                <path d="M6.4 18.9995L5 17.5995L10.6 11.9995L5 6.39951L6.4 4.99951L12 10.5995L17.6 4.99951L19 6.39951L13.4 11.9995L19 17.5995L17.6 18.9995L12 13.3995L6.4 18.9995Z" fill="#1C1B1F"/>
                </g>
                </g>
            </svg>
        </span>
    </div>
    <div class="d-flex flex-column align-items-center">
        <div>
            <span class="emoji">
                {{modalData.emoji}}
            </span>
        </div>
        <div class="my-3">
            <span class="title">
                {{modalData.title}}
            </span>
        </div>
        <div class="text-center mb-3">
            <span class="description">
                {{modalData.description}}
            </span>
        </div>
        <div *ngIf="modalData?.mainBtnText" class="mb-3 w-100">
            <button (click)="closeBtn()" class="neat-btn neat-btn__primary w-100">
                <span>
                    {{modalData.mainBtnText}}
                </span>
            </button>
        </div>
        <div *ngIf="modalData?.securityBtnText" class="w-100">
            <button (click)="openIntercom()" class="neat-btn neat-btn__secondary w-100">
                <span>
                    {{modalData.securityBtnText}}
                </span>
            </button>
        </div>
    </div>
</div>
import { EmailHandlerModule } from './email-handler/email-handler.module';
import { environment } from './../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable, isDevMode } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
// Device detector
import { AngularMaterialModule } from './shared/angular-material/angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire/compat/';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS } from '@angular/fire/compat/remote-config';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { LoginGuard } from './guards/login/login.guard';
import { Integrations } from '@sentry/tracing';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AppGlobals } from '@shared/constants';
import { RouterModule } from '@angular/router';


const requestOptions = {
  method: 'GET',
  headers: {
    'x-api-key': environment.versionApiKey,
    'Content-type': 'application/json'
  }
};

const getVersionUrl = AppGlobals.versionService;

fetch(getVersionUrl, requestOptions)
  .then(response => response.json())
  .then(result => {
    const localVersion = localStorage.getItem('last-version');
    const remoteVersion = result.shaVersion;

    if (!localVersion || localVersion == 'undefined') {
      localStorage.setItem('last-version', 'AAXXZZ');
      window.location.reload();
      return;
    }

    if (localVersion != remoteVersion) {
      localStorage.setItem('last-version', remoteVersion);
      window.location.reload();
      return;
    }
  })
  .catch(() => {});

Sentry.init(Object.assign(
  environment.sentry,
  { integrations: [new Integrations.BrowserTracing()] })
);
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    if (environment.sentry.active) {
      Sentry.showReportDialog({ eventId });
    }
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    AngularFireRemoteConfigModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    EmailHandlerModule,
    RecaptchaV3Module,
  ],
  exports: [],
  providers: [
    {
      provide: DEFAULTS, useValue: {
        payment_provider_status: true,
        payment_problem_text: 'Disculpa, el servicio de pagos se encuentra suspendido temporalmente',
      }
    },
    {
      provide: SETTINGS,
      // 3 hrs of fetch interval and 1 min fetch Timeout
      useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : { minimumFetchIntervalMillis: 900000, fetchTimeoutMillis: 60000 }
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LcqerUUAAAAAP4qyBEPTqYEjpV6kS6sfouZr5Sx'
    },
    LoginGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixed'
})
export class ToFixedPipe implements PipeTransform {
  transform(value: any, arg1: number = 4): number {
    if (value == null) {
      return 0;
    }
    if (value.length === 1) {
      return value;
    }
    value = value.toFixed(arg1);
    return value;
  }
}

<div class="neat-club-modal">
    <div *ngIf="!preregisteredUser; else userPreRegistered">
        <div class="d-flex justify-content-between">
            <div>
                <img class="neat-club-logo" src="assets/img/neat-club/neat-club-logo.svg" alt="neat-club">
            </div>
            <div>
                <span (click)="onClose()" style="cursor: pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="white"/>
                    </svg>
                </span>
            </div>
        </div>
        <div class="text-center">
            <img src="assets/img/neat-club/gif-tarjeta-balance.gif" style="max-width: 200px; margin-top: -35px;" alt="neat-balance">
        </div>
        <div class="text-center">
            <span class="title my-3 d-block">
                El equilibrio financiero es la<br> clave del éxito.
            </span>
            <p class="subtitle">
                NeatClub recompensará tu responsabilidad.<br>
                ¿Estás preparado para recibir beneficios por ser un buen pagador?
            </p>
        </div>
        <div>
            <button class="neat-btn neat-btn__terciary mx-auto" style="color: #151F6D;" (click)="!loading && saveNeatClubRegistry()">
                <span *ngIf="!loading">Sí, me interesa</span>
                <span *ngIf="loading">Registrando <mat-spinner style="display: inline-block; margin-left: 4px;" [diameter]="12"></mat-spinner></span>
            </button>
        </div>
    </div>
    <ng-template #userPreRegistered>
        <div class="d-flex justify-content-between">
            <div>
                <img class="neat-club-logo" src="assets/img/neat-club/neat-club-logo.svg" alt="neat-club">
            </div>
            <div>
                <span (click)="onClose()" style="cursor: pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="white"/>
                    </svg>
                </span>
            </div>
        </div>
        <div class="text-center align-box" style="position: relative;">
            <img class="coin-mid-left" src="assets/img/neat-club/coin-1.svg" alt="coin-1">
            <img class="coin-left" src="assets/img/neat-club/coin-2.svg" alt="coin-2">
            <img class="coin-top" src="assets/img/neat-club/coin-3.svg" alt="coin-3">
            <img class="coin-right" src="assets/img/neat-club/coin-4.svg" alt="coin-4">
            <span class="title my-3 d-block">
                {{title}}
            </span>
            <p class="subtitle">
                {{subtitle}}
            </p>
        </div>
    </ng-template>
</div>
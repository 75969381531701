<div>
    <div class="d-flex py-2 justify-content-between align-items-center">
        <span class="title-modal">
            Información relevante
        </span>
        <span class="close-btn" (click)="closeBtn()">&times;</span>
    </div>
    <div class="default-color">
        <span class="d-block mb-2">
            Antes de que pagues, ten en cuenta que:
        </span>
        <ul>
            <section>
                <li class="mb-2" *ngIf="!isEdiproPayment">
                    Los proveedores se demoran <b>hasta 72 horas hábiles en actualizar la deuda</b> en sus sistemas.
                </li>
                <li *ngIf="!isEdiproPayment">
                    <b>Los montos pagados no se pueden anular.</b> <br/>
                    Revisa con detalle los montos que estás pagando para evitarte dolores de cabeza.
                </li>
            </section>
            <section *ngIf="isEdiproPayment">
                <li class="mb-2" *ngIf="isMultiPay">
                    Los proveedores se demoran <b>hasta 72 horas hábiles en actualizar la deuda</b> en sus sistemas.
                </li>
                <li class="mb-2">
                    <b>Si ya pagaste, no vuelvas a pagar</b> <br/>
                    EdiPro puede demorarse en actualizar la deuda en su sistema. En algunos casos podrás seguir viendo deuda por pagar.
                </li>
                <li class="mb-2">
                    <b>Los montos pagados no se pueden anular</b> <br/>
                    Revisa bien que el monto que estás pagando es el correcto. De no ser así, escríbenos al chat.
                </li>
                <li class="mb-2">
                    <b>Los pagos a EdiPro tienen una comisión diferente: máximo 1,89%</b> <br/>
                    Es una comisión distinta a la estándar de Neat, que es de 1,39%, pero sigue siendo menor a las otras alternativas de pago en EdiPro.
                </li>
            </section>
        </ul>
    </div>
    <div class="row align-items-center mt-3">
        <span class="col-7 default-color">
            Si tienes dudas, escríbenos al chat.
        </span>
        <span class="col-5">
            <span class="box-btn my-2 d-block" (click)="openIntercom()">Tengo dudas</span>
        </span>
    </div>
</div>
import { Component, Inject, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardMethod, CardTypes, IconErrorModals, paymentMethodsOptions } from '@shared/enums/enums.enum';
import { MyErrorStateMatcher } from '@shared/my-error-state-matcher/my-error-state-matcher.directive';
import { PaymentClass } from 'app/dashboard/checkout/payment';
import { Subscription } from 'rxjs/internal/Subscription';
import { CookiesService } from '@services/cookies/cookies.service';
import { RegisterCreditCardService } from '@services/credit-card-registration/register-credit-card.service';
import { UserService } from '@services/user/user.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { SweetalertService } from '@services/shared/sweetalert/sweetalert.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DialogService } from '@services/shared/dialog/dialog.service';
import { User } from 'app/models/users/user.model';
import { PaymentsService } from '@services/payments/payments.service';
import { Kushki } from '@kushki/js';
import { environment } from 'environments/environment';
import { TokenResponse } from '@kushki/js/lib/types/remote/token_response';

@Component({
  selector: 'app-payment-methods-selection-modal',
  templateUrl: './payment-methods-selection-modal.component.html',
  styleUrls: ['./payment-methods-selection-modal.component.scss']
})
export class PaymentMethodsSelectionModalComponent implements OnInit, OnDestroy {
  paymentMethodsAvailable: any[];
  cardTypes = CardTypes;
  cardMethod = CardMethod;
  card: any;
  selectedCardId: string;
  errorMsg: boolean;
  paymentMethodForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  private valueChangesSubscriptions: Subscription = new Subscription();
  private errorSubscription: Subscription;
  showLoader: boolean;
  showNewLoader: boolean;
  successMode: boolean;
  errorMode: boolean;
  hide = true;
  currentUserIdToken: string;
  isMobile: boolean;
  enrollMode = false;
  kushkiForm: FormGroup;
  currentUser: User;
  kushkiMode = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private deviceDetector: DeviceDetectorService,
    public dialogRef: MatDialogRef<PaymentMethodsSelectionModalComponent>,
    public paymentClass: PaymentClass,
    private cookiesService: CookiesService,
    private registerCreditCard: RegisterCreditCardService,
    private userService: UserService,
    private errorService: ErrorHandlerService,
    private swalService: SweetalertService,
    private dialogService: DialogService,
    private paymentService: PaymentsService,
    private fb: FormBuilder,
  ) {
    this.kushkiForm = this.fb.group({
      number: ['', Validators.required],
      name: ['', Validators.required],
      expiry_date: ['', Validators.required],
      cvc: ['', Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    this.kushkiMode = this.userService.kushkiMode;
    this.assignCurrentUserIdToken();
    this.isMobile = this.deviceDetector.isMobile();
    this.userService.idToken$.subscribe({
      next: (idToken) => this.currentUserIdToken = idToken,
      error: (error) => {
        this.errorService.recordError(error, 'payment-methods-selection.component.ts', 'this.userService.idToken$', 'Error al ejecutar subscribe de idToken$');
      }
    });
    this.paymentMethodForm = this.paymentClass.buildPaymentMethodFormOnlyMethod();
    this.paymentMethodsAvailable = this.data.paymentMethods;
    this.card = this.data.selectedCard;
    this.selectedCardId = this.data.selectedCard && this.data.selectedCard.id;
    if (this.card) {
      this.paymentMethodForm.patchValue({
        paymentMethod: this.card.id
      }, {emitEvent: true});
    }
    this.valueChangesSubscriptions.add(this.paymentMethodForm.get('paymentMethod').valueChanges.subscribe((paymentMethodId) => {
      this.selectedCardId = paymentMethodId;
      this.card = this.paymentMethodsAvailable?.find(cards => cards.id === paymentMethodId);
    }));
    this.currentUser = await this.userService.getCurrentUser();
  }

  assignCurrentUserIdToken(): void {
    this.userService.idToken$.subscribe({
      next: (idToken) => this.currentUserIdToken = idToken,
      error: (error) => {
        this.errorService.recordError(error, 'checkout.component.ts',
          'this.userService.idToken$', 'Error al ejecutar subscribe de idToken$');
      }
    });
  }

  requestToken(): void {
    try {
      if (this.kushkiForm.invalid) {
        this.kushkiForm.markAllAsTouched();
        return;
      }
      this.showNewLoader = true;
      const kushki = new Kushki({
        merchantId: environment.kushkiMerchantId,
        ...(isDevMode() && {inTestEnvironment: true})
      });
      kushki.requestSubscriptionToken({
        currency: 'CLP',
        card: {
          name: this.kushkiForm.get('name').value,
          number: this.kushkiForm.get('number').value,
          cvc: this.kushkiForm.get('cvc').value,
          expiryMonth: this.kushkiForm.get('expiry_date').value.slice(0, 2),
          expiryYear: String(this.kushkiForm.get('expiry_date').value).slice(-2),
        },
      }, (response) => {
        if ((response as TokenResponse).token) {
          this.paymentService.kushkiEnrollerRequest({token: (response as TokenResponse).token, uid: this.currentUser.id}, this.currentUserIdToken).subscribe(res => {
            if (res.status === 200) {
              this.showNewLoader = false;
              this.errorMsg = false;
              this.successMode = true;
            } else {
              this.showNewLoader = false;
              this.errorMode = true;
              this.errorMsg = true;
            }
          });
        } else {
          this.showNewLoader = false;
          this.errorMode = true;
          this.errorMsg = true;
          this.successMode = false;
        }
      });
    } catch (err) {
      this.showNewLoader = false;
      this.errorMode = true;
      this.errorMsg = true;
      this.successMode = false;
    }

  }

  ngOnDestroy(): void {
    this.errorSubscription?.unsubscribe();
  }

  formatCreditCardNumbers(text: any) {
    const lastNumbers = text.substr(text.length - 4);
    return `•••• ${lastNumbers}`;
  }

  changeEnrollMode(enrollMode: boolean) {
    this.enrollMode = enrollMode;
  }

  formatBankName(bankName: string, cardType?: string): paymentMethodsOptions | string {
    if (bankName) {
      const formatBankName = bankName.toLocaleLowerCase().replace(/\ /g, '').replace(/\//g, '').replace(/\./g, '').replace(/\-/g, '').replace(/\)/g, '').replace(/\(/g, '');
      const findBankName = paymentMethodsOptions[formatBankName];
      if (cardType === 'Redcompra' && paymentMethodsOptions.scotiabank === findBankName) {
        return findBankName + cardType.toLocaleLowerCase();
      }
      if (cardType === 'Prepago' && [paymentMethodsOptions.bancodecreditoeinversiones, paymentMethodsOptions.bancosantanderchile, paymentMethodsOptions.bancoripley].includes(findBankName)) {
        return findBankName + cardType.toLocaleLowerCase();
      }
      return findBankName;
    }
  }

  formatBankNameClass(bankName: string, cardType?: string): paymentMethodsOptions | string {
    if (bankName) {
      const formatBankName = bankName.toLocaleLowerCase().replace(/\ /g, '').replace(/\//g, '').replace(/\./g, '').replace(/\-/g, '').replace(/\)/g, '').replace(/\(/g, '');
      const findBankName = paymentMethodsOptions[formatBankName];
      if (cardType === 'Prepago' && [paymentMethodsOptions.bancodecreditoeinversiones, paymentMethodsOptions.bancosantanderchile, paymentMethodsOptions.bancoripley].includes(findBankName)) {
        return `${findBankName  }-${  cardType.toLocaleLowerCase()}`;
      }
      return findBankName;
    }
  }

  closeBtn(): void {
    this.dialogRef.close();
  }

  selectCreditCard(paymentMethodId: string) {
    this.paymentMethodForm.patchValue({
      paymentMethod: paymentMethodId
    }, {emitEvent: true});
  }

  changePaymentMethod(): void {
    this.dialogRef.close({
      paymentMethodId: this.selectedCardId
    });
  }

  async creditCardRegistration() {
    this.showLoader = true;
    this.cookiesService.setCookie('new-checkout', JSON.stringify(this.data.entities.map(entity => entity.id)), '.neatpagos.com', 0.3);
    const defineHomePath = 'home';
    const res = await this.registerCreditCard.getCreditCardRegistrationToken(this.currentUserIdToken, defineHomePath);
    if(res === false) {
      this.showLoader = false;
    }
    this.errorSubscription = this.registerCreditCard.detectErrorOnRequest$.subscribe(error => {
      if (error) {
        this.showLoader = false;
        this.swalService.swalError2('No se ha podido agregar tu tarjeta', `Esto se debe a problemas con tu conexión a internet. Por favor intenta con otra red Wifi. 
        Si estás conectado al internet de tu compañía de celular, inténtalo de nuevo más tarde.`, IconErrorModals.sadCard, true, false);
        this.errorSubscription.unsubscribe();
      }
    });
  }

  openKushkiDialog() {
    this.dialogService.openKushkiDialog(this.isMobile);
  }

  resetStatus() {
    this.successMode = false;
    this.errorMode = false;
  }

}
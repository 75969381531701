import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {
  transform(img: string): any {
    let url;
    if (!img) {
      url = 'assets/img/user_unknown.png';
      return url;
    }
    return img;
  }
}

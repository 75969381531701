import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { CookiesService } from '@services/cookies/cookies.service';
import { IconErrorModals, IconInfoModals } from '@shared/enums/enums.enum';

declare let Intercom: any;
@Injectable({
  providedIn: 'root'
})
export class SweetalertService {
  constructor(
    public router: Router,
    private cookiesService: CookiesService,
  ) { }

  swalConfirmationQuestion(
    title: string,
    message: string,
    alertType: any = 'warning',
    confirmBtnText?: any
  ) {
    return Swal.fire({
      title: `${title}`,
      html: `${message}`,
      type: alertType,
      showCancelButton: true,
      reverseButtons: true,
      /* confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', */
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-warn mx-3 custom-btn-no-color',
        cancelButton: 'mat-raised-button mat-button-base mat-secundary-custom mx-3 custom-btn-no-color'
      },
      buttonsStyling: false,
      confirmButtonText: confirmBtnText ? confirmBtnText : 'Continuar',
      cancelButtonText: 'Cancelar'
    });
  }

  swalTenpoMaxCancellationAmount() {
    return Swal.fire({
      title: 'Ten presente que este pago no se podrá anular',
      html: `<div class='tenpo-alert__body'>Si es necesario, vuelve a corroborar monto y datos del destinatario antes de confirmar</div>`,
      showCancelButton: true,
      reverseButtons: true,
      customClass: {
        confirmButton: 'neat-btn neat-btn__primary w-100 w-md-auto',
        cancelButton: 'neat-btn neat-btn__secondary mr-md-3 mb-3 mb-md-0 tenpo-alert-title w-100 w-md-auto',
        title: 'tenpo-alert__title',
        content: 'tenpo-alert__body'
      },
      buttonsStyling: false,
      confirmButtonText: 'Confirmar pago',
      cancelButtonText: 'Cancelar'
    });
  }

  entitySavedQuestion(
    title: string,
    message: string
  ) {
    return Swal.fire({
      title: `${title}`,
      allowOutsideClick: false,
      text: `${message}`,
      type: 'question',
      showCancelButton: true,
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary mx-3 custom-btn-no-color',
        cancelButton: 'mat-raised-button mat-button-base mat-warn mx-3 custom-btn-no-color'
      },
      buttonsStyling: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    });
  }

  swalConfirmationCheckoutQuestion(
    title: string,
    message: string,
    alertType: any = 'warning',
  ) {
    return Swal.fire({
      title: `${title}`,
      text: `${message}`,
      type: alertType,
      showCancelButton: true,
      /* confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', */
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-warn mx-3 custom-btn-no-color checkout-btn-go-back mt-3 order-1',
        cancelButton: 'mat-raised-button mat-button-base mat-primary mx-3 custom-btn-no-color checkout-btn-continue mt-3 order-2'
      },
      buttonsStyling: false,
      confirmButtonText: 'Sí, salir',
      cancelButtonText: 'No, continuar con el pago'
    });
  }


  swalToastGeneral(
    msgTitle: string,
    swalType: any,
    swalPosition: any = 'top-end',
    swalTimer: number = 3000
  ) {
    const Toast = Swal.mixin({
      toast: true,
      position: swalPosition,
      showConfirmButton: false,
      timer: swalTimer
    });

    Toast.fire({
      type: swalType,
      title: `${msgTitle}`
    });
  }
  swalSuccess(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'success',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn'
      },
      buttonsStyling: false
    });
  }

  swalPaymentSuccess(msgTitle: string, msgText: string, showBtn: boolean): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'success',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Crear pin de seguridad',
      showCancelButton: !showBtn,
      customClass: {
        actions: 'flex-column',
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn mb-2',
        cancelButton: 'mat-raised-button mat-button-base custom-btn-secundary create-pin-from-modal',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.dismiss) {
        this.router.navigate(['dashboard/perfil'], { queryParams: { action: 'pin' } });
      }
    });
  }

  swalCreditCardDeletedSuccess(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      imageUrl: 'assets/img/icons/credit-card-removed.svg',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn'
      },
      buttonsStyling: false
    });
  }

  swalInfo(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'info',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary'
      },
      buttonsStyling: false
    });
  }

  swalSencillitoInfo(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'info',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary'
      },
      onClose: () => {
        this.router.navigate(['/dashboard/']);
      },
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });
  }

  swalFidelityInfo(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">${msgTitle}</h2>`,
      html: `${msgText}`,
      confirmButtonText: 'Cerrar',
      cancelButtonText: 'Ver más sobre Neat Unique',
      showCancelButton: false, // este btn debe aparecer una vez se lance por completo neat unique 2.0
      customClass: {
        popup: 'swal2-border-radius w-fit-content px-2 px-md-5',
        actions: 'flex-column',
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn mb-2 fixed-width',
        cancelButton: 'mat-raised-button mat-button-base custom-btn-secundary-2 fixed-width',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.dismiss) {
        window.open('https://neatpagos.com/blog/articulo/nuestro-programa-neat-unique', '_blank');
      }
    });
  }

  swalNeatUniqueV2(msgText: string): void {
    Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">¡Bienvenido a Neat Unique 2.0!</h2>`,
      html: `${msgText}`,
      confirmButtonText: 'Aceptar',
      customClass: {
        popup: 'swal2-border-radius w-fit-content px-2 px-md-5',
        actions: 'flex-column',
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn mb-2 fixed-width',
        cancelButton: 'mat-raised-button mat-button-base custom-btn-secundary-2 fixed-width',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    });
  }

  oldProgram(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">${msgTitle}</h2>`,
      html: `${msgText}`,
      confirmButtonText: 'Aceptar',
      showCloseButton: true,
      customClass: {
        popup: 'swal2-border-radius w-fit-content px-2 px-md-5',
        actions: 'flex-column',
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn mb-2 fixed-width',
        cancelButton: 'mat-raised-button mat-button-base custom-btn-secundary-2 fixed-width',
        closeButton: 'pr-1'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    });
  }

  swalErrorOnTransfer(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">${msgTitle}</h2>`,
      html: `${msgText}`,
      confirmButtonText: 'Ir al chat',
      showCloseButton: true,
      customClass: {
        popup: 'swal2-border-radius w-fit-content px-2 px-md-5',
        actions: 'flex-column',
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn mb-2 fixed-width',
        cancelButton: 'mat-raised-button mat-button-base custom-btn-secundary-2 fixed-width',
        closeButton: 'pr-1'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        Intercom('showNewMessage', '');
      }
    });
  }

  customProviderWarningModal(providerName: string, providerPath: string): void {
    Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">¡Ahora pagar tu ${providerName} es más fácil!</h2>`,
      html: ` <p class="modal-text-content-1">Existe una sección especial de 
      <b>${providerName}. </b>
      Solo debes ingresar tu rut y nosotros cargaremos la deuda. </p>`,
      imageUrl: `assets/img/entity-icons-4/${providerName}.svg`,
      imageHeight: 160,
      showCancelButton: false,
      confirmButtonText: 'Ir a la nueva sección',
      customClass: {
        popup: 'swal2-border-radius px-2 px-md-5',
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn mr-2 custom-min-width'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        this.router.navigate([`/dashboard/${providerPath}`]);
        setTimeout(() => {
          this.cookiesService.deleteCookie('tempEntityCookie', '.neatpagos.com');
        }, 2000);
      }
    });
  }

  expiredFalabellaPromotion(): void {
    Swal.fire({
      title: `<h2 class="custom-modal-falabella-title mt-1">Cambios en la promoción de CMR</h2>`,
      html: `<div>
      <p class="custom-modal-text-falabella">La promoción de 100% de descuento en el primer pago del mes no aplica desde Octubre 2022. La nueva promoción aplica solo para usuarios nuevos desde el mes de Noviembre. 
      </p>
      <p class="custom-modal-text-falabella">Para seguir teniendo descuentos puedes revisar <b style="color: #3368f0 !important;">Neat Unique en promociones</b>. Además traeremos <b>nuevas alianzas y promociones</b> durante el mes, así que atentos a las novedades 👀
      </p>
      </div>`,
      imageUrl: 'assets/promotions/falabella/neat-cmr.svg',
      imageHeight: 100,
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
      customClass: {
        popup: 'swal2-border-radius px-2 px-md-5',
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn mr-2 custom-min-width'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    });
  }

  cmrPointsPromoDetail(): void {
    Swal.fire({
      title: `<h2 class="custom-modal-falabella-title-2 mt-1">¿Cuánto hay que pagar para ganar CMR puntos?</h2>`,
      html: `<div class="text-left">
      <li class="mb-1">Entre $20.000 a $150.000 ganas <b>500 <br/>CMR puntos.</b></li>
      <li class="mb-1">Entre $150.001 a $450.000 ganas <b>1000 <br/>CMR puntos.</b></li>
      <li class="mb-1">Más de $450.000 ganas <b>2000 <br/>CMR puntos.</b></li>
      <p style="font-size: 12px; line-height: 19px; color: #2F2F2F !important;" class="mt-2 mb-0">*El bono se calcula sumando todos los pagos automatizados cargados a tu CMR durante el mes.</p>
      </div>`,
      imageUrl: 'assets/promotions/falabella/cmr-falabella-logo.svg',
      imageHeight: 100,
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
      customClass: {
        popup: 'swal2-border-radius swal-max-width px-2 px-md-5',
        confirmButton: 'mat-raised-button mat-button-base mat-primary falabella-btn-modal mr-2 custom-min-width'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    });
  }

  swalError2(title: string, message: string, iconModal: IconErrorModals,
    openChat: boolean, cancelBtn: boolean, customMaxWidth?: boolean, footer?: string): void {
    Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">${title}</h2>`,
      html: `<p class="modal-text-content-1">${message}</p>`,
      imageUrl: iconModal,
      imageWidth: 90,
      imageHeight: 90,
      cancelButtonText: 'Cancelar',
      showCancelButton: cancelBtn,
      footer: footer ? footer : '',
      confirmButtonText: openChat ? 'Ir al chat' : 'Aceptar',
      customClass: {
        popup: `swal2-border-radius swal-max-width px-2 px-md-5 ${customMaxWidth ? 'extended-swal-width' : ''}`,
        image: 'swal-custom-img',
        confirmButton: 'mat-raised-button mat-button-base mat-primary swal-custom-btn mx-1 custom-min-width',
        cancelButton: 'mat-raised-button mat-button-base swal-custom-btn-secundary-2 mx-1 custom-min-width',
        closeButton: 'pr-1'
      },
      buttonsStyling: false,
      allowOutsideClick: true,
    }).then((result) => {
      if (result.value && openChat) {
        Intercom('showNewMessage', '');
      }
    });
  }

  swalError3(title: string, message: string, btnMessage: string, iconModal: IconErrorModals, openChat: boolean, cancelBtn: boolean, customMaxWidth?: boolean): void {
    Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">${title}</h2>`,
      html: `<p class="modal-text-content-1">${message}</p>`,
      imageUrl: iconModal,
      imageWidth: 90,
      imageHeight: 90,
      cancelButtonText: 'Cancelar',
      showCancelButton: cancelBtn,
      confirmButtonText: btnMessage,
      customClass: {
        popup: `swal2-border-radius swal-max-width px-2 px-md-5 ${customMaxWidth ? 'extended-swal-width' : ''}`,
        image: 'swal-custom-img',
        confirmButton: 'mat-raised-button mat-button-base mat-primary swal-custom-btn mx-1 custom-min-width',
        cancelButton: 'mat-raised-button mat-button-base swal-custom-btn-secundary-2 mx-1 custom-min-width',
        closeButton: 'pr-1'
      },
      buttonsStyling: false,
      allowOutsideClick: true,
    }).then((result) => {
      if (result.value && openChat) {
        Intercom('showNewMessage', '');
      }
    });
  }

  swalInfo2(title: string, message: string, iconModal: IconInfoModals, showCancelBtn?: boolean, acceptBtnText?: string) {
    return Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">${title}</h2>`,
      html: `<p class="modal-text-content-1">${message}</p>`,
      imageUrl: iconModal,
      imageWidth: 90,
      imageHeight: 90,
      cancelButtonText: 'Cancelar',
      confirmButtonText: acceptBtnText ? acceptBtnText : 'Aceptar',
      customClass: {
        popup: 'swal2-border-radius swal-max-width px-2 px-md-5',
        image: 'swal-custom-img',
        confirmButton: 'mat-raised-button mat-button-base mat-primary swal-custom-btn mx-1 custom-min-width',
        cancelButton: 'mat-raised-button mat-button-base swal-custom-btn-secundary-2 mx-1 custom-min-width',
        closeButton: 'pr-1'
      },
      buttonsStyling: false,
      allowOutsideClick: true,
      showCancelButton: showCancelBtn
    });
  }

  swalInfoFalabella(title: string, message: string, iconModal: IconInfoModals) {
    return Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">${title}</h2>`,
      html: `<p class="modal-text-content-1">${message}</p>`,
      imageUrl: iconModal,
      imageWidth: 90,
      imageHeight: 90,
      showCancelButton: true,
      cancelButtonText: 'Pagar sin promoción',
      confirmButtonText: 'Cambiar tarjeta',
      customClass: {
        popup: 'swal2-border-radius swal-max-width px-2 px-md-5',
        image: 'swal-custom-img',
        confirmButton: 'mat-raised-button mat-button-base mat-primary swal-custom-btn mx-1 mb-0 mb-md-3 custom-min-width go-to-change-payment-method-for-promo',
        cancelButton: 'mat-raised-button mat-button-base swal-custom-btn-secundary-2 mx-1 custom-min-width pay-without-promotion',
        closeButton: 'pr-1'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    });
  }

  swalInfoOutdatedAutomaticPayment(title: string, message: string, iconModal: IconInfoModals, payBtn: string, onlyAutomateBtn: string) {
    return Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">${title}</h2>`,
      html: message,
      imageUrl: iconModal,
      imageWidth: 90,
      imageHeight: 90,
      showCancelButton: true,
      cancelButtonText: payBtn,
      confirmButtonText: onlyAutomateBtn,
      customClass: {
        popup: 'swal2-border-radius swal-max-width-430 px-2 px-md-5',
        image: 'swal-custom-img',
        confirmButton: 'mat-raised-button mat-button-base mat-primary swal-custom-btn custom-min-width go-to-change-payment-method-for-promo',
        cancelButton: 'mat-raised-button mat-button-base swal-custom-btn-secundary-2 custom-min-width pay-without-promotion mt-3',
        closeButton: 'pr-1'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
      showCloseButton: true
    });
  }
  swalError(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'error',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-warn rounded-btn'
      },
      buttonsStyling: false
    });
  }

  swalWarning(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'warning',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-warn rounded-btn'
      },
      buttonsStyling: false
    });
  }

  basicServiceSuccess(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      imageHeight: 85,
      type: 'success',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn'
      },
      buttonsStyling: false
    });
  }
  /* Swall for components */
  swalRegisterInfo(): void {
    let timerInterval;
    Swal.fire({
      title: `Te hemos enviado un correo para verificar tu usuario.`,
      html:
        'Por favor ingresa a tu email y sigue las instrucciones.Te enviaremos al dashboard en <strong></strong> segundos.',
      type: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: 5000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector('strong').textContent = (
            Swal.getTimerLeft() / 1000
          ).toFixed(0);
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      }
    });
  }

  swalPromotionAdded(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'success',
      confirmButtonText: 'Ir a pagar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary rounded-btn'
      },
      buttonsStyling: false,
      showCloseButton: true,
    }).then(isConfirm => {
      if (isConfirm) {
        this.router.navigate(['/dashboard/']);
      }
    });
  }

  swalRecoverPin(): void {
    Swal.fire({
      title: `Para esta operación es necesario que hables con el equipo de Neat`,
      html: `Te pediremos información para validar tu identidad. `,
      type: 'info',
      confirmButtonText: 'Háblanos por el chat',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn'
      },
      buttonsStyling: false
    }).then(isConfirm => {
      if (isConfirm) {
        Intercom('showNewMessage', '');
      }
    });
  }


  newErrorModal(msgText: string, msgTitle: string, btnText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      imageUrl: 'assets/img/icons/error-icon-modal.svg',
      imageWidth: 190,
      imageHeight: 85,
      imageAlt: 'neat',
      confirmButtonText: `${btnText}`,
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base custom-btn-secundary'
      },
      buttonsStyling: false
    });
  }


  newWarnModal(msgText: string, msgTitle: string, btnText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      imageUrl: 'assets/img/icons/warn-icon-modal.svg',
      imageWidth: 190,
      imageHeight: 85,
      imageAlt: 'neat',
      confirmButtonText: `${btnText}`,
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base custom-btn-secundary'
      },
      buttonsStyling: false
    });
  }


  swalErrorCustomBtn(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      imageUrl: 'assets/img/icons/error-icon.png',
      imageHeight: 85,
      imageAlt: 'neat',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn my-2'
      },
      buttonsStyling: false
    });
  }

  compareNeatUniqueVersions(): void {
    Swal.fire({
      title: `<h2 class="custom-modal-h2 mt-3">Compara la versión 1.0 y 2.0 de Neat Unique</h2>`,
      html: `<img src="assets/img/illustrations/neatUniqueCompare.svg">`,
      confirmButtonText: 'Aceptar',
      customClass: {
        popup: 'swal2-border-radius w-fit-content px-2 px-md-5',
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn mr-2 custom-min-width',
        cancelButton: 'mat-raised-button mat-button-base custom-btn-secundary-2 custom-min-width',
        closeButton: 'pr-1'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    });
  }


  showPaymentVoucher(text: string, imgPath: string): void {
    Swal.fire({
      title: ``,
      html: `${text}`,
      imageUrl: `${imgPath}`,
      imageHeight: 70,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: 'Cerrar',
      customClass: {
        popup: 'swal2-border-radius px-2 px-md-5 text-left min-h-100',
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn mr-2 custom-min-width'
      },
      buttonsStyling: false,
    });
  }

}

import {
  FormControl,
  FormGroup,
  AbstractControl,
  ValidatorFn
} from '@angular/forms';
import { Injectable } from '@angular/core';
import { ValidationResult } from 'app/interfaces/validationResult.interface';
import { CloudService } from 'app/services/cloud/cloud.service';
import { AppGlobals } from 'app/shared/constants';
import { ErrorHandlerService } from 'app/services/shared/error-handler/error-handler.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { of } from 'rxjs/internal/observable/of';
import { delay } from 'rxjs/internal/operators/delay';
import { catchError } from 'rxjs/internal/operators/catchError';

@Injectable({
  providedIn: 'root'
})
export class FormValidation {
  private static cloudService: CloudService;
  private static errorService: ErrorHandlerService;

  constructor(
        private cloudService: CloudService,
        private errorService: ErrorHandlerService) {
    FormValidation.cloudService = cloudService;
    FormValidation.errorService = errorService;
  }

  static verifyRut() {
    return async (control: AbstractControl) => {
      if (control && control.value && AppGlobals.formatRut(String(control.value)).length >= 8) {
        try {
          return await this.cloudService.rutValidator(AppGlobals.formatRut(control.value)).then(res => {
            const message = res.message;
            if (message && message === 'El RUT ya se encuentra registrado. Para más detalles contactarse con atención al cliente.') {
              return res.status ? null : { usedRut: true };
            } else {
              return res.status ? null : { rutValidation: true };
            }
          });
        } catch (err) {
          FormValidation.errorService.recordError(err, 'form-validation.ts',
            'this.verifyRut()', 'Error al validar disponibilidad de rut');
          return { rutValidation: true };
        }
      }
    };
  }

  static saveRut() {
    return (control: AbstractControl) => {
      return of(control.value).pipe(
        delay(3000),
        switchMap((rut) => this.cloudService.rutValidator(AppGlobals.formatRut(rut)).then(async res => {
          res.status ? await this.cloudService.rutEnroller(AppGlobals.formatRut(String(control.value))) : null;
          return res.status ? null : { rutValidation: true };
        })),
        catchError(() => of(null)));
    };
  }

  validateEmail(control: FormControl): ValidationResult {
    const valid = control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g);
    return valid ? null : { validMail: true };
  }

  onlyNumberValidator(control: FormControl): ValidationResult {
    const val = control.value;
    if (val === null || val === '') {return null;}
    if (!val.toString().match(/^[-+]?[1-9]\d*$/)) {return { 'invalidNumber': true };}
    return null;
  }

  onlyNumberAndCommaValidator(control: FormControl): ValidationResult {
    const val = control.value;
    if (val === null || val === '') {return null;}
    if (!val.toString().match(/^[0-9]+.?[0-9]*\b$/)) {return { 'invalidUFNumber': true };}
    return null;
  }

  static differentMonthValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl) => {
      const transferLimitDate = Number(control.value);
      if ((transferLimitDate >= min && transferLimitDate <= 31) || (transferLimitDate <= max && transferLimitDate > 0)) {
        return null;
      } else {
        return { 'invalidAutomateDay': true };
      }
    };
  }

  static sameMonthValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl) => {
      const transferLimitDate = Number(control.value);
      if (transferLimitDate >= min && transferLimitDate <= max) {
        return null;
      } else {
        return { 'invalidAutomateDay': true };
      }
    };
  }

  onlyTrue(control: FormControl): ValidationResult {
    const isTrue = control.value;
    return isTrue === 'true' ? null : { onlyTrue: true };
  }

  passwordCustomValidations(control: FormControl): ValidationResult {
    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const valid = hasNumber && hasUpper && hasLower;
    if (!valid) {
      // return what´s not valid
      return { strongPassword: true };
    }
    return null;
  }

  acceptTerms(control: FormControl): ValidationResult {
    const validateAcceptTerms = control.value;
    return validateAcceptTerms === true ? null : { acceptTermsInvalid: true };
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }


  checkSafetyPasswords(control: FormControl): ValidationResult {
    const pass = String(control.value);
    const unsafetyPasswords = ['0000', '1111', '2222', '3333', '4444', '5555', '6666', '7777', '8888', '9999'];
    if (unsafetyPasswords.includes(pass)) {return { notSafe: true };}
    return null;
  }

}

<div *ngIf="step === 2" class="p-2 text-center">
    <span class="searching-text d-block mb-4">
        {{selectedBasicServices.length === 1 ? 'Buscando cuenta': 'Buscando cuentas'}}
    </span>
    <div class="progress-bar-background w-100 mb-4">
        <div class="progress-bar-container" [ngClass]="(progressWidth | async) !== '0%' ? 'fill-background' : ''" [ngStyle]="{width: (progressWidth | async)}">&nbsp;
        </div>
    </div>
    <span class="secundary-text">
        Esto solo demorará un momento...
    </span>
</div>
<div *ngIf="step === 3" class="final-step" [ngClass]="basicServicesSaved.length > 5 ? 'custom-heigth' : ''">
    <div class="boxes p-0 p-md-2">
        <div class="header d-block">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="w-100">
                    <div class="d-flex justify-content-between align-items-center">
                        <span *ngIf="basicServicesSaved.length > 0 && success" class="d-block searching-text text-left">
                            Encontramos {{basicServicesSaved.length}} {{basicServicesSaved.length === 1 ? 'cuenta': 'cuentas'}}  🎉 
                        </span>
                        <span *ngIf="basicServicesSaved.length === 0 && (success || !success)" class="d-block searching-text text-left">
                            No encontramos ninguna cuenta 🧐  
                        </span>
                        <span (click)="onNoClickNotFound()"><mat-icon class="cursor-pointer">close</mat-icon></span>
                    </div>
                </div>
            </div>
            <span *ngIf="basicServicesSaved.length > 0 && success" class="d-block secundary-text-2 mb-2 mb-md-4">
                Buscamos por {{isSameRut ? 'tu RUT' : 'el RUT'}} y te agregamos {{basicServicesSaved.length === 1 ? 'la siguiente cuenta:': 'las siguientes cuentas:'}} 
            </span>
            <div *ngIf="basicServicesSaved.length === 0 && (success || !success)" class="text-center py-3">
                <span class="d-block mb-4 purple-text">
                    Buscamos por {{isSameRut ? 'tu RUT' : 'el RUT'}} las cuentas que nos indicaste y no hemos encontramos ninguna. 
                </span>
                <span class="d-block purple-text bold">
                    Búscalas tú y agrégalas por tu cuenta!
                </span>
            </div>
        </div>
        <div class="content">
            <div>
                <div *ngFor="let basicService of basicServicesSaved;" class="w-100 py-3 basic-service-payed">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center justify-content-center">
                           <div class="logo-box mr-3">
                                <img class="w-100" src="{{basicService?.logoUrl}}" onerror="this.src='assets/img/basic-services-icons/other.svg'" alt="{{ basicService?.utilityName}}">
                            </div>
                            <div class="d-block">
                                <span class="d-block company-name">
                                    {{basicService?.utilityName | customTitlecase}}
                                </span>
                                <span class="d-block company-category">
                                    {{basicService?.category | titlecase}}
                                </span>
                            </div>
                        </div>
                        <div class="text-right mobile-max-width">
                            <span [ngClass]="basicService?.currentDebt && basicService.value && basicService.value > 0 ? 'debt-available' : 'no-debt'" *ngIf="!basicService.currentDebtError">{{ basicService?.currentDebt && basicService.value ? (basicService?.value | clpCurrency) : 'Sin deuda'}}</span>
                            <span *ngIf="basicService.currentDebtError" class="no-debt">Deuda no disponible</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer text-center pt-2 pt-md-4">
            <div *ngIf="basicServicesSaved.length > 0 && success" (click)="goToHome()" class="neat-main-btn cursor-pointer m-auto" style="width: fit-content !important;">
                <span>{{basicServicesSaved.length === 1 ? 'Ver cuenta': 'Ver cuentas'}} </span>
            </div>
            <div *ngIf="basicServicesSaved.length === 0 && (success || !success)" (click)="onNoClickFinished()" class="neat-main-btn cursor-pointer m-auto" style="width: fit-content !important;">
                <span>Agrega{{haveEntitiesAdded ? ' una cuenta' : ' tu primera cuenta'}}</span>
            </div>
        </div>
    </div>
</div>

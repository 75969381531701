import { EntityType } from '../shared/enums/enums.enum';

/**
 * POSTed Body for 'payments/rent' Servipag route
 */
export class PaymentRequestDto {
  readonly provider: string;
  readonly providerParameters: {
    redirectTo: string;
  };
  readonly entityType: EntityType;
  readonly entityId: string;
  readonly tokenId: string;

  constructor(provider: string) {
    this.provider = provider;
    this.providerParameters = {
      redirectTo: 'https://app.neatpagos.com/dashboard/historial?status=ok'
    };
  }
}

export class PaymentRequestTransbank {
  readonly provider: string;
  readonly providerParameters: {
    redirectTo: string;
  };
  readonly entityType: EntityType;
  readonly entityId: string;
  readonly tokenId: string;

  constructor(provider: string) {
    this.provider = provider;
    this.providerParameters = {
      redirectTo: 'https://app.neatpagos.com/dashboard/historial?status=ok'
    };
  }
}

/* eslint-disable max-len */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

import { creditCardValidatorDTO, requestDTO } from './../../interfaces/promotions.interface';
import { Entity } from '../../models/abstract-entity.model';
import { ErrorHandlerService } from '../../services/shared/error-handler/error-handler.service';
import { AppGlobals } from '../../shared/constants';
import { EntityType } from 'neat-lib';


export interface IShinkansenOperationNewBanking {
  toAccount?: string;
  toAccountType?: string;
  toBank?: string;
  toDNI?: string;
  toName?: string;
  toEmail?: string;
}
export interface IShinkansenTranslateBanking {
  paymentId: string;
  entityType: EntityType;
  newBanking: IShinkansenOperationNewBanking;
}

@Injectable({
  providedIn: 'root'
})
export class CloudService {

  afFunction$: Observable<any>;
  loading: boolean;

  constructor(
    private aff: AngularFireFunctions,
    private http: HttpClient,
    private errorService: ErrorHandlerService
  ) {
    this.loading = false;
  }

  /**
   * Activa la CF userCreationByAdmin(data)
   * @param data Datos para mandar a la CF.
   * Puede ser un objeto Rent o cualquier cosa que tenga al menos:
   * - lesseeEmail
   * - lesseeRut
   * - lesseeFullName
   * - adminFullName
   */
  userCreationByAdmin(data: any): void {
    const sendingData = {
      lesseeEmail: data.lesseeEmail,
      lesseeRut: data.lesseeRut,
      lesseeFullName: data.lesseeFullName,
      adminFullName: data.adminFullName,
      displayCode: AppGlobals.makeRandomCode(10),
      tipoEmail: 'invitations-email'
    };
    const callable = this.aff.httpsCallable('userCreationByAdmin');
    const afFunction$: Observable<any> = callable(sendingData);
    afFunction$.subscribe({
      next: () => { },
      error: (error) => {
        this.errorService.recordError(error, 'cloud.service.ts',
          'afFunction$.subscribe()', 'Error en subscribe de cloud function');
      }
    });
  }

  commissionRobot(
    entities:
      | Entity[]
      | Array<{ id: string; value: number; entityType: EntityType }>,
  ): Observable<Array<any>> {
    const sendingData = { entities };
    const callable = this.aff.httpsCallable('commissionRobot');
    const afFunction$: Observable<any> = callable(sendingData);
    return afFunction$;
  }

  async saveUserSurveys(data: any): Promise<any> {
    const sendingData = data;
    const callable = this.aff.httpsCallable('saveUserSurveys');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async getDepositeeByAddress(data: any): Promise<any> {
    const sendingData = data;
    const callable = this.aff.httpsCallable('getDepositeeByAddress');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async creditCardValidator(data: any): Promise<any> {
    const sendingData: creditCardValidatorDTO = { promotionCode: data.promotionCode, lastCodeNumbers: data.lastCodeNumbers, bank: data.bank };
    const callable = this.aff.httpsCallable('creditCardValidator');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async addBankToPaymentMethod(data: any): Promise<any> {
    const sendingData: any = data;
    const callable = this.aff.httpsCallable('addBankToPaymentMethod');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async promotionValidator(data: requestDTO): Promise<any> {
    const sendingData = data;
    const callable = this.aff.httpsCallable('promotionValidator');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async suggestNewEntity(data: any): Promise<any> {
    const sendingData = data;
    const callable = this.aff.httpsCallable('entityTypeRequest');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async updatePinMethod(data: any): Promise<any> {
    const sendingData = data;
    const callable = this.aff.httpsCallable('updatePinMethod');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async userRegistrationConsistency(data: any): Promise<any> {
    const sendingData = data;
    const callable = this.aff.httpsCallable('userRegistrationConsistency');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async rutValidator(data: any): Promise<any> {
    const sendingData = { rut: data };
    const callable = this.aff.httpsCallable('rutValidator');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async neatCheckCoupon(code: string, token: string, userId: string): Promise<any> {
    let url = '';
    if (isDevMode()) {
      url = 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/neatCheckCoupon';
    } else {
      url = 'https://us-central1-neatwebplatform.cloudfunctions.net/neatCheckCoupon';
    }
    const body = { code };
    const httpHeader = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'X-Neat-uid': userId,
      })
    };
    const res = await this.http.post(url, body, httpHeader).toPromise();
    return res;
  }

  async neatUseCoupon(code: string, token: string, userId: string): Promise<any> {
    let url = '';
    if (isDevMode()) {
      url = 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/neatUseCoupon';
    } else {
      url = 'https://us-central1-neatwebplatform.cloudfunctions.net/neatUseCoupon';
    }
    const body = { code };
    const httpHeader = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'X-Neat-uid': userId,
      })
    };
    const res = await this.http.post(url, body, httpHeader).toPromise();
    return res;
  }

  async changeUserEmail(currentEmail: string, newEmail: string): Promise<any> {
    const sendingData = { currentEmail, newEmail };
    const callable = this.aff.httpsCallable('changeUserEmail');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async changeUserName(data: any): Promise<any> {
    const sendingData = data;
    const callable = this.aff.httpsCallable('changeUserName');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async rutEnroller(data: any): Promise<any> {
    const sendingData = { rut: data };
    const callable = this.aff.httpsCallable('rutEnroller');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async togglePromotionStatus(): Promise<any> {
    const sendingData = { data: null };
    const callable = this.aff.httpsCallable('togglePromotionStatus');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async userDocCreate(data?: any): Promise<any> {
    const sendingData = { ip: data };
    const callable = this.aff.httpsCallable('coldStarts-userDocCreate');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async entityDocCreate(data: EntityType): Promise<any> {
    const sendingData = { entityType: data };
    const callable = this.aff.httpsCallable('coldStarts-entityDocCreate');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async recaptchaVerify(token: any): Promise<any> {
    const sendingData = { token };
    const callable = this.aff.httpsCallable('recaptchaVerify');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async engagementBigqueryMetrics(data: any): Promise<any> {
    const callable = this.aff.httpsCallable('engagementBigqueryMetrics');
    this.afFunction$ = callable(data);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async wakeUpRutValidator(): Promise<any> {
    const sendingData = { rut: '', action: 'enroll' };
    const callable = this.aff.httpsCallable('rutHandler');
    this.afFunction$ = callable(sendingData);
    const res = await this.afFunction$.toPromise();
    return res;
  }

  async checkValidReferralsIds(invitationCode: string): Promise<boolean> {
    const sendingData = { invitationCode };
    const callable = this.aff.httpsCallable('checkValidReferralsIds');
    this.afFunction$ = callable(sendingData);
    const {
      isInvitationCodeValid
    } = await this.afFunction$.toPromise();
    return isInvitationCodeValid;
  }

  async sendIntercomMessage(message: string): Promise<any> {
    const sendingData = {
      message
    };
    const callable = this.aff.httpsCallable('sendIntercomMessage');
    this.afFunction$ = callable(sendingData);
    try {
      const response = await this.afFunction$.toPromise();
      return response;
    } catch (error) {
      return false;
    }
  }

  async retryFailedTransfer(message: IShinkansenTranslateBanking): Promise<any> {
    try {
      const callable = this.aff.httpsCallable('retryFailedTransfer');
      this.afFunction$ = callable(message);
      const response = await this.afFunction$.toPromise();
      return {success: response ? true : false};
    } catch (error) {
      return {success: false, errorMessage: error.message};
    }
  }

  async getRegisterInfo(email: string): Promise<any> {
    const sendingData = {
      email
    };
    const callable = this.aff.httpsCallable('getRegisterInfo');
    this.afFunction$ = callable(sendingData);
    try {
      const response = await this.afFunction$.toPromise();
      return response;
    } catch (error) {
      return false;
    }
  }

  async getCustomProviderDebt(billId: string, billerId: string, flow?: string, persistDebt?: boolean): Promise<any> {
    const sendingData = {
      billId,
      billerId,
      originFlow: flow ? flow : 'neatFlow',
      ...(persistDebt && {
        persistDebt: true,
      })
    };
    const callable = this.aff.httpsCallable('getCustomProviderDebtHandler');
    this.afFunction$ = callable(sendingData);
    try {
      const response = await this.afFunction$.toPromise();
      return response;
    } catch (error) {
      return false;
    }
  }


  async getNeatPointsToEarn(data: any, token: string, userId: string): Promise<any> {
    let url = '';
    if (isDevMode()) {
      url = 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/getNeatPoints';
    } else {
      url = 'https://us-central1-neatwebplatform.cloudfunctions.net/getNeatPoints';
    }
    const body = { payment: data };
    const httpHeader = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'X-Neat-uid': userId,
      })
    };
    try {
      const res = await this.http.post(url, body, httpHeader).toPromise();
      return res;
    } catch (error) {
      return false;
    }
  }


  async neatClubRedeemReward(rewardId: string, token: string, userId: string): Promise<any> {
    let url = '';
    if (isDevMode()) {
      url = 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/redeemReward';
    } else {
      url = 'https://us-central1-neatwebplatform.cloudfunctions.net/redeemReward';
    }
    const body = {
      rewardId,
      userId,
    };
    const httpHeader = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'X-Neat-uid': userId,
      })
    };
    try {
      const res = await this.http.post(url, body, httpHeader).toPromise();
      return res;
    } catch (error) {
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PromotionsService } from '@services/promotions/promotions.service';
import { RentListService } from '@services/rent-list/rent-list.service';
import { AppGlobals } from '@shared/constants';
import { IAutomaticPaymentDates } from 'app/interfaces/automatic-payment.interface';
import { FormValidation } from 'app/landing/register/form-validation';
import { Payment } from 'app/models/abstract-payment.model';
import { Promotion } from 'app/models/promotions.model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { Entity } from '../../models/abstract-entity.model';
import { PayingAccountUnit, PaymentProduct, PaymentStatus } from '../../shared/enums/enums.enum';

@Injectable({
  providedIn: 'root',
})
export class PaymentClass {
  public paymentsDone: Observable<Payment[]> ;
  public paymentsInProgress: Observable<Payment[]> ;
  constructor(
        private promotionsService: PromotionsService,
        private formValidation: FormValidation,
        private fb: FormBuilder,
        private rentListService: RentListService
  ) {
    this.setDonePayments();
    this.setInProgressPayments();
  }

  setDonePayments(): void {
    this.paymentsDone = this.rentListService.allPayments$.pipe(map(payments => {
      return payments.filter(payment => payment.paymentStatus?.currentStatus === PaymentStatus.InProgress ||
                                            payment?.currentPaymentStatus === PaymentStatus.InProgress);
    })
    );
  }

  setInProgressPayments(): void {
    this.paymentsInProgress = this.rentListService.allPayments$.pipe( map(payments => {
      const today = new Date();
      payments = this.filterDonePayments(payments, today);
      return payments;
    })
    );
  }

  /** Set the param data for commission robot request
     * @param entity Entity
     * @param paymentMethodForm FormGroup
     * @param userId string
     * @param amount number
     */
  public commissionRobotData(entity: Entity, paymentMethodForm: FormGroup, userId: string, amount: number) {
    return {
      entities : [this.entityValueForComissionRobot(entity, amount)],
      paymentMethodId: this.definePaymentMethodId(paymentMethodForm) ? this.definePaymentMethodId(paymentMethodForm) : '',
      uid: userId
    };
  }


  public commissionRobotDataMultipay(entities: any[], paymentMethodForm: FormGroup, userId: string, uf: number) {
    const dataArray = entities.map(entity => {
      if (entity?.utilityName) {
        // entity.currentDebt.amount replaced with entity.value
        return {
          id: entity.id,
          value: entity.value,
          entityType: entity.entityType
        };
      } else if (entity?.customData) {
        let valueCLP;
        if (entity.payingAccountUnit === PayingAccountUnit.UF && uf) {
          valueCLP = Math.round(AppGlobals.convertUFToPeso(uf, entity.valueUF));
        }
        const customDataBody = {
          id: entity.id,
          value: !valueCLP ? entity.value : valueCLP,
          entityType: entity.entityType
        };
        if (entity?.customData && entity?.customData?.biller === 'edipro') {
          Object.assign(customDataBody, {
            isCustomProvider: true,
          });
        }
        return customDataBody;
      } else {
        let valueCLP;
        if (entity.payingAccountUnit === PayingAccountUnit.UF && uf) {
          valueCLP = Math.round(AppGlobals.convertUFToPeso(uf, entity.valueUF));
        }
        return {
          id: entity.id,
          value: !valueCLP ? entity.value : valueCLP,
          entityType: entity.entityType
        };
      }
    });
    return {
      entities : dataArray,
      paymentMethodId: paymentMethodForm.get('paymentMethod').value,
      uid: userId
    };
  }

  private entityValueForComissionRobot(entity: Entity, amount: number) {
    return {
      id: entity.id,
      value: amount,
      entityType: entity.entityType
    };
  }

  private definePaymentMethodId(paymentMethodForm: FormGroup, ) {
    return this.getPaymentMethodId(paymentMethodForm);
  }

  private getPaymentMethodId(paymentMethodForm: FormGroup) : string {
    const paymentMethod = paymentMethodForm.get('paymentMethod').value;
    const getPaymentOption = PaymentProduct[paymentMethodForm.get('paymentOption').value];
    if (paymentMethod && getPaymentOption === PaymentProduct.oneclick) {return paymentMethod;}
  }

  public getCommissionValue(commissionBotResponse: any) {
    if (commissionBotResponse.status === 200) {
      const fee = JSON.parse(commissionBotResponse?.body).data;
      if (fee) {
        return this.buildCommissionValue(fee);
      }
    }
  }

  private buildCommissionValue(commissionBotResponse: any) {
    return {promoId: commissionBotResponse.promoId, commission: commissionBotResponse.commission, baseCommission: commissionBotResponse.baseCommission, discountApplied: commissionBotResponse?.discount ? parseInt(commissionBotResponse.discount) : 0, fee: commissionBotResponse.fee};
  }

  public getPromotionInfo( promoId: string) {
    return this.promotionsService.allPromotions$.pipe(map( (promotions: Promotion[]) => {
      if (promotions && promotions.length > 0) {return promotions.filter((promotion) => this.promotionsFilter(promotion, promoId));}
    }));
  }

  private promotionsFilter(promotion: Promotion, promoId: string) {
    return promotion.id === promoId && promotion.entity ? true : false;
  }

  private checkCanAutomatizateCurrentMonth(diffDays: number, day: number, today: Date, month: number): boolean {
    let currentMonth = today.getMonth() + 1;
    currentMonth = currentMonth === 13 ? 1 : currentMonth;
    if (month !== currentMonth && diffDays >= 28 && (day >= today.getDate() || today.getDate() >= day && day < 10)) {
      return false;
    } else if (month === currentMonth && diffDays >= 28 && (today.getDate() <= day)) {
      return false;
    } else {
      return true;
    }
  }

  public dateDiffInDays(firstDate: Date, currentDate: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
    const utc2 = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }


  public buildPaymentForm(maxNeatCost: number, minNeatCost: number, UF: number): FormGroup {
    return this.fb.group({
      amount: [
        0,
        [Validators.required, Validators.min(minNeatCost), Validators.max(maxNeatCost)]
      ],
      amountUF: [
        0,
        [
          Validators.required,
          Validators.min(
            Number(AppGlobals.convertPesoToUF(UF, minNeatCost).toFixed(3))
          ),
          Validators.max(
            Number(AppGlobals.convertPesoToUF(UF, maxNeatCost).toFixed(3))
          ),
          this.formValidation.onlyNumberAndCommaValidator
        ]
      ],
      payingAccountUnit: ['', Validators.required],
      paymentMonth: ['', Validators.required],
      paymentYear: [`${new Date().getFullYear()}`, Validators.required],
      lastTransferComment: [''],
      transferLimitDate: ['', [Validators.min(1), Validators.max(31), this.formValidation.onlyNumberValidator]],
      fixedValue: [''],
      receiverEmailNotification: ['', Validators.required],
      sendReminderNotification: [''],
    });
  }

  public buildPaymentMethodForm(): FormGroup {
    return this.fb.group({
      paymentMethod: ['', Validators.required],
      paymentOption: ['oneclick', Validators.required],
      installments: ['', Validators.required]
    });
  }

  public buildPaymentMethodFormNew(): FormGroup {
    return this.fb.group({
      paymentMethod: ['', Validators.required],
      paymentOption: ['oneclick', Validators.required],
      installments: ['0', Validators.required]
    });
  }

  public buildPaymentMethodFormOnlyMethod(): FormGroup {
    return this.fb.group({
      paymentMethod: ['', Validators.required],
      paymentOption: ['oneclick', Validators.required]
    });
  }

  public getCleanTransferCommentValue(lastTransferComment: string): string {
    if (lastTransferComment) {
      return this.clearLastTransferComment(lastTransferComment);
    }
  }

  private clearLastTransferComment(transferComment: string): string {
    if (this.transferCommentValidation(transferComment)) {
      return '';
    } else {
      return transferComment;
    }
  }

  private transferCommentValidation(transferComment: string): boolean {
    return (transferComment.match(/\.?(Pago:)/g) && transferComment.match(/\.?(Mes:)/g) && transferComment.match(/\.?(Dirección:)/g)) ? true : false;
  }


  public automaticPaymentDate(day: number): IAutomaticPaymentDates {
    const today = new Date(new Date().toLocaleString('en-US', {timeZone: 'America/Santiago'}));
    let year = today.getFullYear();
    const transferLimitDate = day;
    let month = today.getMonth() + 1;
    let defaultTransferLimitDate: Date;
    let state = false;
    let canAutomatizateCurrentMonth = false;
    do {
      month = month === 13 ? 1 : month;
      const lastDayOfMonth: number = AppGlobals.lastDaysOfMonths[month - 1];
      if (day > lastDayOfMonth) {day = lastDayOfMonth;}
      defaultTransferLimitDate = AppGlobals.transferLimitDateFromDate(`${year}/${month}/${day}`);
      const diffDays = this.dateDiffInDays(today, new Date(new Date(`${year}/${month}/${day}`).toLocaleString('en-US', { timeZone: 'America/Santiago' })));
      if (today.getTime() >= defaultTransferLimitDate.getTime() || (defaultTransferLimitDate.getMonth() < today.getMonth() && today.getTime() >= defaultTransferLimitDate.getTime())) {
        state = false;
        month++;
        if (month === 13) {year++;}
      } else {
        defaultTransferLimitDate = AppGlobals.transferLimitDateFromDate(`${year}/${month}/${day}`);
        canAutomatizateCurrentMonth = this.checkCanAutomatizateCurrentMonth(diffDays, transferLimitDate, today, month);
        const dateObject = {day, defaultTransferLimitDate, month};
        state = true;
        return Object.assign(this.setAutomaticPaymentDateStringValue(dateObject), {
          canAutomatizateCurrentMonth: canAutomatizateCurrentMonth,
        });
      }
    } while (!state);
  }

  public automaticPaymentDateCustomEntities(day: number): IAutomaticPaymentDates {
    const today = new Date(new Date().toLocaleString('en-US', {timeZone: 'America/Santiago'}));
    let year = today.getFullYear();
    const transferLimitDate = day;
    let month = today.getMonth() + 1;
    let defaultTransferLimitDate: Date;
    let state = false;
    let canAutomatizateCurrentMonth = false;
    do {
      month = month === 13 ? 1 : month;
      const lastDayOfMonth: number = AppGlobals.lastDaysOfMonths[month - 1];
      if (day > lastDayOfMonth) {day = lastDayOfMonth;}
      defaultTransferLimitDate = AppGlobals.transferLimitDateFromDateCustomEntity(`${year}/${month}/${day}`);
      const diffDays = this.dateDiffInDays(today, new Date(new Date(`${year}/${month}/${day}`).toLocaleString('en-US', { timeZone: 'America/Santiago' })));
      if (today.getTime() >= defaultTransferLimitDate.getTime() || (defaultTransferLimitDate.getMonth() < today.getMonth() && today.getTime() >= defaultTransferLimitDate.getTime())) {
        state = false;
        month++;
        if (month === 13) {year++;}
      } else {
        defaultTransferLimitDate = AppGlobals.transferLimitDateFromDateCustomEntity(`${year}/${month}/${day}`);
        canAutomatizateCurrentMonth = this.checkCanAutomatizateCurrentMonth(diffDays, transferLimitDate, today, month);
        const dateObject = {day, defaultTransferLimitDate, month};
        state = true;
        return Object.assign(this.setAutomaticPaymentDateStringValue(dateObject), {
          canAutomatizateCurrentMonth: canAutomatizateCurrentMonth,
        });
      }
    } while (!state);
  }

  private setAutomaticPaymentDateStringValue(dateObject: any): IAutomaticPaymentDates {
    const date = dateObject.defaultTransferLimitDate.getDate();
    const month = dateObject.defaultTransferLimitDate.getMonth();
    return {
      chargeDay: `${date} de ${AppGlobals.monthNames[month === 12 ? 0 : month]}`,
      depositDay: `${dateObject.day} de ${dateObject.day > date ? AppGlobals.monthNames[month === 12 ? 0 : month] : AppGlobals.monthNames[month + 1 === 12 ? 0 : month + 1]}`,
      automaticPaymentDay: `El cargo automático para pagar el mes de ${AppGlobals.monthNames[dateObject.month - 1]} se realizará el ${date} de ${AppGlobals.monthNames[month]}`
    };
  }

  private filterDonePayments(payments: Payment[], today: Date): Payment[] {
    return payments.filter( (payment: any) =>
      (payment.paymentStatus?.currentStatus === PaymentStatus.Done || payment?.currentPaymentStatus === PaymentStatus.Done) &&
      (new Date(payment.transferDate.seconds * 1000)).getDate() === today.getDate() &&
      (new Date(payment.transferDate.seconds * 1000)).getMonth() === today.getMonth() &&
      (new Date(payment.transferDate.seconds * 1000)).getFullYear() === today.getFullYear()
    );
  }


  public showAccountType(bankAccountType: string): string {
    switch (bankAccountType) {
    case '1':
      return 'Cuenta Corriente';
    case '2':
      return 'Cuenta Vista';
    case '3':
      return 'Chequera Electrónica';
    case '4':
      return 'Cuenta de Ahorro';
    default:
      return '-';
    }
  }

}

import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
declare let Intercom: any;

enum ErrorMessages {
  RETRY_IN_PROCESS = 'Retry already in process',
  USER_NOT_FOUND = 'User not found',
  RUT_NOT_ALLOWED = 'Rut not allowed',
  PAYMENT_NOT_FOUND = 'Payment not found',
  PAYMENT_NOT_PENDING = 'Payment not pending'
}

@Component({
  selector: 'app-retry-transfer-error',
  templateUrl: './retry-transfer-error.component.html',
  styleUrls: ['../retry-transfer.component.scss']
})

export class RetryTransferErrorComponent implements OnInit{
  @Input() entityType: string;
  @Input() paymentError: boolean;
  @Input() errorMessage: string;
  translatedErrorMessage: string;
  @Output () setCloseModal = new EventEmitter();
  constructor(
  ) {
  }

  ngOnInit(): void {
    this.translatedErrorMessage = this.generateFriendlyErrorMessage(this.errorMessage);
  }
  
  closeModal(): void {
    this.setCloseModal.emit();
  }

  openIntercom() {
    Intercom('showNewMessage', '');
    this.setCloseModal.emit();
  }


  generateFriendlyErrorMessage(errorMessage): string {
    if (errorMessage === ErrorMessages.RETRY_IN_PROCESS) {
      return 'Ya hay un reintento en proceso';
    } else if (errorMessage === ErrorMessages.USER_NOT_FOUND) {
      return 'Usuario no encontrado';
    } else if (errorMessage === ErrorMessages.RUT_NOT_ALLOWED) {
      return 'El RUT no está permitido';
    } else if (errorMessage === ErrorMessages.PAYMENT_NOT_FOUND) {
      return 'Pago no encontrado';
    } else if (errorMessage === ErrorMessages.PAYMENT_NOT_PENDING) {
      return 'El pago no está pendiente';
    } else {
      return 'Error desconocido';
    }
  }

}

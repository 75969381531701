/* eslint-disable max-len */
/* eslint-disable no-empty */
import {
  CustomProvidersNames,
  PaymentMethodsBanks
} from 'neat-lib/dist/Enums/Constants';
import { isHoliday } from 'neat-lib/dist/Functions/Functions';

import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';

import { ProfessionalServiceCategoriesCodes } from './enums/enums.enum';

export interface IVerification {
  emoji: string;
  title: string;
  description: string;
  mainBtnText?: string;
  securityBtnText?: string;
}

export enum VerificationOptions {
  processing = 'processing',
  verified = 'verified',
  fatalError = 'fatalError',
  notSameRut = 'notSameRut',
  temporalBlock = 'temporalBlock',
  stillProcessing = 'stillProcessing',
  notCompleted = 'notCompleted'
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export type MergeTypes<A, B> = {
  [key in keyof A]: key extends keyof B ? B[key] : A[key];
} &
  B;

export const today = new Date(
  new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
);

export function formatEdipro(
  inputValue: string,
  commonExpense?: boolean
): string {
  if (
    inputValue &&
    inputValue.toLocaleLowerCase() ===
    CustomProvidersNames.edipro.toLocaleLowerCase() &&
    !commonExpense
  ) {
    return 'EdiPro';
  } else if (
    inputValue &&
    inputValue.toLocaleLowerCase() ===
    CustomProvidersNames.edipro.toLocaleLowerCase() &&
    commonExpense
  ) {
    return 'Gasto Común EdiPro';
  } else {
    return inputValue;
  }
}

export function camelCaseToSnakeCase(input: string): string {
  if (!input) {
    return '';
  }
  input = input.charAt(0).toLowerCase() + input.slice(1);
  input = input.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);
  input = input.replace(/\s+/g, '_');
  input = input.replace(/_+/g, '_');
  return input;
}

export function getKeyOfEnum<T>(enumObj: T, value: any): keyof T | null {
  try {
    for (const key in enumObj) {
      if (enumObj[key] === value) {
        return key;
      }
    }
    return null;
  } catch (err) { }
}

export function diffInDays(firstDate: Date, currentDate: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(
    firstDate.getFullYear(),
    firstDate.getMonth(),
    firstDate.getDate()
  );
  const utc2 = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const listOfProffesionalServices: ProfessionalServiceCategoriesCodes[] = [ProfessionalServiceCategoriesCodes.Gasfiter,
ProfessionalServiceCategoriesCodes.Jardinero,
ProfessionalServiceCategoriesCodes.Odontologo,
ProfessionalServiceCategoriesCodes.Psicologo,
ProfessionalServiceCategoriesCodes.Abogado,
ProfessionalServiceCategoriesCodes.Contador,
ProfessionalServiceCategoriesCodes.Arquitecto];

export const basicServicesSuggested = [
  {
    name: 'AutoTag',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/AutoTag.svg',
    utilityNumber: ['NT-CL-G-00400'],
    category: ['Todas las autopistas'],
    alts: ['AutoTag - Paga todas tus autopistas juntas']
  },
  {
    name: 'Entel',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/entel.svg',
    utilityNumber: ['NT-CL-S-00442', 'NT-CL-S-00443'],
    category: ['Hogar / Móvil'],
    alts: ['Entel']
  },
  {
    name: 'Movistar',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/movistar.svg',
    utilityNumber: ['NT-CL-S-00440', 'NT-CL-S-00441'],
    category: ['Hogar / Móvil'],
    alts: ['Movistar']
  },
  {
    name: 'WOM',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/wom.svg',
    utilityNumber: ['NT-CL-S-00444', 'NT-CL-S-00452'],
    category: ['Hogar / Móvil'],
    alts: ['WOM']
  },
  {
    name: 'Claro',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/claro.svg',
    utilityNumber: ['NT-CL-S-00454', 'NT-CL-S-00467'],
    category: ['Hogar / Móvil'],
    alts: ['Claro']
  },
  {
    name: 'VTR',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/vtr.svg',
    utilityNumber: ['NT-CL-S-00457'],
    category: ['Internet / Cable'],
    alts: ['VTR']
  },
  {
    name: 'Mundo Pacifico - Hogar',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/mundo.svg',
    utilityNumber: ['NT-CL-S-00450'],
    category: ['Internet / Cable'],
    alts: ['Mundo']
  },
  {
    name: 'Cae Itaú Corpbanca',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/itau.svg',
    utilityNumber: ['NT-CL-S-00524'],
    category: ['CAE Corpbanca'],
    alts: ['Itaú']
  },
  {
    name: 'Créditos Falabella',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/falabella.svg',
    utilityNumber: ['NT-CL-S-00503', 'NT-CL-S-00527', 'NT-CL-S-00504'],
    category: ['Créditos / CAE'],
    alts: ['Banco Falabella']
  },
  {
    name: 'Forum - Cuenta Contrato',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/forum.svg',
    utilityNumber: ['NT-CL-S-00500'],
    category: ['Crédito'],
    alts: ['Forum']
  },
  {
    name: 'Afianza MyV Hipotecario',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/myv.svg',
    utilityNumber: ['NT-CL-S-00496'],
    category: ['Hipotecario'],
    alts: ['Afianza MyV']
  },
  {
    name: 'Bice Hipotecaria - Full',
    logoUrl: 'assets/img/basic-services-companies-logos-svg/bice.svg',
    utilityNumber: ['NT-CL-S-00495'],
    category: ['Hipotecario Full'],
    alts: ['Bice Hipotecaria']
  }
];

export const utilityTypeToCategory = {
  'NT-CAT-00001': 'Agua',
  'NT-CAT-00002': 'Luz',
  'NT-CAT-00003': 'Internet/Cable',
  'NT-CAT-00004': 'RECARGAS',
  'NT-CAT-00005': 'Telefonía',
  'NT-CAT-00006': 'TV CABLE',
  'NT-CAT-00007': 'Otros servicios',
  'NT-CAT-00008': 'Gas',
  'NT-CAT-00009': 'VENTA POR CATALOGO',
  'NT-CAT-00010': 'Cementerio',
  'NT-CAT-00011': 'PROMOTORAS',
  'NT-CAT-00012': 'COOPERATIVAS',
  'NT-CAT-00013': 'Créditos hipotecarios',
  'NT-CAT-00014': 'Créditos',
  'NT-CAT-00015': 'Universidad',
  'NT-CAT-00016': 'COBRANZA',
  'NT-CAT-00017': 'Seguros',
  'NT-CAT-00018': 'Alarmas',
  'NT-CAT-00019': 'ISAPRES',
  'NT-CAT-00020': 'Autopistas',
  'NT-CAT-00021': 'Corredora de propiedades',
  'NT-CAT-00022': 'SUSCRIPCION',
  'NT-CAT-00023': 'REPORTES FINANCIEROS',
  'NT-CAT-00024': 'TARJETAS DE CREDITOS',
  'NT-CAT-00025': 'CAE',
  'NT-CAT-00026': 'Caja de compensación',
  'NT-CAT-00027': 'Crédito automotriz',
  'NT-CAT-00028': 'Instituto profesional',
  'NT-CAT-00029': 'Preuniversitario',
  'NT-CAT-00030': 'MyV Hipotecarios',
  'NT-CAT-00031': 'Afianza'
};

export function canAutomatizatePayment(
  paymentDay: number,
  fastPayment: boolean
): boolean {
  const depositeDates = getIntervalDates(paymentDay);
  const followingWorkingDaysCurrentMonth = getWorkingDays(
    depositeDates.currentDate
  );
  const followingWorkingDaysNextMonth = getWorkingDays(
    depositeDates.followingDate
  );
  const checkCurrentMonth = fastPayment
    ? forbiddenDates(
      getFastIntervalDates(followingWorkingDaysCurrentMonth[0]).currentDate,
      getFastIntervalDates(followingWorkingDaysCurrentMonth[0]).followingDate
    )
    : forbiddenDates(
      followingWorkingDaysCurrentMonth[2],
      followingWorkingDaysCurrentMonth[0]
    );
  const checkNextMonth = fastPayment
    ? forbiddenDates(
      getFastIntervalDates(followingWorkingDaysNextMonth[0]).currentDate,
      getFastIntervalDates(followingWorkingDaysNextMonth[0]).followingDate
    )
    : forbiddenDates(
      followingWorkingDaysNextMonth[2],
      followingWorkingDaysNextMonth[0]
    );
  if (!checkCurrentMonth && !checkNextMonth) {
    return true;
  } else {
    return false;
  }
}

function getWorkingDays(paymentDate: Date): Date[] {
  const workingDays = [];
  while (workingDays.length < 7) {
    if (!isWeekend(paymentDate) && !isHoliday(paymentDate)) {
      workingDays.push(new Date(paymentDate));
    }
    paymentDate.setDate(paymentDate.getDate() - 1);
    paymentDate.setHours(8, 0, 0, 0);
  }
  return workingDays;
}

function isWeekend(today: Date) {
  const day = today.getDay();
  return day === 0 || day === 6;
}

function getIntervalDates(
  day: number
): { currentDate: Date; followingDate: Date } {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const currentDate = new Date(currentYear, currentMonth, day, 23, 59, 59, 59);
  const nextMonth = (currentMonth + 1) % 12;
  const nextYear = nextMonth === 0 ? currentYear + 1 : currentYear;
  const nextDate = new Date(nextYear, nextMonth, day, 23, 59, 59, 59);
  return { currentDate: currentDate, followingDate: nextDate };
}

function getFastIntervalDates(
  date: Date
): { currentDate: Date; followingDate: Date } {
  const currentDate = new Date(date);
  const followingDate = new Date(date);
  currentDate.setHours(8, 0, 0, 0);
  followingDate.setHours(23, 59, 59, 999);
  return { currentDate, followingDate };
}

function forbiddenDates(startDate: Date, finishDate: Date): boolean {
  const today = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
  );
  return today >= startDate && today <= finishDate;
}

export const verificationDictionary: Record<
  VerificationOptions,
  IVerification
> = {
  [VerificationOptions.processing]: {
    emoji: '⌛️',
    title: 'Recibimos tu información',
    description:
      'Estamos procesando la documentación que nos entregaste para verificar tu cuenta. Te avisaremos por email cuando estés verificado.',
    mainBtnText: 'Bacán'
  },
  [VerificationOptions.verified]: {
    emoji: '✅',
    title: 'Tu cuenta quedó verificada',
    description: 'Ya puedes continuar utilizando Neat.',
    mainBtnText: 'Bacán'
  },
  [VerificationOptions.fatalError]: {
    emoji: '🥸',
    title: 'No pudimos verificar tu identidad',
    description:
      'Por favor, inténtalo de nuevo o escríbenos si el problema persiste.',
    mainBtnText: null,
    securityBtnText: 'Ir al chat'
  },
  [VerificationOptions.notSameRut]: {
    emoji: '🥸',
    title: 'No pudimos verificar tu identidad',
    description:
      'La identidad que verificaste no coincide con el RUT que ingresaste en Neat. Escríbenos al chat para que analicemos tu caso.',
    mainBtnText: null,
    securityBtnText: 'Ir al chat'
  },
  [VerificationOptions.temporalBlock]: {
    emoji: '🕵️',
    title: 'Bloqueo temporal',
    description: `Algún comportamiento activó una alerta en Neat y tu cuenta quedó bloqueada temporalmente.
    Escríbenos al chat para que analicemos tu caso.`,
    mainBtnText: null,
    securityBtnText: 'Ir al chat'
  },
  [VerificationOptions.stillProcessing]: {
    emoji: '⌛',
    title: 'Verificación en proceso',
    description: `Aún estamos procesando la información que nos entregaste para verificar tu cuenta, por lo que aún no puedes hacer pagos.
     Te avisaremos por email cuando estés verificado.`,
    mainBtnText: 'OK',
    securityBtnText: null
  },
  [VerificationOptions.notCompleted]: {
    emoji: '🥸',
    title: 'Verificación no terminada',
    description: `No terminaste el proceso de verificación.`,
    mainBtnText: 'OK',
    securityBtnText: null
  }
};

export function checkUpdateCredituDebt(date: any): boolean {
  const currentDate = new Date();
  const lastUpdate = new Date(date.seconds * 1000);
  const diffTime = currentDate.getTime() - lastUpdate.getTime();
  const minDiff = diffTime / (1000 * 60);
  return minDiff > 3;
}

export function filterBlockedRuts(
  blockedRuts: string[],
  blockedRutsAllowedList: string[]
) {
  if (!blockedRutsAllowedList) {
    return blockedRuts;
  }
  if (!blockedRuts) {
    return [];
  }
  return blockedRuts.filter(item => !blockedRutsAllowedList?.includes(item));
}

export function isRecentPayment(entity: Entity): boolean {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const creationDate = new Date(
    entity?.lastPayment?.createDate
      ? entity?.lastPayment?.createDate
      : entity.createDate
  );
  const utc1 = Date.UTC(
    creationDate.getFullYear(),
    creationDate.getMonth(),
    creationDate.getDate()
  );
  const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const diffDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);
  return diffDays < 40;
}

export function isPreloadedRecentPayment(entity: Entity): boolean {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const creationDate = new Date(
    entity?.lastPayment?.createDate
      ? entity?.lastPayment?.createDate
      : entity.createDate
  );
  const utc1 = Date.UTC(
    creationDate.getFullYear(),
    creationDate.getMonth(),
    creationDate.getDate()
  );
  const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const diffDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);
  return diffDays < 4;
}

function compareTransferLimitDate(entity1: Entity, entity2: Entity): number {
  const today = new Date().getDate();

  const date1 = entity1.transferLimitDate ? entity1.transferLimitDate : 32;
  const date2 = entity2.transferLimitDate ? entity2.transferLimitDate : 32;

  const diff1 = (date1 >= today ? date1 : date1 + 31) - today;
  const diff2 = (date2 >= today ? date2 : date2 + 31) - today;

  return diff1 - diff2;
}

function compareLastPaymentDate(entity1: Entity, entity2: Entity): number {
  const date1 = entity1.lastPayment ? entity1.lastPayment.createDate : 0;
  const date2 = entity2.lastPayment ? entity2.lastPayment.createDate : 0;

  return date2 - date1; // Most recent payments first
}

function compareCreateDate(entity1: Entity, entity2: Entity): number {
  const date1 = entity1.createDate;
  const date2 = entity2.createDate;

  return date2 - date1; // Newest entities first
}

export function sortNeatEntities(entities: Entity[]): Entity[] {
  const today = new Date();
  const fortyDaysAgo = new Date(today);
  fortyDaysAgo.setDate(today.getDate() - 40);

  return entities.sort((a, b) => {
    const aHasTransferLimit = a?.transferLimitDate !== undefined;
    const bHasTransferLimit = b?.transferLimitDate !== undefined;

    const aLastPayment = a?.lastPayment
      ? new Date(a.lastPayment.createDate)
      : null;
    const bLastPayment = b?.lastPayment
      ? new Date(b.lastPayment.createDate)
      : null;

    const aCreateDate = new Date(a.createDate);
    const bCreateDate = new Date(b.createDate);

    // Priority 1: Transfer Limit Date
    if (aHasTransferLimit && !bHasTransferLimit) {
      return -1;
    }
    if (!aHasTransferLimit && bHasTransferLimit) {
      return 1;
    }
    if (aHasTransferLimit && bHasTransferLimit) {
      const transferLimitComparison = compareTransferLimitDate(a, b);
      if (transferLimitComparison !== 0) {
        return transferLimitComparison;
      }
    }

    // Priority 2: Payments within the last 40 days
    if (
      aLastPayment &&
      aLastPayment >= fortyDaysAgo &&
      (!bLastPayment || bLastPayment < fortyDaysAgo)
    ) {
      return -1;
    }
    if (
      bLastPayment &&
      bLastPayment >= fortyDaysAgo &&
      (!aLastPayment || aLastPayment < fortyDaysAgo)
    ) {
      return 1;
    }
    if (
      aLastPayment &&
      bLastPayment &&
      aLastPayment >= fortyDaysAgo &&
      bLastPayment >= fortyDaysAgo
    ) {
      const lastPaymentComparison = compareLastPaymentDate(a, b);
      if (lastPaymentComparison !== 0) {
        return lastPaymentComparison;
      }
    }

    // Priority 3: Payments older than 40 days
    if (
      aLastPayment &&
      (!bLastPayment || aLastPayment < fortyDaysAgo) &&
      !aHasTransferLimit
    ) {
      return -1;
    }
    if (
      bLastPayment &&
      (!aLastPayment || bLastPayment < fortyDaysAgo) &&
      !bHasTransferLimit
    ) {
      return 1;
    }
    if (aLastPayment && bLastPayment) {
      const lastPaymentComparison = compareLastPaymentDate(a, b);
      if (lastPaymentComparison !== 0) {
        return lastPaymentComparison;
      }
    }

    // Priority 4: No payments and created more than 40 days ago
    if (
      !aLastPayment &&
      aCreateDate < fortyDaysAgo &&
      (!bLastPayment || bCreateDate >= fortyDaysAgo)
    ) {
      return -1;
    }
    if (
      !bLastPayment &&
      bCreateDate < fortyDaysAgo &&
      (!aLastPayment || aCreateDate >= fortyDaysAgo)
    ) {
      return 1;
    }
    if (!aLastPayment && !bLastPayment) {
      const createDateComparison = compareCreateDate(a, b);
      if (createDateComparison !== 0) {
        return createDateComparison;
      }
    }

    return 0;
  });
}

export function sortPreloadedEntities(
  entities: MergeTypes<BasicService, Entity>[]
): MergeTypes<BasicService, Entity>[] {
  const today = new Date();
  const fortyDaysAgo = new Date(today);
  fortyDaysAgo.setDate(today.getDate() - 40);

  return entities.sort((a, b) => {
    const aLastPayment = a?.lastPayment
      ? new Date(a.lastPayment.createDate)
      : null;
    const bLastPayment = b?.lastPayment
      ? new Date(b.lastPayment.createDate)
      : null;

    const aCreateDate = new Date(a.createDate);
    const bCreateDate = new Date(b.createDate);

    // Priority 1: Payments within the last 40 days
    if (
      aLastPayment &&
      aLastPayment >= fortyDaysAgo &&
      (!bLastPayment || bLastPayment < fortyDaysAgo)
    ) {
      return -1;
    }
    if (
      bLastPayment &&
      bLastPayment >= fortyDaysAgo &&
      (!aLastPayment || aLastPayment < fortyDaysAgo)
    ) {
      return 1;
    }
    if (
      aLastPayment &&
      bLastPayment &&
      aLastPayment >= fortyDaysAgo &&
      bLastPayment >= fortyDaysAgo
    ) {
      const lastPaymentComparison = compareLastPaymentDate(a, b);
      if (lastPaymentComparison !== 0) {
        return lastPaymentComparison;
      }
    }

    // Priority 2: Payments older than 40 days
    if (aLastPayment && (!bLastPayment || aLastPayment < fortyDaysAgo)) {
      return -1;
    }
    if (bLastPayment && (!aLastPayment || bLastPayment < fortyDaysAgo)) {
      return 1;
    }
    if (aLastPayment && bLastPayment) {
      const lastPaymentComparison = compareLastPaymentDate(a, b);
      if (lastPaymentComparison !== 0) {
        return lastPaymentComparison;
      }
    }

    // Priority 3: No payments and created more than 40 days ago
    if (
      !aLastPayment &&
      aCreateDate < fortyDaysAgo &&
      (!bLastPayment || bCreateDate >= fortyDaysAgo)
    ) {
      return -1;
    }
    if (
      !bLastPayment &&
      bCreateDate < fortyDaysAgo &&
      (!aLastPayment || aCreateDate >= fortyDaysAgo)
    ) {
      return 1;
    }
    if (!aLastPayment && !bLastPayment) {
      const createDateComparison = compareCreateDate(a, b);
      if (createDateComparison !== 0) {
        return createDateComparison;
      }
    }

    return 0;
  });
}

export function findDuplicateIds(
  arr: any[]
): { count: number; duplicates: string[] } {
  const idCounts: { [key: string]: number } = {};
  const duplicates: string[] = [];

  // Contar las apariciones de cada id
  arr.forEach(item => {
    if (idCounts[item.id]) {
      idCounts[item.id]++;
    } else {
      idCounts[item.id] = 1;
    }
  });

  // Identificar y contar los ids que tienen más de una aparición
  for (const [id, count] of Object.entries(idCounts)) {
    if (count > 1) {
      duplicates.push(id);
    }
  }

  return {
    count: duplicates.length,
    duplicates
  };
}

export function validateUpdatedDebts(
  entity: MergeTypes<BasicService, Entity>
): boolean {
  if (entity?.currentDebt && entity.currentDebt?.lastUpdate) {
    const lastUpdate = new Date(
      (entity.currentDebt.lastUpdate as any).seconds * 1000
    );
    const updatedDebt =
      lastUpdate.getDate() === new Date().getDate() &&
      lastUpdate.getMonth() === new Date().getMonth() &&
      lastUpdate.getFullYear() === new Date().getFullYear();
    return updatedDebt;
  }
  return false;
}

export function sortByTransferLimitDate(
  entities: MergeTypes<BasicService, Entity>[]
): MergeTypes<BasicService, Entity>[] {
  const today = new Date().getDate();
  return entities.sort((a, b) => {
    const aDate = a.transferLimitDate;
    const bDate = b.transferLimitDate;

    const aDistance = calculateDistance(today, aDate);
    const bDistance = calculateDistance(today, bDate);

    return aDistance - bDistance;
  });
}

function calculateDistance(today: number, date: number): number {
  if (date >= today) {
    return date - today;
  } else {
    return 31 - today + date;
  }
}

export function removeDuplicateEntities(entities: any[]): any[] {
  const seenIds = new Set<string>();
  return entities.filter(entity => {
    if (seenIds.has(entity.id)) {
      return false;
    } else {
      seenIds.add(entity.id);
      return true;
    }
  });
}

export function isDateClose(
  entity: Entity,
  daysThreshold: number = 7
): boolean {
  const today = new Date();
  const currentDay = today.getDate();
  const entityDay = entity.transferLimitDate;
  // Calcular la diferencia en días entre la fecha de la entidad y la fecha actual
  let dayDifference = entityDay - currentDay;
  if (dayDifference < 0) {
    // Si la diferencia es negativa, significa que la fecha de la entidad está en el siguiente mes
    dayDifference += 30; // Aproximación: un mes tiene 30 días
  }
  // Verificar si la diferencia está dentro del umbral especificado
  return dayDifference <= daysThreshold;
}

export function isDifferenceGreaterThanCertainHours(
  createDate: Date,
  today: Date,
  isGroupedDebt: boolean
): boolean {
  const limitHour = isGroupedDebt ? 6 : 48;
  const createdDate = new Date(createDate).getTime();
  const todayDate = new Date(today).getTime();
  const differenceInMilliseconds = Math.abs(todayDate - createdDate);
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
  return differenceInHours > limitHour;
}

export const getBankName = (paymentSelected: any, profileData: any) => {
  const paymentMethod = profileData?.paymentMethods.find(
    (paymentMethod: any) =>
      paymentSelected.paymentOption &&
      paymentSelected.paymentOption.paymentMethodId === paymentMethod.id
  );

  return paymentMethod?.bankName;
};

export const isPromotionActive = (
  promotionStartDate: number,
  promotionEndDate: number
): boolean => {
  const now = Date.now();
  return now >= promotionStartDate && now <= promotionEndDate;
};

export const preventTenpoCancellation = (
  selectedPayment: any,
  userProfile: any,
  isPromoActive: any,
  maxAmountForCancellation: number
): boolean => {
  const isAmountExceedingLimit =
    selectedPayment.value >= maxAmountForCancellation;

  const isPaymentMethodTenpo =
    getBankName(selectedPayment, userProfile) === PaymentMethodsBanks.tenpo;

  return isAmountExceedingLimit && isPaymentMethodTenpo && isPromoActive;
};

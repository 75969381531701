import { Injectable } from '@angular/core';
declare let $: any;
@Injectable({
  providedIn: 'root'
})
export class BanksService {
  bancos = [
    'Banco Conosur',
    'Banco de Chile / Citibank N.A. / A. Edwards',
    'Banco Internacional',
    'Banco Estado',
    'Scotiabank',
    'Banco Crédito e Inversiones',
    'Corpbanca',
    'Banco Bice',
    'Banco Santander',
    'Banco Itaú',
    'JP Morgan Chase Bank',
    'Banco Security',
    'Banco Falabella',
    'Banco Ripley',
    'Banco Consorcio',
    'BBVA Banco Bhif',
    'Banco del Desarrollo',
    'Mach',
    'Coopeuch',
    'Tenpo',
    'Caja Compensación Los Héroes',
    'Caja Los Andes',
    'Mercado Pago',
    'Copec Pay',
    'Global 66',
    'La Polar'
  ];
  constructor() {
    // Ordena sin tomar en cuenta la parte "Banco " de los nombres
    this.bancos.sort((a, b) => {
      if (a.includes('Banco')) {
        a = a.replace('Banco ', '');
      }
      if (b.includes('Banco')) {
        b = b.replace('Banco ', '');
      }
      return a.localeCompare(b, 'es', { sensitivity: 'base' });
    });
  }

  showBancosDeChile() {
    let htmlBancos = '';
    this.bancos.forEach((bankName) => {
      htmlBancos += `<mat-option value="${bankName}">${bankName}</mat-option>`;
    });
    $('select[name=bankName]').append(htmlBancos);
  }
}

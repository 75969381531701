import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BanksService } from '@services/shared/banks/banks.service';
import { Payment } from 'app/models/abstract-payment.model';
import { AppGlobals } from '@shared/constants';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CloudService } from 'app/services/cloud/cloud.service';
import { FormatRutPipe } from 'app/pipes/format-rut/format-rut.pipe';
import { UserService } from '@services/user/user.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { IconErrorModals } from '@shared/enums/enums.enum';
import { SweetalertService } from '@services/shared/sweetalert/sweetalert.service';
import { filterBlockedRuts } from '@shared/utils';
import { EntityType } from 'neat-lib';

export interface IShinkansenTranslateBanking {
  paymentId: string;
  newBanking: IShinkansenOperationNewBanking;
  entityType: EntityType;
}

export interface IShinkansenOperationNewBanking {
  toAccount?: string;
  toAccountType?: string;
  toBank?: string;
  toDNI?: string;
  toName?: string;
  toEmail?: string;
}

@Component({
  selector: 'app-retry-transfer-form',
  templateUrl: './retry-transfer-form.component.html',
  styleUrls: ['../retry-transfer.component.scss'],
  providers: [FormatRutPipe],
})
export class RetryTransferFormComponent implements OnInit, OnDestroy {
  name: string;
  rut: string;
  bank: string;
  accountNumber: string;
  email: string;
  retryForm: FormGroup;
  banks = this.bankService.bancos;
  @Input() currentPayment: Payment;
  @Output() showSuccessModal = new EventEmitter();
  @Output() showErrorModal = new EventEmitter();
  accountTypes: [
    string,
    string,
    string
  ] = ['Cuenta Corriente', 'Cuenta Vista', 'Cuenta Rut'];
  submitted: boolean = false;
  formSameData: boolean = false;
  loading: boolean = false;
  private allSubscriptions: Subscription = new Subscription();
  blockedRuts: Array<string> = [];
  constructor(
    private fb: FormBuilder,
    private bankService: BanksService,
    private fireAnalytics: AngularFireAnalytics,
    private cloudService: CloudService,
    private pipe: FormatRutPipe,
    private userService: UserService,
    private swalService: SweetalertService,
    private scroller: ViewportScroller
  ) {

  }

  ngOnInit(): void {
    this.allSubscriptions.add(this.userService.userInfo$.subscribe(userInfo => {
      this.blockedRuts = filterBlockedRuts(userInfo.blockedRuts, userInfo.blockedRutsAllowedList);
    }));
    this.retryForm = this.fb.group(
      {
        name: [this.currentPayment.depositee.fullName ? this.currentPayment.depositee.fullName : '', Validators.required],
        rut: [this.currentPayment.depositee.identifier ?
          this.pipe.transform(this.currentPayment.depositee.identifier) : '', Validators.required],
        bank: [this.currentPayment.depositee.bankName ?
          this.currentPayment.depositee.bankName : '', Validators.required],
        accountType: [this.currentPayment.depositee.bankAccountType ?
          this.currentPayment.depositee.bankAccountType : null, Validators.required],
        accountNumber: [this.currentPayment.depositee.bankAccountNumber ?
          this.currentPayment.depositee.bankAccountNumber : null, Validators.required],
        email: [this.currentPayment.depositee.email ? this.currentPayment.depositee.email : '', Validators.required],
      },
    );
  }

  async onSubmit() {
    const curentPaymentinfo = {
      name: this.currentPayment.depositee.fullName,
      rut: this.currentPayment.depositee.identifier,
      bank: this.currentPayment.depositee.bankName,
      accountType: this.currentPayment.depositee.bankAccountType,
      accountNumber: this.currentPayment.depositee.bankAccountNumber,
      email: this.currentPayment.depositee.email,
    };
    const currentForm = this.retryForm.value;
    currentForm.rut = AppGlobals.formatRut(currentForm.rut);
    if (this.blockedRuts.includes(currentForm.rut)) {
      this.swalService.swalError2('El rut del destinatario está bloqueado', `No puedes ingresar este rut por alerta de pago circular. <br/>
      Para poder habilitarlo debes enviarnos por el chat un documento que nos ayude a acreditar el pago. `,
        IconErrorModals.sadFace, false, false);
      return false;
    }
    const newBanking: IShinkansenOperationNewBanking = {};
    if (curentPaymentinfo.rut !== currentForm.rut) {
      newBanking.toDNI = currentForm.rut;
    }
    if (curentPaymentinfo.name !== currentForm.name) {
      newBanking.toName = currentForm.name;
    }
    if (curentPaymentinfo.bank !== currentForm.bank) {
      newBanking.toBank = currentForm.bank;
    }
    if (curentPaymentinfo.accountType !== currentForm.accountType) {
      newBanking.toAccountType = currentForm.accountType;
    }
    if (curentPaymentinfo.accountNumber.replace(/[^a-z0-9]/gi, '') !== currentForm.accountNumber.replace(/[^a-z0-9]/gi, '')) {
      newBanking.toAccount = currentForm.accountNumber.replace(/[^a-z0-9]/gi, '');
    }
    if (curentPaymentinfo.email !== currentForm.email) {
      newBanking.toEmail = currentForm.email;
    }
    const requTranslate: IShinkansenTranslateBanking = {
      paymentId: this.currentPayment.paymentId,
      entityType: this.currentPayment.entityType,
      newBanking: newBanking
    };
    if (!this.retryForm.invalid) {
      this.loading = true;
      this.fireAnalytics.logEvent('Send Retry Info', {
        retryForm: this.retryForm
      });
      const response = await this.cloudService.retryFailedTransfer(requTranslate);
      response?.success ? this.showSuccessModal.emit(true) : this.showErrorModal.emit({
        error: true,
        errorMessage: response?.errorMessage
      });
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.allSubscriptions?.unsubscribe();
  }
}
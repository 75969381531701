import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CheckoutService } from '@services/checkout/checkout.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { MyErrorStateMatcher } from '@shared/my-error-state-matcher/my-error-state-matcher.directive';
import { FormValidation } from 'app/landing/register/form-validation';

@Component({
  selector: 'app-automate-payment',
  templateUrl: './automate-payment.component.html',
  styleUrls: ['./automate-payment.component.scss']
})
export class AutomatePaymentComponent implements OnInit, OnDestroy {
  paymentForm: FormGroup;
  selectedDay: number;
  matcher = new MyErrorStateMatcher();
  automaticPaymentChargeDayText: string;
  automaticPaymentDepositDayText: string;
  automaticPaymentText: string;
  private valueChangesSubscriptions: Subscription = new Subscription();
  constructor(
    public dialogRef: MatDialogRef<AutomatePaymentComponent>,
    private checkoutService: CheckoutService,
    private fb: FormBuilder,
    public formValidation: FormValidation,
  ) {
    this.paymentForm = this.fb.group({
      transferLimitDate: ['', [Validators.required, Validators.min(1), Validators.max(31), this.formValidation.onlyNumberValidator]],
    });
  }

  ngOnInit(): void {
    this.transferLimitDateChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  transferLimitDateChanges(): void {
    this.valueChangesSubscriptions.add(this.paymentForm.get('transferLimitDate').valueChanges.subscribe( selectedDay => {
      this.defineAutomaticPaymentInfoTexts(Number(selectedDay));
    }));
  }

  defineAutomaticPaymentInfoTexts(selectedDay: number): void {
    if (selectedDay) {
      this.selectedDay = selectedDay;
      const automaticPaymentDates = this.checkoutService.automaticPaymentDates(selectedDay);
      this.automaticPaymentChargeDayText = automaticPaymentDates.chargeDay;
      this.automaticPaymentDepositDayText = automaticPaymentDates.depositDay;
      this.automaticPaymentText = automaticPaymentDates.automaticPaymentDay;
    }
  }

  ngOnDestroy(): void {
    this.valueChangesSubscriptions?.unsubscribe();
  }


  async updateEntityForAutomate(): Promise<void | boolean> {
    this.dialogRef.close({
      transferLimitDate: Number(this.paymentForm.get('transferLimitDate').value)
    });
  }

}

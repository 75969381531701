<app-loading [showLoader]="showLoader"></app-loading>
<div style="max-width: 500px;" class="text-center m-auto">
    <button type="button" mat-button class="close" (click)="onNoClick()"><i class="material-icons notranslate">close</i></button>
    <h1 class="heading-h5 d-block">Ingresa tu pin de seguridad</h1>
    <p class="body-1 gray">Confirma tu pin para realizar la acción</p>
    <form [formGroup]="validatePin" (ngSubmit)="pinValidationForAutomatizate()" style="max-width: 330px;"  class="text-center m-auto">
        <div class="row pt-2 pb-0 pb-md-2">
            <div class="col-12 col-md-8 m-auto">
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>
                        <mat-icon>lock</mat-icon> Pin de 4 digitos
                    </mat-label>
                    <input matInput type="password" formControlName="pin" minlength="4" maxlength="4" required>
                    <mat-error *ngIf="validatePin.controls['pin'].hasError('required')">El pin es <strong>requerido</strong></mat-error>
                    <mat-error *ngIf="validatePin.controls['pin'].hasError('minlength') || validatePin.controls['pin'].hasError('maxlength')">El pin debe ser de <strong>4 dígitos</strong></mat-error>
                    <mat-error *ngIf="errorMessage"><strong>{{errorMessage}}</strong></mat-error>
                    <mat-hint class="animated fadeIn d-flex align-items-center" *ngIf="loadingVerifyPin">Verificando pin <mat-spinner style="display: inline-block; margin-left: 4px;" [diameter]="12"></mat-spinner></mat-hint>
                    <mat-hint class="animated fadeIn" style="color: green;" *ngIf="validPin && !loadingVerifyPin">El pin es <strong>válido</strong></mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-center">
            <button [disabled]="validatePin.invalid || loadingVerifyPin" style="width: fit-content;" mat-raised-button id="validate-pin-btn" type="submit" class="main-btn"><span class="mx-4">CONFIRMAR PIN</span></button>
        </div>
    </form>
</div>
import { Component, Inject, OnInit, isDevMode } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../services/shared/dialog/dialog.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from '../../../shared/my-error-state-matcher/my-error-state-matcher.directive';
import { UserService } from '../../../services/user/user.service';
import { Router } from '@angular/router';
import { RutValidator } from './../../../validators/rut.validator';
import { CloudService } from '../../../services/cloud/cloud.service';
import { User } from '../../../models/users/user.model';
import { SweetalertService } from '../../../services/shared/sweetalert/sweetalert.service';
import { AppGlobals } from '../../../shared/constants';
import { FormValidation } from 'app/landing/register/form-validation';
import { IconErrorModals } from '@shared/enums/enums.enum';
import { RentListService } from '@services/rent-list/rent-list.service';
declare let Intercom: any;

@Component({
  selector: 'app-rut-modal',
  templateUrl: './rut-modal.component.html',
  styleUrls: ['./rut-modal.component.scss']
})
export class RutModalComponent implements OnInit {
  rutForm: FormGroup;
  showLoader = false;
  matcher = new MyErrorStateMatcher();
  focusState = false;
  currentUser: User;
  promotionValidation: boolean;
  addPaymentMethodValidation: boolean;
  successMessageText = 'Ahora puedes continuar con la creación de tu cuenta.';
  descriptionText = 'Necesitamos este dato para fortalecer la seguridad de tu cuenta. Solo te lo pediremos una vez.';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<RutModalComponent>,
    private cloudService: CloudService,
    private swalService: SweetalertService,
    private rentListService: RentListService,
  ) {
    this.promotionValidation = this.router.url.includes('promociones');
    this.addPaymentMethodValidation = this.router.url.includes('medios-de-pago');
    if (this.promotionValidation) {
      this.descriptionText = 'Dato necesario para poder activar la promoción y fortalecer la seguridad de tu cuenta. Solo te lo pediremos una vez.';
      this.successMessageText = 'Ahora puedes continuar con la activación de tu promoción.';
    }
    if (this.addPaymentMethodValidation) {
      this.descriptionText = 'Dato necesario para poder agregar un método de pago y fortalecer la seguridad de tu cuenta. Solo te lo pediremos una vez.';
      this.successMessageText = 'Ahora podrás agregar tu método de pago.';
    }
    this.rutForm = this.fb.group({
      rut:  ['', [Validators.required, Validators.minLength(8), RutValidator], [FormValidation.verifyRut()]],
    });

    this.userService.user$.subscribe((user: User) => {
      this.currentUser = user;
    });
  }

  get rut() {
    return this.rutForm.get('rut').value;
  }

  async saveRut() {
    this.showLoader = true;
    const res = await this.cloudService.rutEnroller(AppGlobals.formatRut(String(this.rut)));
    if (res.status) {
      this.showLoader = false;
      this.onNoClick();
      if (this.data?.edipro) {
        this.successMessageText = 'Ahora puedes continuar con el pago de tu cuenta.';
      }
      this.swalService.swalSuccess('Tu RUT ha sido guardado exitosamente', this.successMessageText);
    } else {
      this.showLoader = false;
      this.swalService.swalError2('Ha ocurrido un error al guardar tu RUT', 'Por favor inténtalo de nuevo. Si el problema persiste, escríbenos por el chat y envíanos tu RUT.', IconErrorModals.sadCloud, false, true);
      this.openIntercom();
    }
  }

  openIntercom() {
    Intercom('showNewMessage', '');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  goBack() {
    this.rentListService.addSelectedCategory('removed');
    if (this.data.url) {this.router.navigate([this.data.url]);}
    this.dialogRef.close();
  }

  ngOnInit() {
    this.validateValidRut();
  }


  validateValidRut(): void {
    const rutElement: any = document.getElementsByName('lesseeRut');
    const rutValue = AppGlobals.formatRut(rutElement.value);

    if (AppGlobals.invalidConstantRuts.includes(rutValue)) {
      rutElement.value = '';
      const savedTabIndex = rutElement.getAttribute('tabindex');
      rutElement.setAttribute('tabindex', '-1');
      rutElement.focus();
      rutElement.setAttribute('tabindex', savedTabIndex);
      this.swalService.swalError2('No puedes pagarte a ti mismo', `El rut del destinatario, no puede ser igual al tuyo. <br/>
      Ingresa otro rut para continuar. `, IconErrorModals.sadFaceWithArrows, false, false);
    }
  }

  goEdiPro(): string {
    return window.location.href = isDevMode() ? 'https://app.stag.edipro.app/edipay' : 'https://app.edipro.app/edipay';
  }

}
